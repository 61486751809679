import TitleWithGreenBlock from '~/components/elements/TitleWithGreenBlock'
import type { Page_Flexcontent_Flex_Posts, Post as PostProps } from '~/graphql/types'
import Slider, { Slide } from '~/components/elements/Slider'
import { Navigation } from 'swiper/modules'
import PostHighlighted from './post-types/PostHighlighted'
import Post from './post-types/Post'
import Links from '~/components/elements/Links'
import useIsVisible from '~/hooks/useIsVisible'
import clsx from 'clsx'

export default function PostsNewsLatest({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const [ref, isVisible] = useIsVisible()
  const posts = fields?.posts?.edges as unknown as { node: PostProps }[]

  return (
    <section data-component="PostsNewsLatest" className="section" ref={ref}>
      <div className="container">
        <div className="flex items-end gap-16 px-6 sm:px-4">
          <TitleWithGreenBlock title={fields?.title} />
          <Links items={fields?.links} className="max-md:hidden lg:mb-4" />
        </div>
        <div className="spacing--big--top grid gap-6 lg:gap-10 lg:grid-cols-2 max-sm:hidden">
          <div>
            <PostHighlighted
              data={posts?.[0]?.node}
              className={clsx('max-sm:h-[250px] max-lg:h-[350px]', isVisible ? 'animate-fade-up opacity-100' : 'opacity-0')}
            />
          </div>
          <div className="grid gap-6 lg:gap-10 lg:grid-cols-1">
            <div>
              <Post
                data={posts?.[1]?.node}
                type="dark"
                className={clsx(isVisible ? 'animate-fade-up animate-delay-300 opacity-100' : 'opacity-0')}
              />
            </div>
            <div>
              <Post
                data={posts?.[2]?.node}
                type="dark"
                className={clsx(isVisible ? 'animate-fade-up animate-delay-400 opacity-100' : 'opacity-0')}
              />
            </div>
          </div>
        </div>
        <div className="sm:hidden spacing--big--top">
          <Slider
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 30
              }
            }}
            modules={[Navigation]}
            navigation={{
              prevEl: `.slider-prev`,
              nextEl: `.slider-next`
            }}
          >
            {posts?.map((item) => (
              <Slide key={item.node.databaseId} className="!h-[250px]">
                <PostHighlighted data={item?.node as PostProps} showReadtime={false} />
              </Slide>
            ))}
            <div className="mt-5 flex--center">
              <button className="slider-prev text-site-base bg-co-flamingo w-8 h-8 lg:w-10 lg:h-10 rounded-full flex--center btn--pseudo bg-ftf-punch">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <path
                    d="M13.5 1.12483C13.5 1.27265 13.4708 1.419 13.4142 1.55554C13.3575 1.69208 13.2746 1.81613 13.17 1.92058L7.41675 7.67383C7.24258 7.84795 7.10443 8.05467 7.01017 8.28219C6.91591 8.5097 6.8674 8.75356 6.8674 8.99983C6.8674 9.2461 6.91591 9.48996 7.01017 9.71748C7.10443 9.94499 7.24258 10.1517 7.41675 10.3258L13.1632 16.0723C13.3682 16.2845 13.4816 16.5687 13.479 16.8637C13.4764 17.1586 13.3581 17.4408 13.1495 17.6494C12.941 17.858 12.6588 17.9763 12.3638 17.9788C12.0688 17.9814 11.7847 17.868 11.5725 17.6631L5.826 11.9166C5.05337 11.1425 4.61943 10.0935 4.61943 8.99983C4.61943 7.90615 5.05337 6.85715 5.826 6.08308L11.58 0.329081C11.7374 0.171902 11.9378 0.0648995 12.156 0.021597C12.3741 -0.0217055 12.6002 0.000635242 12.8057 0.0857965C13.0112 0.170956 13.1868 0.315113 13.3104 0.500052C13.434 0.68499 13.4999 0.902409 13.5 1.12483Z"
                    fill="currentColor"
                  />
                </svg>
                <span className="sr-only">Vorige</span>
              </button>
              <button className="slider-next text-site-base bg-co-flamingo w-8 h-8 lg:w-10 lg:h-10 rounded-full flex--center btn--pseudo bg-ftf-punch">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <path
                    d="M4.50001 16.8752C4.50004 16.7274 4.5292 16.581 4.58583 16.4445C4.64245 16.3079 4.72543 16.1839 4.83001 16.0794L10.5833 10.3262C10.7574 10.1521 10.8956 9.94533 10.9898 9.71781C11.0841 9.4903 11.1326 9.24644 11.1326 9.00017C11.1326 8.7539 11.0841 8.51004 10.9898 8.28252C10.8956 8.05501 10.7574 7.84829 10.5833 7.67417L4.83676 1.92767C4.63183 1.71549 4.51844 1.43131 4.521 1.13634C4.52357 0.841371 4.64188 0.559207 4.85047 0.350623C5.05905 0.142039 5.34121 0.0237236 5.63619 0.0211604C5.93116 0.0185971 6.21533 0.131991 6.42751 0.336919L12.174 6.08342C12.9466 6.85749 13.3806 7.90649 13.3806 9.00017C13.3806 10.0939 12.9466 11.1428 12.174 11.9169L6.42001 17.6709C6.26263 17.8281 6.0622 17.9351 5.84403 17.9784C5.62586 18.0217 5.39976 17.9994 5.19428 17.9142C4.9888 17.829 4.81318 17.6849 4.68961 17.4999C4.56604 17.315 4.50006 17.0976 4.50001 16.8752Z"
                    fill="currentColor"
                  />
                </svg>
                <span className="sr-only">Volgende</span>
              </button>
            </div>
          </Slider>
        </div>
        <div className="flex--center spacing--big--top md:hidden">
          <Links items={fields?.links} />
        </div>
      </div>
    </section>
  )
}
