import clsx from 'clsx'

export default function PageDownArrow({ className }: { className?: string }) {
  return (
    <svg
      onClick={() => {
        // scroll page down
        window.scrollTo({ top: window.innerHeight, behavior: 'smooth' })
      }}
      className={clsx(className && className, 'cursor-pointer')}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_869_4701)">
        <path
          d="M6.26968 21.4752L10.6786 25.884C11.4285 26.6005 12.4258 27.0002 13.4629 27.0002C14.5001 27.0002 15.4974 26.6005 16.2473 25.884L20.6562 21.4752C20.9636 21.1569 21.1337 20.7306 21.1298 20.2882C21.126 19.8457 20.9485 19.4225 20.6356 19.1096C20.3227 18.7967 19.8995 18.6192 19.457 18.6154C19.0146 18.6116 18.5883 18.7816 18.2701 19.089L15.1426 22.2165L15.1167 1.6853C15.1167 1.23775 14.9389 0.80853 14.6224 0.492063C14.306 0.175596 13.8767 -0.00219409 13.4292 -0.00219412C12.9816 -0.00219416 12.5524 0.175595 12.2359 0.492063C11.9195 0.80853 11.7417 1.23775 11.7417 1.6853L11.7676 22.2008L8.65581 19.089C8.50014 18.9279 8.31394 18.7993 8.10805 18.7109C7.90217 18.6224 7.68074 18.5759 7.45667 18.5739C7.23261 18.572 7.0104 18.6147 6.80301 18.6995C6.59562 18.7844 6.40721 18.9097 6.24877 19.0681C6.09032 19.2266 5.96502 19.415 5.88017 19.6224C5.79532 19.8297 5.75263 20.052 5.75457 20.276C5.75652 20.5001 5.80307 20.7215 5.89151 20.9274C5.97995 21.1333 6.10851 21.3195 6.26968 21.4752Z"
          fill="white"
        />
      </g>
    </svg>
  )
}
