import clsx from 'clsx'
import { useInView } from 'framer-motion'
import { useRef, useState } from 'react'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentUspsDetail({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const [currentHover, setCurrentHover] = useState(0)

  const titleRef = useRef(null)
  const contentRef = useRef(null)
  const crosshairRef = useRef(null)

  const titleVisible = useInView(titleRef, { once: true })
  const contentVisible = useInView(contentRef, { once: true })
  const crosshairVisible = useInView(crosshairRef, { once: true })

  const [positionMapping] = useState<
    {
      block: string
      title: string
    }[]
  >([
    {
      block:
        'animate-delay-300 -left-[150px] sm:-left-[200px] xl:-left-[235px] -top-[26px] sm:-top-[43px] w-[90px] h-[56px] sm:w-[144px] sm:h-[90px]',
      title: '-left-[20px] sm:-left-[60px] -top-[12px] sm:-top-[22px] whitespace-nowrap'
    },
    {
      block:
        'animate-delay-[400ms] -top-[150px] sm:-top-[200px] xl:-top-[235px] -left-[26px] sm:-left-[43px] w-[56px] h-[90px] sm:w-[90px] sm:h-[144px]',
      title: '-rotate-90 -left-[5px] xl:-left-[25px] -top-[0px] xl:-top-[20px] whitespace-nowrap'
    },
    {
      block:
        'animate-delay-500 -right-[150px] sm:-right-[200px] xl:-right-[235px] -top-[26px] sm:-top-[43px] w-[90px] h-[56px] sm:w-[144px] sm:h-[90px]',
      title: 'left-[15px] sm:left-[40px] -bottom-[10px] sm:-bottom-[15px] whitespace-nowrap'
    },
    {
      block:
        'animate-delay-[600ms] -bottom-[150px] sm:-bottom-[200px] xl:-bottom-[235px] -left-[26px] sm:-left-[43px] w-[56px] h-[90px] sm:w-[90px] sm:h-[144px]',
      title: '-rotate-90 -left-[53px] sm:-left-[75px] xl:-left-[90px] bottom-[30px] xl:-bottom-[0px] whitespace-nowrap'
    }
  ])

  return (
    <section data-component="ContentUspsDetail" className="section--unit" id={fields.sectionId}>
      <div className="container">
        <div className="grid lg:grid-cols-12 gap-10 xl:gap-16">
          <div
            ref={crosshairRef}
            className="max-sm:min-h-[380px] max-lg:min-h-[550px] col-span-6 lg:col-span-7 relative flex items-center justify-center"
          >
            <div className="absolute w-1 h-1 left-0 top-0 right-0 bottom-0 m-auto">
              {fields.items.map((item, i) => (
                <div onMouseEnter={() => setCurrentHover(i)} key={i}>
                  <div
                    className={clsx(
                      crosshairVisible && 'animate-fade-up animate-ease-in-out animate-duration-500',
                      crosshairVisible ? 'opacity-100' : 'opacity-0',
                      positionMapping[i].block,
                      currentHover === i ? 'bg-site-accent' : 'bg-site-grey ',
                      'absolute duration-200 transition-all'
                    )}
                  >
                    <div
                      className={clsx(
                        positionMapping[i].title,
                        currentHover === i ? 'text-site-base' : 'text-site-base/50',
                        'absolute text-xl sm:text-3xl xl:text-[36px] font-bold !leading-[0.8] duration-200 transition-all'
                      )}
                    >
                      {item.title.split('|').map((t, si) => (
                        <span key={si}>
                          {t}
                          <br />
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-span-6 lg:col-span-5">
            <h2
              ref={titleRef}
              key={`title-${currentHover}`}
              className={clsx(
                titleVisible && 'animate-fade-up animate-ease-in-out animate-duration-500',
                titleVisible ? 'opacity-100' : 'opacity-0',
                'text-site-base spacing--semi-small--bottom font-bold title--medium sm:title--semi-large'
              )}
            >
              {fields?.items?.[currentHover]?.title?.split('|').map((t, i) => (
                <span key={i}>
                  {t}
                  {i === 0 && <br />}
                </span>
              ))}
            </h2>
            <div
              key={`content-${currentHover}`}
              className={clsx(
                contentVisible && 'animate-fade-up animate-ease-in-out animate-delay-200 animate-duration-500',
                contentVisible ? 'opacity-100' : 'opacity-0'
              )}
              ref={contentRef}
            >
              <Content theme="light">{fields?.items?.[currentHover]?.description}</Content>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
