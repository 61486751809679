import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import useIsMobile from '~/hooks/useIsMobile'

export default function CopyButton({
  className,
  classNameValue,
  classNameHiddenValue,
  value,
  hiddenValue,
  mobileHiddenValue
}: {
  className?: string
  classNameValue?: string
  classNameHiddenValue?: string
  value: React.ReactNode
  hiddenValue: React.ReactNode
  mobileHiddenValue: string
}) {
  const isMobile = useIsMobile()
  const [state, setState] = useState<'idle' | 'hover' | 'click'>('idle')

  useEffect(() => {
    if (state !== 'click') return

    const timeout = setTimeout(() => {
      setState('idle')
    }, 3000)

    return () => {
      clearTimeout(timeout)
    }
  }, [state])

  return (
    <a
      className={clsx(className, 'overflow-hidden')}
      onMouseEnter={() => setState('hover')}
      onMouseLeave={() =>
        setTimeout(() => {
          setState('idle')
        }, 3000)
      }
      onClick={(e) => {
        if (!isMobile) {
          e.preventDefault()
        } else {
          return
        }

        setState('click')

        navigator.clipboard.writeText(hiddenValue as string)
      }}
      href={mobileHiddenValue}
    >
      <AnimatePresence mode="wait">
        {state === 'click' && (
          <motion.span
            key="hover"
            initial={{ y: '100%' }}
            animate={{ y: 0 }}
            exit={{ y: '-100%' }}
            transition={{
              duration: 0.1
            }}
            className={clsx('-my-2 py-2 text-ellipsis overflow-hidden', classNameHiddenValue)}
          >
            Gekopieërd
          </motion.span>
        )}
        {state === 'hover' && (
          <motion.span
            key="hover"
            initial={{ y: '100%' }}
            animate={{ y: 0 }}
            exit={{ y: '-100%' }}
            transition={{
              duration: 0.1
            }}
            className={clsx('-my-2 py-2 text-ellipsis overflow-hidden block', classNameHiddenValue)}
          >
            {hiddenValue}
          </motion.span>
        )}
        {state === 'idle' && (
          <motion.span
            key="idle"
            initial={{ y: '100%' }}
            animate={{ y: 0 }}
            exit={{ y: '-100%' }}
            transition={{
              duration: 0.1
            }}
            className={clsx('-my-2 py-2', classNameValue)}
          >
            {value}
          </motion.span>
        )}
      </AnimatePresence>
    </a>
  )
}
