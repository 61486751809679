import type { Vacature } from '~/graphql/types'
import type { ElasticVacancy } from '~/services/elastic.server'

function dataLayerPush(data: any) {
  if (!window.dataLayer) {
    console.log('window.dataLayer', data)
    return
  }

  window.dataLayer.push(data)
}

export function gaSendViewItemList(data?: ElasticVacancy[]) {
  if (!data) return

  dataLayerPush({
    event: 'view_item_list',
    ecommerce: {
      item_list_id: 'vacatures',
      item_list_name: 'Vacatures',
      items: data.map((item, i) => ({
        item_id: item?.id,
        item_name: item?.title,
        item_brand: item?.functionGroup?.[0],
        item_variant: item?.function?.[0],
        item_category: 'Vacature',
        item_category2: item.region,
        item_list_id: 'vacatures',
        item_list_name: 'Vacatures',
        price: 0.0,
        index: i,
        quantity: 1
      }))
    }
  })
}

export function gaSelectItem(item?: ElasticVacancy) {
  if (!item) return

  dataLayerPush({
    event: 'select_item',
    ecommerce: {
      item_id: item?.id,
      item_name: item?.title,
      item_brand: item?.functionGroup?.[0],
      item_variant: item?.function?.[0],
      item_category: 'Vacature',
      item_category2: item.region,
      price: 0.0,
      quantity: 1
    }
  })
}

export function gaViewItem(item?: Vacature) {
  if (!item) return

  dataLayerPush({
    event: 'view_item',
    ecommerce: {
      item_id: item?.id,
      item_name: item?.title,
      item_brand: item?.vakgebieden?.nodes?.[0]?.name,
      item_variant: item?.functies?.nodes?.[0]?.name,
      item_category: 'Vacature',
      item_category2: item?.recap.region,
      price: 0.0,
      quantity: 1
    }
  })
}

export function gaAddToCart(item?: Vacature) {
  if (!item) return

  dataLayerPush({
    event: 'add_to_cart',
    ecommerce: {
      currency: 'EUR',
      value: 0,
      items: [
        {
          item_id: item?.id,
          item_name: item?.title,
          item_brand: item?.vakgebieden?.nodes?.[0]?.name,
          item_variant: item?.functies?.nodes?.[0]?.name,
          item_category: 'Vacature',
          item_category2: item?.recap.region,
          price: 0.0,
          quantity: 1
        }
      ]
    }
  })
}

export function gaBeginCheckout(item?: Vacature) {
  if (!item) return

  dataLayerPush({
    event: 'begin_checkout',
    ecommerce: {
      currency: 'EUR',
      value: 0,
      items: [
        {
          item_id: item?.id,
          item_name: item?.title,
          item_brand: item?.vakgebieden?.nodes?.[0]?.name,
          item_variant: item?.functies?.nodes?.[0]?.name,
          item_category: 'Vacature',
          item_category2: item?.recap.region,
          price: 0.0,
          quantity: 1
        }
      ]
    }
  })
}

export function gaPurchase(item?: Vacature) {
  if (!item) return

  dataLayerPush({
    event: 'purchase',
    ecommerce: {
      transaction_id: item?.recap?.vacancyNr,
      currency: 'EUR',
      value: 0,
      shipping: 0,
      tax: 0,
      items: [
        {
          item_id: item?.id,
          item_name: item?.title,
          item_brand: item?.vakgebieden?.nodes?.[0]?.name,
          item_variant: item?.functies?.nodes?.[0]?.name,
          item_category: 'Vacature',
          item_category2: item?.recap.region,
          price: 0.0,
          quantity: 1
        }
      ]
    }
  })
}
