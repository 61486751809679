import clsx from 'clsx'
import Content from '~/components/elements/Content'
import Links from '~/components/elements/Links'
import TitleWithGreenBlock from '~/components/elements/TitleWithGreenBlock'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import useIsVisible from '~/hooks/useIsVisible'

export default function ContentBlock({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const [ref, isVisible] = useIsVisible()

  return (
    <section data-component="ContentBlock" className="section" ref={ref} id={fields.sectionId || 'blok'}>
      <div
        className={clsx(
          isVisible && 'animate-fade-up animate-ease-in-out',
          isVisible ? 'opacity-100' : 'opacity-0',
          'container bg-neutral-100 lg:p-14 lg:columns-2'
        )}
      >
        <div className="break-after-column">
          <div className="relative flex justify-start max-sm:pl-6 max-lg:pl-10">
            <TitleWithGreenBlock title={fields?.title} className="my-6 lg:mb-12" />
          </div>
          <div className="spacing--semi-small--bottom title--small xl:title--normal text-left lg:text-center font-bold">
            {fields?.subtitle}
          </div>
        </div>
        <div className="w-full h-full lg:mt-14">
          <Content>{fields?.description}</Content>
          <Links items={fields?.links} className="spacing--normal--top pb-9 lg:pb-6" />
        </div>
      </div>
    </section>
  )
}
