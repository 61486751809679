import { useState, type ReactNode } from 'react'
import Select from './Select'
import { useLosseLayout, useNavigation } from '@ubo/losse-sjedel'
import Loading from '../Loading'
import NoResults from './NoResults'
import Pagination from './Pagination'
import { AnimatePresence, motion } from 'framer-motion'

type Props = {
  id?: string
  filtersChildren: ReactNode
  contentChildren?: ReactNode
  children: ReactNode
  total: number
  title: string
  hits: number
  limit: number
  sortOptions: string[]
}

export default function Overview({ id, filtersChildren, contentChildren, children, total, title, hits, limit, sortOptions }: Props) {
  const navigation = useNavigation()
  const [filtersOpen, setFiltersOpen] = useState(false)
  const { setScrollable } = useLosseLayout()

  return (
    <div className="container py-8 mb-20" id="id">
      <div className="grid lg:grid-cols-12 gap-8">
        <div className="lg:col-span-3 pt-1 max-lg:hidden">{filtersChildren}</div>
        <AnimatePresence>
          {filtersOpen && (
            <motion.div
              initial={{ x: '100%' }}
              animate={{ x: 0 }}
              exit={{ x: '100%' }}
              transition={{ duration: 0.3, ease: 'easeInOut' }}
              className="fixed lg:hidden z-50 left-0 top-0 w-full h-full bg-site-secondair"
            >
              <div className="container pt-10 flex flex-col justify-between h-full overflow-y-auto">
                <div className="px-2 xs:px-6 flex flex-col justify-between pb-6 h-full">
                  <div>
                    <div className="flex items-center justify-end">
                      <button
                        type="button"
                        onClick={() => {
                          setFiltersOpen(false)
                          setScrollable(true)
                        }}
                        className="text-site-base"
                      >
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g id="Group">
                            <path
                              id="Vector"
                              d="M9.41416 8.00022L15.7072 1.70782C16.0979 1.31718 16.0979 0.683833 15.7072 0.293224C15.3166 -0.0974159 14.6833 -0.0974159 14.2927 0.293224L8.00022 6.58628L1.70782 0.293224C1.31718 -0.0974159 0.683833 -0.0974159 0.293224 0.293224C-0.0973846 0.683865 -0.0974159 1.31721 0.293224 1.70782L6.58628 8.00022L0.293224 14.2927C-0.0974159 14.6833 -0.0974159 15.3166 0.293224 15.7072C0.683865 16.0979 1.31721 16.0979 1.70782 15.7072L8.00022 9.41416L14.2926 15.7072C14.6833 16.0979 15.3166 16.0979 15.7072 15.7072C16.0979 15.3166 16.0979 14.6833 15.7072 14.2927L9.41416 8.00022Z"
                              fill="currentColor"
                            />
                          </g>
                        </svg>
                      </button>
                    </div>
                    <div className="mt-6 flex justify-center">
                      <Select name="Sorteren" options={sortOptions} className="max-xs:w-full" />
                    </div>
                    <div className="mt-8">{filtersChildren}</div>
                  </div>

                  <div className="py-12 flex justify-center">
                    <button
                      type="button"
                      onClick={() => {
                        setFiltersOpen(false)
                        setScrollable(true)
                      }}
                      className="btn--conversion w-full xs:w-[80%] !text-xl xs:!py-3"
                    >
                      Toon {total} resultaten
                    </button>
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <div className="lg:col-span-9">
          {contentChildren && <div className="mb-8">{contentChildren}</div>}

          <div className="flex items-center justify-between pl-2 lg:pl-4">
            <div className="flex items-center gap-3">
              <h2 className="title--small">
                <span className="text-site-accent">{total}</span> {title} <span className="max-xs:hidden">gevonden</span>
              </h2>
              {navigation.state === 'loading' && <Loading />}
            </div>

            <Select name="Sorteren" options={sortOptions} className="max-lg:hidden" />
            <button
              type="button"
              className="lg:hidden flex items-center gap-2 text-lg font-bold"
              onClick={() => {
                setFiltersOpen(true)
                setScrollable(false)
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none" className="w-4 h-4">
                <g clipPath="url(#clip0_956_10232)">
                  <path
                    d="M11.8749 19L7.12487 15.4375V11.3842L0.791534 4.25917V2.375C0.791534 1.74511 1.04176 1.14102 1.48716 0.695621C1.93255 0.250222 2.53665 0 3.16653 0L15.8332 0C16.4631 0 17.0672 0.250222 17.5126 0.695621C17.958 1.14102 18.2082 1.74511 18.2082 2.375V4.25917L11.8749 11.3842V19ZM8.7082 14.6458L10.2915 15.8333V10.7825L16.6249 3.6575V2.375C16.6249 2.16504 16.5415 1.96367 16.393 1.81521C16.2445 1.66674 16.0432 1.58333 15.8332 1.58333H3.16653C2.95657 1.58333 2.75521 1.66674 2.60674 1.81521C2.45828 1.96367 2.37487 2.16504 2.37487 2.375V3.6575L8.7082 10.7825V14.6458Z"
                    fill="#1D1D1B"
                  />
                </g>
              </svg>
              Filters
            </button>
          </div>
          {children}
          {hits === 0 && <NoResults />}

          <div className="flex justify-center spacing--big--top">
            <Pagination total={total} limit={limit} />
          </div>
        </div>
      </div>
    </div>
  )
}
