import clsx from 'clsx'
import type { Page_Flexcontent_Flex_Posts, Post } from '~/graphql/types'
import useIsVisible from '~/hooks/useIsVisible'
import Case from './post-types/Case'
import { Fragment } from 'react'
import Links from '~/components/elements/Links'

export default function CasesHighlighted({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const [expertsRef, expertsIsVisible] = useIsVisible()

  return (
    <section data-component="CasesHighlighted" className="section">
      <div className="container flex lg:flex-row lg:items-end flex-col gap-4 lg:gap-8">
        <div className="relative mb-3 z-10">
          <h2 className="title--large pl-5 relative !leading-[0.8] text-site-base">
            <div className="bg-site-accent absolute h-14 sm:h-20 w-20 sm:w-40 lg:h-[126px] lg:w-[188px] left-0 top-4 xl:top-10 -z-10" />
            {fields.title.split('|').map((t, i) => (
              <Fragment key={i}>
                <span className={clsx(i !== 0 && 'pl-10 md:pl-36')}>{t}</span>
                {i < fields.title.split('|').length - 1 && <br />}
              </Fragment>
            ))}
          </h2>
        </div>
        <div className="mb-3 max-sm:hidden">
          <Links icons="arrow" items={fields.links} />
        </div>
      </div>
      <div className="container">
        <div className="max-w-screen-xl mx-auto">
          <div
            ref={expertsRef}
            className={clsx(
              expertsIsVisible && 'animate-fade-up animate-delay-500 animate-ease-in-out animate-duration-500',
              expertsIsVisible ? 'opacity-100' : 'opacity-0',
              'grid grid-cols-1 lg:grid-cols-5 pt-8 lg:pt-16 gap-6 lg:gap-10 xl:pt-16'
            )}
          >
            {fields.relations?.map((edge, index) => {
              const post = edge as Post

              return <Case type={index % 4 === 1 || index % 4 === 2 ? 'small' : 'big'} key={index} fields={post} />
            })}
          </div>
        </div>
      </div>
      <div className="spacing--big--top sm:hidden container">
        <Links icons="arrow" items={fields.links} />
      </div>
    </section>
  )
}
