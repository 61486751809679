import { useLosseData } from '@ubo/losse-sjedel'
import type { Component_Popup } from '~/graphql/types'
import usePopup from '~/hooks/usePopup'
import Modal from '../../elements/Modal'
import Loading from '../../elements/Loading'
import Content from '../../elements/Content'
import Form from '../../elements/Form'
import { useState } from 'react'

export default function PopupHelp() {
  const [isOpen, setOpen] = usePopup('laat-ons-je-helpen')

  return (
    <Modal show={isOpen} setShow={setOpen}>
      <ModalContent />
    </Modal>
  )
}

function ModalContent() {
  const [formSend, setFormSend] = useState(false)
  const [response, state] = useLosseData<{ data: Component_Popup }>('Popup', {
    id: 302
  })

  if (state === 'loading') {
    return (
      <div className="flex--center py-20">
        <Loading />
      </div>
    )
  }

  return state === 'done' ? (
    <>
      {response?.data?.title && <h2 className="title--medium text-site-base mb-6">{response?.data?.title}</h2>}
      {!formSend && <Content className="mb-4 text-sm children-p:leading-6">{response?.data?.description}</Content>}
      <div className="md:px-4">
        <Form
          data={response?.data?.form}
          theme="light"
          submit="alternative"
          onSubmitDone={() => {
            setFormSend(true)
          }}
        />
      </div>
    </>
  ) : null
}
