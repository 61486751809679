import { LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import Links from '~/components/elements/Links'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentSmallImage({ fields, index }: { fields: Page_Flexcontent_Flex_Content; index: number }) {
  return (
    <section data-component="ContentSmallImage" className="section section--with-bg bg-site-grey-light " id={fields.sectionId}>
      <div className="container lg:grid flex flex-col grid-cols-3 lg:gap-20 gap-10">
        <div className="max-sm:flex--center">
          <LossePlaatjie
            src={fields.image}
            maxWidth={300}
            className="object-contain max-w-full lg:max-h-full max-h-[300px] sm:object-left"
          />
        </div>

        <div className="flex flex-col col-span-2 gap-y-6 lg:pr-20 xl:pr-36">
          {fields.title && <h2 className="title--normal">{fields.title}</h2>}
          {fields.description && (
            <Content data-id={`content-${index}`} className="content list--stripe--accent">
              {fields?.description}
            </Content>
          )}
          {fields.links && <Links items={fields.links} />}
        </div>
      </div>
    </section>
  )
}
