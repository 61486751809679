import clsx from 'clsx'
import { Fragment, useEffect, useState } from 'react'
import Content from '~/components/elements/Content'
import Links from '~/components/elements/Links'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import { useComponentTheme } from './ContentColumnsBig'
import useIsVisible from '~/hooks/useIsVisible'

export default function ContentDefault({ fields, index }: { fields: Page_Flexcontent_Flex_Content; index: number }) {
  // We don't want to have read more's everywhere in the SSR, so we use null for the initial state
  const [ref, isVisible] = useIsVisible()
  const [isOverflowing, setIsOverflowing] = useState<boolean | null>(null)
  const { theme } = useComponentTheme(index, fields?.styletype)

  let widthClass = ''

  switch (fields.width) {
    case 'sm':
      widthClass = 'max-w-screen-sm'
      break
    case 'md':
      widthClass = 'max-w-screen-md'
      break
    case 'lg':
      widthClass = 'max-w-screen-lg'
      break
    case 'xl':
      widthClass = 'max-w-screen-xl'
      break
  }

  let lineClampClass = ''

  switch (fields.lineclamp) {
    case '3':
      lineClampClass = 'line-clamp-3'
      break
    case '5':
      lineClampClass = 'line-clamp-5'
      break
    case '7':
      lineClampClass = 'line-clamp-7'
      break
  }

  useEffect(() => {
    if (lineClampClass === '') {
      return
    }

    function checkForTextOverflowEllipsis(elements: NodeListOf<Element>) {
      const elementsWithOverflow: Element[] = []

      elements.forEach((element) => {
        if (element.scrollHeight > element.clientHeight) {
          elementsWithOverflow.push(element)
        }
      })

      return elementsWithOverflow
    }

    const elements = document.querySelectorAll(`[data-id="content-${index}"] *`)
    if (!elements) return
    setIsOverflowing(checkForTextOverflowEllipsis(elements).length > 0)
    // eslint-disable-next-line
  }, [])

  return (
    <section
      data-component="ContentDefault"
      className={clsx(theme === 'light' && 'section', theme === 'none' && 'section--large')}
      id={fields.sectionId}
      ref={ref}
    >
      <div className={clsx('container mx-auto', widthClass)}>
        {fields.title && (
          <h2
            className={clsx('title--medium', isVisible && 'animate-fade-up animate-ease-in-out', isVisible ? 'opacity-100' : 'opacity-0')}
          >
            {fields.title}
          </h2>
        )}
        {fields.subtitle && (
          <h3
            className={clsx(
              'title--small mt-3 text-site-accent',
              isVisible && 'animate-fade-up animate-ease-in-out animate-delay-200',
              isVisible ? 'opacity-100' : 'opacity-0'
            )}
          >
            {fields.subtitle}
          </h3>
        )}
        {fields.description && (
          <>
            <Content
              data-id={`content-${index}`}
              type="blog"
              className={clsx(
                'content list--stripe--accent mt-6',
                isOverflowing === null || isOverflowing === true ? lineClampClass : undefined,
                theme === 'none' && 'lg:children-li:text-lg',
                isVisible && 'animate-fade-up animate-ease-in-out animate-delay-300',
                isVisible ? 'opacity-100' : 'opacity-0'
              )}
            >
              {fields?.description}
            </Content>

            {fields.lineclamp && (
              <div className="-mt-6 relative">
                <div className={clsx('bg-white/50 w-full h-6', !isOverflowing && 'opacity-0')} />

                <button
                  onClick={() => setIsOverflowing(!isOverflowing)}
                  className="mt-4 font-bold flex items-center gap-x-1 text-sm"
                  type="button"
                >
                  Lees {isOverflowing ? 'meer' : 'minder'}
                  {/* chevron */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    fill="none"
                    viewBox="0 0 12 12"
                    className={clsx(!isOverflowing && 'rotate-180')}
                  >
                    <g clipPath="url(#clip0_813_6560)">
                      <path
                        fill="#1D1D1B"
                        d="M3 11.25a.75.75 0 01.22-.53l3.835-3.836a1.25 1.25 0 000-1.768l-3.83-3.83A.75.75 0 014.284.224l3.831 3.83a2.752 2.752 0 010 3.89L4.28 11.78A.75.75 0 013 11.25z"
                      ></path>
                    </g>
                  </svg>
                </button>
              </div>
            )}
          </>
        )}
        {fields.items && (
          <div className="grid grid-cols-1 sm:grid-cols-2 mt-6 md:mt-10 gap-8">
            {fields.items.map((item, i) => (
              <div key={i}>
                <Content type="blog" className="list--stripe--accent">
                  {item.description}
                </Content>
              </div>
            ))}
          </div>
        )}
        {fields.links && (
          <Links
            className="mt-6 sm:mt-10 max-sm:flex-wrap max-xs:children-div:w-full max-xs:children-a:children-div:w-full"
            items={fields.links}
          />
        )}
      </div>
    </section>
  )
}
