/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { LosseLink, useLoaderData, useLocation, useLosseLayout } from '@ubo/losse-sjedel'
import HeaderMenu from './HeaderMenu'
import Logo from '~/components/elements/Logo'
import { AnimatePresence, motion } from 'framer-motion'
import { useHeaderTheme, type HeaderLoaderData } from './Header'
import clsx from 'clsx'

export default function HeaderMobile() {
  const { isScrollable, setScrollable } = useLosseLayout()
  const { header } = useLoaderData<HeaderLoaderData>()
  const location = useLocation()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const headerTheme = useHeaderTheme()

  useEffect(() => {
    if (mobileMenuOpen) {
      setMobileMenuOpen(false)
    }
  }, [location.pathname, location.hash, location.search])

  useEffect(() => {
    if (isScrollable && mobileMenuOpen) setScrollable(false)
    if (!isScrollable && !mobileMenuOpen) setScrollable(true)
  }, [mobileMenuOpen])

  const contactMenuItem = header?.menuHeader?.find((edge) => edge?.link?.url === '/contact/')

  return (
    <>
      <div className="block lg:hidden">
        <button
          type="button"
          onClick={() => {
            setMobileMenuOpen(!mobileMenuOpen)
          }}
          className={clsx(
            headerTheme === 'light' && 'text-black',
            headerTheme === 'dark' && 'text-white',
            'z-10 h-16 w-16 flex--center rounded-full focus:bg-site-accent text-center text-xs'
          )}
          title="Open menu"
        >
          <div className="w-8">
            <div
              className={clsx(
                headerTheme === 'light' && 'bg-black',
                headerTheme === 'dark' && 'bg-white',
                'mx-auto my-[6px] h-[3px] rounded-full w-full'
              )}
            />
            <div
              className={clsx(
                headerTheme === 'light' && 'bg-black',
                headerTheme === 'dark' && 'bg-white',
                'mx-auto my-[6px] h-[3px] rounded-full w-full'
              )}
            />
            <div
              className={clsx(
                headerTheme === 'light' && 'bg-black',
                headerTheme === 'dark' && 'bg-white',
                'mx-auto my-[6px] h-[3px] rounded-full w-full'
              )}
            />
            Menu
          </div>
        </button>
      </div>
      <AnimatePresence>
        {mobileMenuOpen && (
          <motion.div
            initial={{ y: '-100%' }}
            animate={{ y: 0 }}
            exit={{ y: '-100%' }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            className="fixed left-0 top-0 z-[100] h-full w-full bg-site-base"
          >
            <div className="container pt-10 flex flex-col justify-between h-full">
              <div>
                <div className="flex items-center justify-between xs:px-6">
                  <LosseLink to="/" aria-label="Home">
                    {/* 0,210650887573964 */}
                    <Logo className="h-[32px] w-[150px] text-white" />
                  </LosseLink>
                  <button
                    type="button"
                    onClick={() => {
                      setMobileMenuOpen(!mobileMenuOpen)
                    }}
                    aira-label="Close menu"
                  >
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="Group">
                        <path
                          id="Vector"
                          d="M9.41416 8.00022L15.7072 1.70782C16.0979 1.31718 16.0979 0.683833 15.7072 0.293224C15.3166 -0.0974159 14.6833 -0.0974159 14.2927 0.293224L8.00022 6.58628L1.70782 0.293224C1.31718 -0.0974159 0.683833 -0.0974159 0.293224 0.293224C-0.0973846 0.683865 -0.0974159 1.31721 0.293224 1.70782L6.58628 8.00022L0.293224 14.2927C-0.0974159 14.6833 -0.0974159 15.3166 0.293224 15.7072C0.683865 16.0979 1.31721 16.0979 1.70782 15.7072L8.00022 9.41416L14.2926 15.7072C14.6833 16.0979 15.3166 16.0979 15.7072 15.7072C16.0979 15.3166 16.0979 14.6833 15.7072 14.2927L9.41416 8.00022Z"
                          fill="white"
                        />
                      </g>
                    </svg>
                  </button>
                </div>
                <ul className="mt-20 flex flex-col gap-10 px-6 sm:px-16">
                  <HeaderMenu setMobileMenuOpen={setMobileMenuOpen} />
                </ul>
              </div>
              <div className="py-20 px-6 xs:px-10 flex justify-center">
                <LosseLink className="btn--conversion w-full xs:w-[80%] !text-xl xs:!py-3" src={contactMenuItem.link} />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}
