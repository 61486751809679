export function SomethingWentWrong() {
  return (
    <section data-component="PostsVacancies" className="header--margin--top--negative">
      <div className="bg-site-base header--padding--top">
        <div className="container py-12">
          <h1 className="text-white title--semi-large text-center">Er ging iets fout</h1>
        </div>
      </div>
    </section>
  )
}
