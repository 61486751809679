import clsx from 'clsx'

export default function VacancyAlert({ className }: { className?: string }) {
  return (
    <div className={clsx('bg-site-base px-7 pt-8 pb-12', className)}>
      <h2 className="title--normal text-white">Vacature alert ontvangen?</h2>
      <p className="text-sm text-site-accent font-normal mt-6">Ontvang alleen een alert als wij een passende baan voor jou hebben.</p>
      <div className="mt-10 flex justify-center">
        <a href="#vacature-alert" className="btn--accent">
          Aanmelden
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-5 h-5">
            <g id="01 align center">
              <path
                id="Vector"
                d="M1.44382 6.66874C1.10898 6.76442 0.804917 6.94586 0.561729 7.19513C0.318541 7.44439 0.144653 7.75284 0.0572715 8.08994C-0.0301104 8.42704 -0.0279575 8.78112 0.0635171 9.11713C0.154992 9.45315 0.332618 9.75945 0.578818 10.0057L3.99082 13.4137V19.9997H10.5838L14.0188 23.4297C14.2032 23.6157 14.4225 23.7634 14.6641 23.8643C14.9058 23.9652 15.165 24.0173 15.4268 24.0177C15.5989 24.0174 15.7703 23.9952 15.9368 23.9517C16.2738 23.8669 16.5824 23.6947 16.8317 23.4526C17.081 23.2105 17.2621 22.9071 17.3568 22.5727L23.9908 0.020752L1.44382 6.66874ZM1.99982 8.59174L19.0278 3.57175L5.99282 16.5857V12.5857L1.99982 8.59174ZM15.4398 22.0157L11.4128 17.9997H7.41282L20.4458 4.97775L15.4398 22.0157Z"
                fill="white"
              />
            </g>
          </svg>
        </a>
      </div>
    </div>
  )
}
