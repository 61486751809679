/* eslint-disable react-hooks/exhaustive-deps */
import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect } from 'react'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  show: boolean
  setShow: (show: boolean) => void
}

export default function ModalFullScreen({ show, setShow, children, ...rest }: Props) {
  function handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Escape') setShow(false)
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown)

    return () => {
      document.removeEventListener('keydown', handleKeydown)
    }
  }, [])

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
          className="fixed left-0 top-0 w-full h-full z-50 bg-site-base"
        >
          <div className="absolute right-6 top-6 lg:right-10 lg:top-10">
            <button
              type="button"
              onClick={() => {
                setShow(!show)
              }}
              className="btn--pseudo btn--pseudo--conversion text-white rounded-full p-2"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5 lg:w-6 lg:h-6"
              >
                <g id="Group">
                  <path
                    id="Vector"
                    d="M14.1209 12.0002L23.5605 2.56161C24.1465 1.97565 24.1465 1.02563 23.5605 0.439714C22.9745 -0.146246 22.0245 -0.146246 21.4386 0.439714L12 9.8793L2.56137 0.439714C1.97541 -0.146246 1.02538 -0.146246 0.43947 0.439714C-0.146443 1.02567 -0.14649 1.9757 0.43947 2.56161L9.87905 12.0002L0.43947 21.4389C-0.14649 22.0248 -0.14649 22.9748 0.43947 23.5608C1.02543 24.1467 1.97545 24.1467 2.56137 23.5608L12 14.1211L21.4386 23.5608C22.0245 24.1467 22.9745 24.1467 23.5605 23.5608C24.1464 22.9748 24.1464 22.0248 23.5605 21.4389L14.1209 12.0002Z"
                    fill="currentColor"
                  />
                </g>
              </svg>
            </button>
          </div>
          <div className="flex--center h-full" {...rest}>
            {children}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
