import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import BannerDefault from './BannerDefault'
import BannerUnit from './BannerUnit'
import BannerCrosshair from './BannerCrosshair'
import BannerFocusImage from './BannerFocusImage'
import BannerVacancy from './BannerVacancy'
import BannerWhitepaper from './BannerWhitepaper'
import BannerWithSearch from './BannerWithSearch'
import BannerColumns from './BannerColumns'
import BannerFunction from './BannerFunction'
import BannerLanding from './BannerLanding'
import BannerNews from './BannerNews'
import BannerExpert from './BannerExpert'
import BannerCase from './BannerCase'
import BannerProof from './BannerProof'
import BannerDisciplines from './BannerDisciplines'

export default function BannerShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    banner_with_search: BannerWithSearch,
    banner_default: BannerDefault,
    banner_unit: BannerUnit,
    banner_crosshair: BannerCrosshair,
    banner_focus_image: BannerFocusImage,
    banner_vacancy: BannerVacancy,
    banner_whitepaper: BannerWhitepaper,
    banner_columns: BannerColumns,
    banner_function: BannerFunction,
    banner_landing: BannerLanding,
    banner_news: BannerNews,
    banner_expert: BannerExpert,
    banner_case: BannerCase,
    banner_proof: BannerProof,
    banner_disciplines: BannerDisciplines
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
