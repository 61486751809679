import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import FormContact from './FormContact'
import FormWhitepaper from './FormWhitepaper'
import FormContent from './FormContent'
import FormApply from './FormApply'
import FormPerfectMatch from './FormPerfectMatch'

export default function FormShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    form_contact: FormContact,
    form_whitepaper: FormWhitepaper,
    form_content: FormContent,
    form_apply: FormApply,
    form_perfect_match: FormPerfectMatch
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
