import Content from '~/components/elements/Content'
import Links from '~/components/elements/Links'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentRankings({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentRankings" className="section" id={fields?.sectionId}>
      <div className="container grid md:grid-cols-2 gap-16">
        <div>
          <div className="title--normal pb-6 md:pb-12">{fields?.title}</div>
          <Content className="children-p:font-light">{fields?.description}</Content>
          <Links className="mt-8" items={fields?.links} />
          {fields?.video && (
            <div className="grid grid-cols-1 mt-16">
              <iframe
                width="100%"
                height="100%"
                className="aspect-video w-[100%]"
                src={fields?.video}
                title="Profield presents!"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          )}
        </div>
        <div className="container grid content-between mx-auto h-fit w-full xl:w-[510px] p-6 sm:p-8 bg-site-base content--dark">
          <div className="flex flex-col sm:flex-row gap-4 sm:h-[75px] lg:h-[105px] my-4">
            <div className="title--unit lg:title--chonker border-text--white text-transparent">{fields?.subtitle.split('|')[0]}</div>
            <div className="text-3xl font-bold flex items-end h-[100%]">{fields?.subtitle.split('|')[1]}</div>
          </div>
          <div className="md:container my-1 md:my-2 xl:my-4">
            {fields?.items?.map((item, key) => (
              <div key={key} className="py-2 md:py-4 xl:py-7">
                <div className="title--light">{item.title}</div>
                <div className="text-lg font-thin">{item.subtitle}</div>
              </div>
            ))}
          </div>
          <div className="md:container md:pb-8">
            <div className="title--small py-6">{fields?.extratitle}</div>
            <Content className="children-p:font-light">{fields?.extradescription}</Content>
          </div>
        </div>
      </div>
    </section>
  )
}
