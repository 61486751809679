import { LosseLink } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useState } from 'react'
import Content from '~/components/elements/Content'
import Stars from '~/components/elements/Stars'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import useGlobalCounters from '~/hooks/useGlobalCounters'
import useIsVisible from '~/hooks/useIsVisible'

export default function ContentQuotes({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const [current, setCurrent] = useState(0)

  const [contentRef, contentVisible] = useIsVisible()

  return (
    <section data-component="ContentQuotes" className="relative max-lg:section lg:overflow-hidden">
      <div className="container">
        <div className="grid grid-cols-1 lg:grid-cols-12">
          <div className="lg:hidden block relative">
            <div className="absolute -left-8 -top-7 bg-site-accent w-[100px] h-[65px]" />
            <h2 className="border-text text-transparent relative z-10 text-6xl font-bold">{fields.title}</h2>
          </div>
          <div className="col-span-1 lg:col-span-5 flex items-center justify-center relative">
            <div
              id="top"
              className={clsx(
                contentVisible && 'animate-fade-down animate-ease-in-out animate-duration-500 animate-delay-200',
                contentVisible ? 'opacity-100' : 'opacity-0',
                'absolute w-full  lg:block hidden lg:w-[250px] h-[75px] left-0 right-0 mx-auto top-0 bg-site-accent'
              )}
            />
            <div
              id="bottom"
              className={clsx(
                contentVisible && 'animate-fade-up animate-ease-in-out animate-duration-500 animate-delay-200',
                contentVisible ? 'opacity-100' : 'opacity-0',
                'absolute lg:block hidden w-[250px] h-[75px] left-0 right-0 mx-auto bottom-0 bg-site-accent'
              )}
            />
            <div
              id="left"
              className={clsx(
                contentVisible && 'animate-fade-right animate-ease-in-out animate-duration-500 animate-delay-200',
                contentVisible ? 'opacity-100' : 'opacity-0',
                'absolute h-[250px] w-[300px] lg:block hidden top-0 bottom-0 my-auto -left-[300px] bg-site-accent'
              )}
            />

            {fields.items.length > 1 && (
              <>
                <svg
                  onClick={() => setCurrent((current + 1) % fields.items.length)}
                  className="top-0 group cursor-pointer bottom-0 absolute right-5 xl:right-10 my-auto"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  aria-label="Volgende"
                >
                  <g id="fi-br-angle-up" clipPath="url(#clip0_1761_3138)">
                    <path
                      id="Vector"
                      d="M5.49999 20.6253C5.50003 20.4446 5.53567 20.2657 5.60487 20.0988C5.67408 19.932 5.7755 19.7804 5.90332 19.6527L12.9351 12.6209C13.1479 12.4081 13.3168 12.1555 13.432 11.8774C13.5472 11.5993 13.6065 11.3013 13.6065 11.0003C13.6065 10.6993 13.5472 10.4012 13.432 10.1232C13.3168 9.84508 13.1479 9.59242 12.9351 9.37961L5.91157 2.35611C5.66111 2.09678 5.52251 1.74945 5.52565 1.38893C5.52878 1.02841 5.67339 0.683543 5.92832 0.428607C6.18326 0.173671 6.52812 0.0290633 6.88865 0.0259305C7.24917 0.0227977 7.59649 0.16139 7.85582 0.411857L14.8793 7.43536C15.8236 8.38144 16.354 9.66355 16.354 11.0003C16.354 12.337 15.8236 13.6191 14.8793 14.5652L7.84665 21.5979C7.65431 21.79 7.40933 21.9207 7.14268 21.9737C6.87603 22.0266 6.59968 21.9993 6.34854 21.8952C6.0974 21.7911 5.88276 21.6149 5.73172 21.3889C5.58069 21.1629 5.50005 20.8971 5.49999 20.6253Z"
                      className="fill-site-base group-hover:fill-site-accent transition-all duration-200"
                    />
                  </g>
                </svg>
                <svg
                  onClick={() => setCurrent((current + fields.items.length - 1) % fields.items.length)}
                  className="top-0 group cursor-pointer rotate-180 bottom-0 absolute left-5 xl:left-10 my-auto"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  aria-label="Vorige"
                >
                  <g id="fi-br-angle-up" clipPath="url(#clip0_1761_3138)">
                    <path
                      id="Vector"
                      d="M5.49999 20.6253C5.50003 20.4446 5.53567 20.2657 5.60487 20.0988C5.67408 19.932 5.7755 19.7804 5.90332 19.6527L12.9351 12.6209C13.1479 12.4081 13.3168 12.1555 13.432 11.8774C13.5472 11.5993 13.6065 11.3013 13.6065 11.0003C13.6065 10.6993 13.5472 10.4012 13.432 10.1232C13.3168 9.84508 13.1479 9.59242 12.9351 9.37961L5.91157 2.35611C5.66111 2.09678 5.52251 1.74945 5.52565 1.38893C5.52878 1.02841 5.67339 0.683543 5.92832 0.428607C6.18326 0.173671 6.52812 0.0290633 6.88865 0.0259305C7.24917 0.0227977 7.59649 0.16139 7.85582 0.411857L14.8793 7.43536C15.8236 8.38144 16.354 9.66355 16.354 11.0003C16.354 12.337 15.8236 13.6191 14.8793 14.5652L7.84665 21.5979C7.65431 21.79 7.40933 21.9207 7.14268 21.9737C6.87603 22.0266 6.59968 21.9993 6.34854 21.8952C6.0974 21.7911 5.88276 21.6149 5.73172 21.3889C5.58069 21.1629 5.50005 20.8971 5.49999 20.6253Z"
                      className="fill-site-base group-hover:fill-site-accent transition-all duration-200"
                    />
                  </g>
                </svg>
              </>
            )}

            <div className="max-w-[250px] sm:max-w-[300px] max-lg:my-[50px]">
              <h3
                key={`${current}-title`}
                className="animate-fade-up animate-ease-in-out animate-duration-150 text-2xl sm:text-3xl text-center font-bold mb-2 sm:mb-4"
              >
                {fields.items[current].title}
              </h3>
              <div key={`${current}-subtitle`} className="animate-fade-up animate-ease-in-out animate-duration-300 animate-delay-75 ">
                <h4 className="text-xl sm:text-3xl text-center !opacity-50 font-bold">{fields.items[current].subtitle}</h4>
              </div>
            </div>
          </div>
          <div className="col-span-1 lg:col-span-7 lg:my-44 xl:my-52 max-lg:-mt-[50px]">
            <div
              ref={contentRef}
              className={clsx(
                contentVisible && 'animate-fade-up animate-ease-in-out animate-duration-500',
                contentVisible ? 'opacity-100' : 'opacity-0',
                'relative'
              )}
            >
              <div className="absolute lg:block hidden bg-site-accent w-[300px] -right-[300px] h-full top-0" />
              <h2 className="border-text lg:block hidden text-transparent -z-10 left-2 sm:left-4 bottom-[calc(100%_-_8px)] lg:bottom-[calc(100%_-_13px)] absolute title--semi-large sm:title--large">
                {fields.title}
              </h2>
              <Content
                type="blog"
                key={`${current}-content`}
                className="animate-fade-left max-lg:mt-8 animate-ease-in-out animate-duration-500 lg:bg-site-accent z-10 min-h-[250px] lg:px-14 lg:py-7 "
              >
                {fields.items[current].description}
              </Content>
              {fields.items[current].link && (
                <LosseLink
                  key={`${current}-link`}
                  className="animate-fade-up mt-4 flex lg:hidden animate-ease-in-out animate-duration-200 animate-delay-500 font-bold items-center gap-3 hover:text-site-accent transition-all duration-200"
                  target={fields.items[current].link.target}
                  to={fields.items[current].link.url}
                >
                  {fields.items[current].link.title}
                  <svg width="14" height="14" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="fi-br-angle-up" clipPath="url(#clip0_1761_3138)">
                      <path
                        id="Vector"
                        d="M5.49999 20.6253C5.50003 20.4446 5.53567 20.2657 5.60487 20.0988C5.67408 19.932 5.7755 19.7804 5.90332 19.6527L12.9351 12.6209C13.1479 12.4081 13.3168 12.1555 13.432 11.8774C13.5472 11.5993 13.6065 11.3013 13.6065 11.0003C13.6065 10.6993 13.5472 10.4012 13.432 10.1232C13.3168 9.84508 13.1479 9.59242 12.9351 9.37961L5.91157 2.35611C5.66111 2.09678 5.52251 1.74945 5.52565 1.38893C5.52878 1.02841 5.67339 0.683543 5.92832 0.428607C6.18326 0.173671 6.52812 0.0290633 6.88865 0.0259305C7.24917 0.0227977 7.59649 0.16139 7.85582 0.411857L14.8793 7.43536C15.8236 8.38144 16.354 9.66355 16.354 11.0003C16.354 12.337 15.8236 13.6191 14.8793 14.5652L7.84665 21.5979C7.65431 21.79 7.40933 21.9207 7.14268 21.9737C6.87603 22.0266 6.59968 21.9993 6.34854 21.8952C6.0974 21.7911 5.88276 21.6149 5.73172 21.3889C5.58069 21.1629 5.50005 20.8971 5.49999 20.6253Z"
                        fill="#1D1D1B"
                      />
                    </g>
                  </svg>
                </LosseLink>
              )}
              <div className="justify-end lg:flex hidden sm:justify-between items-center w-full absolute h-16 z-10 top-full left-4 pl-4">
                {fields.items[current].link ? (
                  <LosseLink
                    key={`${current}-link`}
                    className="animate-fade-up sm:flex hidden animate-ease-in-out animate-duration-200 animate-delay-500 font-bold items-center gap-3 hover:text-site-accent transition-all duration-200"
                    target={fields.items[current].link.target}
                    to={fields.items[current].link.url}
                  >
                    {fields.items[current].link.title}
                    <svg width="14" height="14" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="fi-br-angle-up" clipPath="url(#clip0_1761_3138)">
                        <path
                          id="Vector"
                          d="M5.49999 20.6253C5.50003 20.4446 5.53567 20.2657 5.60487 20.0988C5.67408 19.932 5.7755 19.7804 5.90332 19.6527L12.9351 12.6209C13.1479 12.4081 13.3168 12.1555 13.432 11.8774C13.5472 11.5993 13.6065 11.3013 13.6065 11.0003C13.6065 10.6993 13.5472 10.4012 13.432 10.1232C13.3168 9.84508 13.1479 9.59242 12.9351 9.37961L5.91157 2.35611C5.66111 2.09678 5.52251 1.74945 5.52565 1.38893C5.52878 1.02841 5.67339 0.683543 5.92832 0.428607C6.18326 0.173671 6.52812 0.0290633 6.88865 0.0259305C7.24917 0.0227977 7.59649 0.16139 7.85582 0.411857L14.8793 7.43536C15.8236 8.38144 16.354 9.66355 16.354 11.0003C16.354 12.337 15.8236 13.6191 14.8793 14.5652L7.84665 21.5979C7.65431 21.79 7.40933 21.9207 7.14268 21.9737C6.87603 22.0266 6.59968 21.9993 6.34854 21.8952C6.0974 21.7911 5.88276 21.6149 5.73172 21.3889C5.58069 21.1629 5.50005 20.8971 5.49999 20.6253Z"
                          fill="#1D1D1B"
                        />
                      </g>
                    </svg>
                  </LosseLink>
                ) : (
                  <div />
                )}
                <Reviews />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function Reviews({ className }: { className?: string }) {
  const globalCounters = useGlobalCounters()

  return (
    <LosseLink
      to="/beoordelingen/"
      className={clsx(
        'bg-site-base mr-4 animate-fade-down animate-ease-in-out animate-duration-500 animate-delay-700 -z-10 max-w-[360px] text-white h-full group hover:bg-site-base/75 flex items-center gap-4 px-5 py-1',
        className
      )}
    >
      <Stars rating={globalCounters?.reviews?.average} size="medium" />
      <span className="text-sm md:text-sm font-light md:font-bold group-hover:underline">
        Andere pro's beoordelen ons met een {globalCounters?.reviews?.average?.toString()}
      </span>
    </LosseLink>
  )
}
