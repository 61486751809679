import type { Maybe, Component_Header_MenuHeader_SubmenuSmall } from '~/graphql/types'
import { motion } from 'framer-motion'
import { LosseLink } from '@ubo/losse-sjedel'
import useIsMobile from '~/hooks/useIsMobile'

export default function HeaderSubmenu({
  fields,
  setCurrentIndex
}: {
  fields: Maybe<Component_Header_MenuHeader_SubmenuSmall[]>
  setCurrentIndex: (index: number) => void
}) {
  const isMobile = useIsMobile()

  return (
    <motion.div
      className="max-lg:fixed max-lg:h-full top-0 absolute lg:top-[calc(100%+theme(space.4))] left-0 bg-white lg:rounded-lg lg:shadow lg:w-[225px] w-full"
      initial={isMobile ? { x: '100%' } : { y: -5, opacity: 0 }}
      animate={isMobile ? { x: 0 } : { y: 0, opacity: 1 }}
      exit={isMobile ? { x: '100%' } : { y: -5, opacity: 0 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
    >
      <div className="absolute bottom-full left-0 w-full h-4" />
      <div className="max-lg:container max-lg:pt-10">
        <button
          type="button"
          onClick={() => {
            setCurrentIndex(-1)
          }}
          className="flex items-center gap-2 text-xl lg:hidden font-bold"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none" className="w-7 h-7 rotate-90">
            <path
              d="M13.2528 6.31542C13.187 6.24903 13.1086 6.19633 13.0223 6.16037C12.936 6.12441 12.8434 6.1059 12.7499 6.1059C12.6564 6.1059 12.5638 6.12441 12.4775 6.16037C12.3912 6.19633 12.3128 6.24903 12.247 6.31542L9.00283 9.55958C8.93698 9.62597 8.85864 9.67867 8.77232 9.71463C8.686 9.75059 8.59342 9.7691 8.49991 9.7691C8.4064 9.7691 8.31382 9.75059 8.2275 9.71463C8.14119 9.67867 8.06284 9.62597 7.99699 9.55958L4.75283 6.31542C4.68698 6.24903 4.60864 6.19633 4.52232 6.16037C4.436 6.12441 4.34342 6.1059 4.24991 6.1059C4.1564 6.1059 4.06382 6.12441 3.9775 6.16037C3.89118 6.19633 3.81284 6.24903 3.74699 6.31542C3.61507 6.44813 3.54102 6.62766 3.54102 6.81479C3.54102 7.00192 3.61507 7.18145 3.74699 7.31417L6.99824 10.5654C7.39668 10.9634 7.93678 11.1869 8.49991 11.1869C9.06304 11.1869 9.60314 10.9634 10.0016 10.5654L13.2528 7.31417C13.3848 7.18145 13.4588 7.00192 13.4588 6.81479C13.4588 6.62766 13.3848 6.44813 13.2528 6.31542Z"
              fill="currentColor"
            />
          </svg>
          Terug
        </button>
        <div className="lg:py-4 flex flex-col gap-3 lg:gap-1 max-lg:mt-10">
          {fields?.map((item, index) => (
            <LosseLink
              key={index}
              to={item?.link?.url}
              target={item?.link?.target}
              className="group flex items-center justify-between py-2 px-4 hover:bg-site-grey-light max-lg:text-lg max-lg:rounded-lg"
            >
              {item?.link?.title}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                className="hidden group-hover:block w-[14px] h-[14px]"
              >
                <g clipPath="url(#clip0_1123_4357)">
                  <path
                    d="M5.49999 20.6252C5.50003 20.4445 5.53567 20.2656 5.60487 20.0987C5.67408 19.9318 5.7755 19.7802 5.90332 19.6526L12.9351 12.6208C13.1479 12.408 13.3168 12.1554 13.432 11.8773C13.5472 11.5992 13.6065 11.3012 13.6065 11.0002C13.6065 10.6992 13.5472 10.4011 13.432 10.123C13.3168 9.84495 13.1479 9.5923 12.9351 9.37949L5.91157 2.35599C5.66111 2.09666 5.52251 1.74933 5.52565 1.38881C5.52878 1.02829 5.67339 0.683421 5.92832 0.428485C6.18326 0.173548 6.52812 0.0289412 6.88865 0.0258084C7.24917 0.0226756 7.59649 0.161268 7.85582 0.411735L14.8793 7.43524C15.8236 8.38132 16.354 9.66343 16.354 11.0002C16.354 12.3369 15.8236 13.619 14.8793 14.5651L7.84665 21.5977C7.65431 21.7898 7.40933 21.9206 7.14268 21.9736C6.87603 22.0265 6.59968 21.9992 6.34854 21.8951C6.0974 21.791 5.88276 21.6148 5.73172 21.3888C5.58069 21.1627 5.50005 20.897 5.49999 20.6252Z"
                    fill="#1D1D1B"
                  />
                </g>
              </svg>
            </LosseLink>
          ))}
        </div>
      </div>
    </motion.div>
  )
}
