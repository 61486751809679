import clsx from 'clsx'
import { useInView } from 'framer-motion'
import { useRef } from 'react'
import Content from '~/components/elements/Content'
import CrossHair from '~/components/elements/CrossHair'
import PageDownArrow from '~/components/elements/PageDownArrow'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'

export default function BannerCrosshair({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const crosshairRef = useRef(null)
  const crosshairVisible = useInView(crosshairRef, { once: true })

  return (
    <section data-component="BannerCrosshair" className="bg-site-base header--margin--top--negative section section--with-bg relative">
      <PageDownArrow className="max-sm:hidden w-[27px] absolute left-0 right-0 mx-auto bottom-8 z-30 animate-bounce animate-ease-in-out" />
      <div className="container header--padding--top">
        <div className="grid grid-cols-5 xs:gap-16 xl:gap-24 pb-8 xs:pb-14 lg:pb-20 xl:pb-32">
          <div ref={crosshairRef} className="col-span-1 lg:col-span-2 relative flex justify-center items-center">
            <CrossHair
              className={clsx(
                crosshairVisible && 'animate-fade--light lg:animate-fade animate-ease-in-out animate-delay-500 animate-duration-[2000]',
                crosshairVisible ? 'opacity-100' : 'opacity-0',
                'max-lg:absolute max-lg:top-0 max-lg:bottom-0 max-lg:my-auto max-lg:-left-10 w-[240px] sm:w-[300px] md:w-[400px] lg:w-full'
              )}
            />
          </div>
          <div className="col-span-5 xs:col-span-4 lg:col-span-3">
            <h1 className="text-white animate-fade-up animate-ease-in-out animate-duration-500 spacing--small--bottom lg:spacing--normal--bottom title--small sm:title--normal lg:title--medium lg:!leading-[55px] font-bold">
              {fields.title}
            </h1>
            <h2 className="text-transparent animate-fade-up animate-delay-150 animate-duration-500 animate-ease-in-out border-text--white title--medium sm:title--semi-large md:title--large xl:title--semi-unit font-bold">
              {fields.subtitle}
            </h2>
          </div>
        </div>
        <div className="max-w-screen-md sm:spacing--big--bottom mx-auto animate-fade-up animate-delay-300 animate-duration-500 animate-ease-in-out">
          <Content theme="dark" className="children-p:font-light">
            {fields.description}
          </Content>
        </div>
      </div>
    </section>
  )
}
