import { LosseLink, LossePlaatjie, losseContentParse } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useState } from 'react'
import Content from '~/components/elements/Content'
import Links from '~/components/elements/Links'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import useIsVisible from '~/hooks/useIsVisible'

export default function ContentDisciplines({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const [titleRef, titleVisible] = useIsVisible()
  const [contentRef, contentVisible] = useIsVisible()
  const [crosshairRef, crosshairVisible] = useIsVisible()

  const hasItems = fields?.items?.length > 0

  const [positionMapping] = useState<
    {
      block: string
      title: string
    }[]
  >([
    {
      block:
        'animate-delay-300 -left-[150px] sm:-left-[200px] xl:-left-[235px] -top-[26px] sm:-top-[43px] w-[90px] h-[56px] sm:w-[144px] sm:h-[90px]',
      title: '-left-[30px] xl:-left-[60px] -top-[22px] xl:-top-[32px] whitespace-nowrap'
    },
    {
      block:
        'animate-delay-[400ms] -top-[150px] sm:-top-[200px] xl:-top-[235px] -left-[26px] sm:-left-[43px] w-[56px] h-[90px] sm:w-[90px] sm:h-[144px]',
      title: 'left-[30px] top-[30px] xl:top-[40px] whitespace-nowrap'
    },
    {
      block:
        'animate-delay-500 -right-[150px] sm:-right-[200px] xl:-right-[235px] -top-[26px] sm:-top-[43px] w-[90px] h-[56px] sm:w-[144px] sm:h-[90px]',
      title: 'left-[10px] sm:left-[25px] xl:left-[40px] -bottom-[20px] xl:-bottom-[30px] whitespace-nowrap'
    },
    {
      block:
        'animate-delay-[600ms] -bottom-[150px] sm:-bottom-[200px] xl:-bottom-[235px] -left-[26px] sm:-left-[43px] w-[56px] h-[90px] sm:w-[90px] sm:h-[144px]',
      title: 'left-[30px] bottom-[30px] xl:bottom-[40px] whitespace-nowrap'
    }
  ])

  return (
    <section data-component="ContentDisciplines" className="section lg:section--large--top" id={fields.sectionId}>
      <div className="container py-5 sm:py-10">
        <div className="grid lg:grid-cols-12 gap-10 xl:gap-16">
          <div className={clsx(hasItems && 'lg:col-span-5', !hasItems && 'lg:col-span-7')}>
            <h2
              ref={titleRef}
              className={clsx(
                titleVisible && 'animate-fade-up animate-ease-in-out animate-duration-500',
                titleVisible ? 'opacity-100' : 'opacity-0',
                'sm:border-text sm:text-transparent max-sm:text-center pb-3 font-bold title--medium sm:title--semi-large xl:title--large'
              )}
            >
              {fields?.title.split('|').map((t, i) => (
                <span className={clsx(i === 1 && 'sm:pl-16')} key={i}>
                  {t}
                  {i === 0 && <br className="sm:block hidden" />}
                </span>
              ))}
            </h2>
            <h3
              className={clsx(
                titleVisible && 'animate-fade-up animate-ease-in-out animate-duration-500 animate-delay-100',
                titleVisible ? 'opacity-100' : 'opacity-0',
                'text-site-accent max-sm:text-center spacing--semi-small--bottom title--light sm:title--small xl:title--normal !font-light sm:font-bold'
              )}
            >
              {fields?.subtitle}
            </h3>
            {hasItems && (
              <div className="sm:hidden flex flex-col mb-10 gap-3">
                {fields.items.map((item, i) => (
                  <LosseLink to={item.link.url || '/'} className="btn--outline--black text-2xl" key={i}>
                    {losseContentParse(item.link.title.replace('|', ''))}
                  </LosseLink>
                ))}
              </div>
            )}
            <div
              className={clsx(
                contentVisible && 'animate-fade-up animate-ease-in-out animate-delay-200 animate-duration-500',
                contentVisible ? 'opacity-100' : 'opacity-0'
              )}
              ref={contentRef}
            >
              <Content theme="light">{fields?.description}</Content>
            </div>
            {fields?.links?.length > 0 && <Links className="spacing--semi-small--top" items={fields.links} />}
          </div>
          {!hasItems && fields?.image && (
            <div className="lg:col-span-5 flex--center">
              <LossePlaatjie src={fields?.image} className="max-w-[400px]" />
            </div>
          )}
          {hasItems && (
            <div
              ref={crosshairRef}
              className="max-sm:min-h-[380px] sm:flex hidden max-lg:min-h-[550px] lg:col-span-7 relative items-center justify-center"
            >
              <div className="absolute w-1 h-1  left-0 top-0 right-0 bottom-0 m-auto">
                {fields.items.map((item, i) => (
                  <LosseLink to={item.link.url} className="group" key={i}>
                    <div
                      className={clsx(
                        crosshairVisible && 'animate-fade-up animate-ease-in-out animate-duration-500',
                        crosshairVisible ? 'opacity-100' : 'opacity-0',
                        positionMapping[i].block,
                        'absolute bg-site-grey group-hover:bg-site-accent duration-200 transition-all'
                      )}
                    >
                      <div
                        className={clsx(
                          positionMapping[i].title,
                          'absolute text-xl text-site-base/50 group-hover:text-site-base sm:text-3xl xl:text-[40px] font-bold !leading-[1.2] duration-200 transition-all'
                        )}
                      >
                        {item.link.title.split('|').map((t, si) => (
                          <span key={si} className="flex items-center gap-3">
                            {losseContentParse(t)}
                            <br />
                            {si === item.link.title.split('|').length - 1 && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                                fill="none"
                                className="hidden group-hover:block relative top-[2px]"
                              >
                                <g clipPath="url(#clip0_1123_4357)">
                                  <path
                                    d="M5.49999 20.6252C5.50003 20.4445 5.53567 20.2656 5.60487 20.0987C5.67408 19.9318 5.7755 19.7802 5.90332 19.6526L12.9351 12.6208C13.1479 12.408 13.3168 12.1554 13.432 11.8773C13.5472 11.5992 13.6065 11.3012 13.6065 11.0002C13.6065 10.6992 13.5472 10.4011 13.432 10.123C13.3168 9.84495 13.1479 9.5923 12.9351 9.37949L5.91157 2.35599C5.66111 2.09666 5.52251 1.74933 5.52565 1.38881C5.52878 1.02829 5.67339 0.683421 5.92832 0.428485C6.18326 0.173548 6.52812 0.0289412 6.88865 0.0258084C7.24917 0.0226756 7.59649 0.161268 7.85582 0.411735L14.8793 7.43524C15.8236 8.38132 16.354 9.66343 16.354 11.0002C16.354 12.3369 15.8236 13.619 14.8793 14.5651L7.84665 21.5977C7.65431 21.7898 7.40933 21.9206 7.14268 21.9736C6.87603 22.0265 6.59968 21.9992 6.34854 21.8951C6.0974 21.791 5.88276 21.6148 5.73172 21.3888C5.58069 21.1627 5.50005 20.897 5.49999 20.6252Z"
                                    fill="#1D1D1B"
                                  />
                                </g>
                              </svg>
                            )}
                          </span>
                        ))}
                      </div>
                    </div>
                  </LosseLink>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
