import { useSearchParams } from '@ubo/losse-sjedel'

export default function ClearFilters({ sessionName, filters }: { sessionName?: string; filters: string[] }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const defaultFilters = ['Sorteren', 'Pagina']

  if (!searchParams.toString()) return null

  function getFilters() {
    const _filters = defaultFilters.concat(filters)

    return _filters
      .map((filter) => {
        const value = searchParams.get(filter)

        if (!value) return null

        return {
          name: filter,
          value
        }
      })
      .filter(Boolean)
  }

  const _filters = getFilters()

  if (_filters.length === 0) return null

  return (
    <button
      type="button"
      onClick={() => {
        _filters.forEach((filter) => {
          searchParams.delete(filter.name)
        })

        if (!searchParams.toString()) {
          setSearchParams(
            {},
            {
              replace: true,
              state: {
                scroll: false
              }
            }
          )
        } else {
          setSearchParams(searchParams, {
            replace: true,
            state: {
              scroll: false
            }
          })
        }

        if (sessionName) sessionStorage.removeItem(sessionName)
      }}
      className="label--black--small btn--pseudo btn--pseudo--conversion"
    >
      <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="01 align center">
          <path
            id="Vector"
            d="M8.6918 1.19179L7.80804 0.308044L4.49992 3.61617L1.19179 0.308044L0.308044 1.19179L3.61617 4.49992L0.308044 7.80804L1.19179 8.6918L4.49992 5.38367L7.80804 8.6918L8.6918 7.80804L5.38367 4.49992L8.6918 1.19179Z"
            fill="white"
          />
        </g>
      </svg>
      Wis filters
    </button>
  )
}
