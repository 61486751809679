import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useInView } from 'framer-motion'
import { useRef, useState } from 'react'
import { Autoplay, Navigation } from 'swiper/modules'
import Slider, { Slide } from '~/components/elements/Slider'
import { SwiperNavigationArrow } from '~/components/elements/SwiperNavigationArrow'
import type { Page_Flexcontent_Flex_Slider } from '~/graphql/types'

export default function SliderTitle({ fields }: { fields: Page_Flexcontent_Flex_Slider }) {
  const [, setActiveIndex] = useState(1)

  const containerRef = useRef(null)
  const containerIsVisible = useInView(containerRef, { once: true })

  return (
    <section data-component="SliderTitle" className="section" id={fields.sectionId}>
      <div className="container pb-7 sm:pb-10 relative">
        {fields.title && (
          <h2
            className={clsx(
              containerIsVisible && 'animate-fade-up animate-delay-200 animate-ease-in-out',
              containerIsVisible ? 'opacity-100' : 'opacity-0',
              'title--large sm:title--unit lg:title--chonker border-text sm:border-text--thicc text-transparent absolute -bottom-0 sm:-bottom-4 lg:-bottom-10 z-10 left-10 lg:left-20'
            )}
          >
            {fields.title}
          </h2>
        )}

        <div
          ref={containerRef}
          className={clsx(
            containerIsVisible && 'animate-fade-up animate-ease-in-out',
            containerIsVisible ? 'opacity-100' : 'opacity-0',
            'relative'
          )}
        >
          <Slider
            modules={[Autoplay, Navigation]}
            navigation={{
              prevEl: '#customslidertitleprev',
              nextEl: '#customslidertitlenext'
            }}
            speed={1000}
            autoplay={{
              delay: 5000
            }}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 10
              }
            }}
            onSlideChange={(swiper) => {
              setActiveIndex(swiper.activeIndex + 1)
            }}
            className="overflow-hidden"
          >
            <SwiperNavigationArrow
              id="customslidertitleprev"
              className="absolute top-0 bottom-0 my-auto left-0 sm:left-2 lg:left-8"
              direction="prev"
            />
            <SwiperNavigationArrow
              id="customslidertitlenext"
              className="absolute top-0 bottom-0 my-auto right-0 sm:right-2 lg:right-8 rotate-180"
              direction="next"
            />
            {fields?.items?.map((item, i) => (
              <Slide className="!h-auto" key={i}>
                <LossePlaatjie className="object-cover h-full aspect-video" src={item.image} />
              </Slide>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  )
}
