import clsx from 'clsx'

const SIZES = {
  small: 16,
  medium: 24,
  large: 44
}

export default function Stars({
  rating,
  type = 'horizontal',
  size = 'medium',
  className
}: {
  rating: number
  type?: 'horizontal' | 'vertical--to-t' | 'vertical--to-b'
  size?: 'small' | 'medium' | 'large'
  className?: string
}) {
  let fullStars: number[] = Array(Math.floor(rating / 2)).fill(2)

  if (rating % 2 !== 0) fullStars = fullStars.concat([1])
  if (5 - fullStars.length > 0) fullStars = fullStars.concat(Array(5 - fullStars.length).fill(0))

  const _size = SIZES[size]

  return (
    <div
      className={clsx(
        size === 'large' && 'gap-2',
        size !== 'large' && 'gap-1',
        'flex',
        type !== 'horizontal' && 'flex-col',
        type === 'vertical--to-t' && 'flex-col-reverse',
        className
      )}
    >
      {fullStars.map((value, key) => {
        if (value === 2) {
          return <FullStar className={clsx(type !== 'horizontal' && '-rotate-90')} key={key} diameter={_size} />
        }

        if (value === 1) {
          return <HalfStar className={clsx(type !== 'horizontal' && '-rotate-90')} key={key} diameter={_size} />
        }

        return <EmptyStar className={clsx(type !== 'horizontal' && '-rotate-90')} key={key} diameter={_size} />
      })}
    </div>
  )
}

function EmptyStar({ diameter, className }: { diameter: number; className?: string }) {
  return (
    <svg
      className={clsx(className && className)}
      width={diameter}
      height={diameter}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1127 0.983704L27.2798 16.8863L44.0008 16.8863L30.4732 26.7147L35.6403 42.6173L22.1127 32.789L8.58512 42.6173L13.7522 26.7147L0.224609 16.8863L16.9456 16.8863L22.1127 0.983704Z"
        fill="#E0E0E0"
      />
    </svg>
  )
}

function HalfStar({ diameter, className }: { diameter: number; className?: string }) {
  return (
    <svg
      className={clsx(className && className)}
      width={diameter}
      height={diameter}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1127 0.983704L27.2798 16.8863L44.0008 16.8863L30.4732 26.7147L35.6403 42.6173L22.1127 32.789L8.58512 42.6173L13.7522 26.7147L0.224609 16.8863L16.9456 16.8863L22.1127 0.983704Z"
        fill="#E0E0E0"
      />
      <path
        d="M22.1127 0.983704L22.1128 16.8863V23.9982L22.1127 26.7147V30.2749V32.789L8.58512 42.6173L13.7522 26.7147L0.224609 16.8863L16.9456 16.8863L22.1127 0.983704Z"
        fill="#78BF00"
      />
    </svg>
  )
}

function FullStar({ diameter, className }: { diameter: number; className?: string }) {
  return (
    <svg
      className={clsx(className && className)}
      width={diameter}
      height={diameter}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.0223 0.983704L27.1894 16.8863L43.9104 16.8863L30.3828 26.7147L35.5499 42.6173L22.0223 32.789L8.49473 42.6173L13.6618 26.7147L0.134222 16.8863L16.8552 16.8863L22.0223 0.983704Z"
        fill="#78BF00"
      />
    </svg>
  )
}
