import clsx from 'clsx'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import useIsVisible from '~/hooks/useIsVisible'

export default function ContentColumns({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const [titleRef, titleVisible] = useIsVisible()
  const [columnsRef, columnsVisible] = useIsVisible()

  return (
    <section data-component="ContentColumns" className="bg-site-base section--large section--with-bg" id={fields.sectionId}>
      <div className="container">
        <div className="max-w-screen-lg mx-auto">
          <h2
            ref={titleRef}
            className={clsx(
              titleVisible && 'animate-fade-up animate-delay-300 animate-ease-in-out animate-duration-500',
              titleVisible ? 'opacity-100' : 'opacity-0',
              'title--medium !leading-[1.2] sm:text-center text-white'
            )}
          >
            {fields.title}
          </h2>
          {fields?.subtitle && <h3 className="sm:text-center title--light text-site-accent mt-4">{fields.subtitle}</h3>}
          <div ref={columnsRef} className="grid grid-cols-1 sm:grid-cols-2 gap-y-5 sm:gap-y-7 gap-x-20 mt-8 sm:mt-16 max-sm:pl-8">
            {fields.items.map((item, i) => (
              <div
                style={{ animationDelay: `${i * 100 + 300}ms` }}
                className={clsx(
                  columnsVisible && 'animate-fade-up animate-ease-in-out animate-duration-500',
                  columnsVisible ? 'opacity-100' : 'opacity-0',
                  'relative'
                )}
                key={i}
              >
                <div className="bg-site-accent h-1 w-6 absolute -left-8 top-[11px]" />
                <Content className="children-strong:children-p:text-white children-strong:children-p:text-lg" theme="dark">
                  {item.description}
                </Content>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
