import clsx from 'clsx'
import Content from '~/components/elements/Content'
import CrossHair from '~/components/elements/CrossHair'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import useIsVisible from '~/hooks/useIsVisible'

export default function ContentSchedule({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const [titleRef, titleVisible] = useIsVisible()
  const [scheduleRef, scheduleVisible] = useIsVisible()

  return (
    <section data-component="ContentSchedule" className="section section--with-bg relative overflow-hidden bg-site-grey-light">
      <div className="container">
        <div className="max-w-screen-lg mx-auto">
          <h2
            ref={titleRef}
            className={clsx(
              titleVisible && 'animate-fade-up animate-ease-in-out animate-delay-100 animate-duration-500',
              titleVisible ? 'opacity-100' : 'opacity-0',
              'border-text text-transparent title--large mb-3 lg:mb-6'
            )}
          >
            {fields?.title}
          </h2>
          <Content
            className={clsx(
              titleVisible && 'animate-fade-up animate-ease-in-out animate-delay-200 animate-duration-500',
              titleVisible ? 'opacity-100' : 'opacity-0'
            )}
            type="thiccer"
            theme="light"
          >
            {fields?.description}
          </Content>
          <div ref={scheduleRef} className="flex flex-col gap-2 mt-10 sm:mt-16 relative z-0">
            <CrossHair
              className={clsx(
                scheduleVisible && 'animate-fade-up animate-ease-in-out animate-delay-700 animate-duration-1000',
                scheduleVisible ? 'opacity-100' : 'opacity-0',
                'w-[100px] sm:w-[200px] absolute right-[25px] sm:-right-[100px] -top-[50px] sm:-top-[100px] -z-10'
              )}
            />
            {fields?.items?.map((item, i) => (
              <div
                style={{ animationDelay: `${i * 100}ms` }}
                className={clsx(
                  scheduleVisible && 'animate-fade-up animate-ease-in-out animate-duration-500',
                  scheduleVisible ? 'opacity-100' : 'opacity-0',
                  i % 2 === 0 ? 'bg-white' : 'bg-site-grey',
                  'pl-5 py-5 sm:py-7 pr-5 sm:pr-36 grid grid-cols-1 sm:grid-cols-12 gap-2 sm:gap-4'
                )}
                key={i}
              >
                <div className="col-span-1 sm:col-span-4">
                  <div className="flex gap-5 items-start lg:items-center">
                    <div className="bg-site-accent max-lg:mt-3 h-2 w-10" />
                    <span className="font-bold text-2xl">{item?.title}</span>
                  </div>
                </div>
                <div className="col-span-1 sm:col-span-8">
                  <Content>{item?.description}</Content>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
