import clsx from 'clsx'
import { Fragment } from 'react'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Posts, Post, RootQueryToPostConnection } from '~/graphql/types'
import useIsFirstComponent from '~/hooks/useIsFirstComponent'
import useIsVisible from '~/hooks/useIsVisible'
import Case from './post-types/Case'

type Props = Page_Flexcontent_Flex_Posts & {
  subdata: {
    Cases: RootQueryToPostConnection
  }
}

export default function CasesOverview({ fields }: { fields: Props }) {
  const isFirstComponent = useIsFirstComponent(fields)
  const [titleRef, titleIsVisible] = useIsVisible()
  const [expertsRef, expertsIsVisible] = useIsVisible()
  const posts = fields.subdata.Cases?.edges

  return (
    <section
      data-component="CasesOverview"
      className={clsx(isFirstComponent ? 'section--mb md:section' : 'section', 'max-w-screen-2xl mx-auto relative overflow-hidden')}
    >
      <div className="container">
        <div
          className={clsx(
            titleIsVisible && 'animate-fade-up animate-delay-200 animate-ease-in-out animate-duration-500',
            titleIsVisible ? 'opacity-100' : 'opacity-0',
            'pb-3 sm:pb-8 block sm:hidden'
          )}
        >
          <Breadcrumbs />
        </div>
        <div ref={titleRef} className="grid grid-cols-1 gap-8 md:grid-cols-2">
          <div>
            <h1
              className={clsx(
                titleIsVisible && 'animate-fade-up animate-ease-in-out animate-duration-500',
                titleIsVisible ? 'opacity-100' : 'opacity-0',
                'title--large'
              )}
            >
              {fields.title.split('|').map((t, i) => (
                <Fragment key={i}>
                  <span className={clsx(i === 1 && 'pl-8 sm:pl-12 lg:pl-24')}>{t}</span>
                  <br />
                </Fragment>
              ))}
            </h1>
          </div>
          <div className="flex items-center">
            <Content
              className={clsx(
                titleIsVisible && 'animate-fade-up animate-delay-150 animate-ease-in-out animate-duration-500',
                titleIsVisible ? 'opacity-100' : 'opacity-0',
                'children-p:text-base sm:children-p:text-lg children-p:font-bold sm:children-p:!leading-8'
              )}
            >
              {fields.description}
            </Content>
          </div>
        </div>

        <div
          className={clsx(
            titleIsVisible && 'animate-fade-up animate-delay-200 animate-ease-in-out animate-duration-500',
            titleIsVisible ? 'opacity-100' : 'opacity-0',
            'pt-3 sm:pt-8 sm:block hidden'
          )}
        >
          <Breadcrumbs />
        </div>
      </div>

      <div className="container">
        <div className="max-w-screen-xl mx-auto">
          <div
            ref={expertsRef}
            className={clsx(
              expertsIsVisible && 'animate-fade-up animate-delay-500 animate-ease-in-out animate-duration-500',
              expertsIsVisible ? 'opacity-100' : 'opacity-0',
              'grid grid-cols-1 lg:grid-cols-5 pt-8 lg:pt-16 gap-6 lg:gap-10'
            )}
          >
            {posts?.map((edge, index) => {
              const post = edge?.node as Post

              return <Case type={index % 4 === 1 || index % 4 === 2 ? 'small' : 'big'} key={index} fields={post} />
            })}
          </div>
        </div>
      </div>
    </section>
  )
}
