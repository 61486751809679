import { type LoaderData, useLoaderData } from '@ubo/losse-sjedel'
import type {
  Page_Flexcontent_Flex_Banner,
  Page_Flexcontent_Flex_Content,
  Page_Flexcontent_Flex_Form,
  Page_Flexcontent_Flex_Posts,
  Page_Flexcontent_Flex_Slider
} from '~/graphql/types'

export default function useIsLastComponent(
  fields:
    | Page_Flexcontent_Flex_Posts
    | Page_Flexcontent_Flex_Content
    | Page_Flexcontent_Flex_Banner
    | Page_Flexcontent_Flex_Form
    | Page_Flexcontent_Flex_Slider
) {
  const { page } = useLoaderData<LoaderData>()
  let lastComponent = page.flexContent?.flex?.[page.flexContent?.flex?.length - 1]

  if (lastComponent.fieldGroupName.includes('_Fragment')) {
    // @ts-ignore
    lastComponent = lastComponent?.element?.flexContent?.flex?.[lastComponent?.element?.flexContent?.flex?.length - 1]
  }

  return lastComponent?.styletype === fields?.styletype
}
