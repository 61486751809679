import { LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentImage({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentImage" className="section" id={fields.sectionId}>
      <div className="container">
        <div className="grid md:py-20 grid-cols-1 md:grid-cols-3 gap-10 lg:gap-20">
          <div className="col-span-1 md:col-span-2">
            <h2 className="title--medium mb-3 text-site-accent">{fields.title}</h2>
            <Content type="slighty-thicc">{fields.description}</Content>
          </div>
          <div className="flex items-center justify-center max-md:px-8">
            <LossePlaatjie src={fields.image} maxwidth={500} />
          </div>
        </div>
      </div>
    </section>
  )
}
