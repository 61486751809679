import { type LoaderData, useLoaderData } from '@ubo/losse-sjedel'
import type {
  Page_Flexcontent_Flex_Banner,
  Page_Flexcontent_Flex_Content,
  Page_Flexcontent_Flex_Form,
  Page_Flexcontent_Flex_Posts,
  Page_Flexcontent_Flex_Slider
} from '~/graphql/types'

export default function useIsFirstComponent(
  fields:
    | Page_Flexcontent_Flex_Posts
    | Page_Flexcontent_Flex_Content
    | Page_Flexcontent_Flex_Banner
    | Page_Flexcontent_Flex_Form
    | Page_Flexcontent_Flex_Slider
) {
  const { page } = useLoaderData<LoaderData>()
  const firstComponent = page.flexContent?.flex?.[0]
  return firstComponent?.styletype === fields?.styletype
}
