import Content from '~/components/elements/Content'
import Links from '~/components/elements/Links'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentBare({ fields, index }: { fields: Page_Flexcontent_Flex_Content; index: number }) {
  return (
    <section data-component="ContentBare" className="section" id={fields.sectionId}>
      <div className="container">
        <div className="max-w-screen-md">
          <div className="flex flex-col gap-y-6">
            {fields.title && <h2 className="title--normal">{fields.title}</h2>}
            {fields.description && (
              <Content data-id={`content-${index}`} className="content list--stripe--accent">
                {fields?.description}
              </Content>
            )}
            {fields.links && <Links items={fields.links} />}
          </div>
        </div>
      </div>
    </section>
  )
}
