import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useInView } from 'framer-motion'
import { useRef } from 'react'
import Content from '~/components/elements/Content'
import CrossHair from '~/components/elements/CrossHair'
import Links from '~/components/elements/Links'
import PageDownArrow from '~/components/elements/PageDownArrow'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'

export default function BannerFocusImage({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const contentRef = useRef(null)

  const imageVisible = useInView(contentRef, { once: true })
  const contentVisible = useInView(contentRef, { once: true })

  return (
    <section
      data-component="BannerFocusImage"
      className="bg-site-base relative md:min-h-[800px] overflow-hidden md:h-screen-dynamic header--margin--top--negative"
      ref={contentRef}
    >
      <div className="max-w-[2000px] mx-auto h-full relative">
        <PageDownArrow className="w-[27px] absolute left-0 right-0 mx-auto bottom-8 z-30 animate-bounce animate-ease-in-out" />
        <LossePlaatjie className="!h-full w-full absolute object-cover" src={fields.image} maxwidth={2000} />
        <div className="absolute h-full w-full bg-site-base mix-blend-multiply opacity-80 top-0 left-0" />
        <div className="h-full pt-[0px] container relative z-20">
          <div
            className={clsx(
              fields.crosshairinsteadoffocus ? 'max-md:pt-[100px] max-md:pb-[40px]' : 'max-md:pt-[400px] max-md:pb-[40px]',
              'grid grid-cols-3 h-full gap-12'
            )}
          >
            <div className="col-span-3 md:col-span-2 relative z-20 flex flex-col sm:justify-center">
              <h2
                className={clsx(
                  contentVisible && 'animate-fade-up animate-ease-in-out animate-duration-500',
                  contentVisible ? 'opacity-100' : 'opacity-0',
                  'title--small xl:title--normal mb-3 md:mb-6 text-site-accent !font-light'
                )}
              >
                {fields.subtitle}
              </h2>
              <h1
                className={clsx(
                  contentVisible && 'animate-fade-up animate-ease-in-out animate-delay-75 animate-duration-500',
                  contentVisible ? 'opacity-100' : 'opacity-0',
                  'title--semi-large sm:title--large xl:title--semi-unit mb-10 text-white'
                )}
              >
                {fields.title}
              </h1>
              <Links
                theme="dark"
                className={clsx(
                  contentVisible && 'animate-fade-up animate-ease-in-out animate-delay animate-duration-1000',
                  contentVisible ? 'opacity-100' : 'opacity-0',
                  'pb-8 flex-wrap gap-y-4 md:hidden'
                )}
                items={fields.links}
              />
              <div
                className={clsx(
                  contentVisible && 'animate-fade-up animate-ease-in-out animate-delay-100 animate-duration-500',
                  contentVisible ? 'opacity-100' : 'opacity-0',
                  'max-w-screen-sm'
                )}
              >
                <Content theme="dark" className="children-p:font-light">
                  {fields.description}
                </Content>
              </div>
              <Links
                theme="dark"
                className={clsx(
                  contentVisible && 'animate-fade-up animate-ease-in-out animate-delay-150 animate-duration-1000',
                  contentVisible ? 'opacity-100' : 'opacity-0',
                  'spacing--small--top flex-wrap gap-y-4 max-md:hidden'
                )}
                items={fields.links}
              />
            </div>
            <div className="col-span-3 md:col-span-1 flex justify-end items-center">
              {fields.crosshairinsteadoffocus ? (
                <CrossHair
                  className={clsx(
                    imageVisible && 'animate-fade--light md:animate-fade-up animate-ease-in-out animate-delay-500 animate-duration-1000',
                    imageVisible ? 'opacity-100' : 'opacity-0',
                    'max-md:absolute max-md:top-0 max-md:right-0 w-[300px] sm:w-[400px]'
                  )}
                />
              ) : (
                <div>
                  <LossePlaatjie
                    maxwidth={500}
                    className={clsx(
                      contentVisible && 'animate-fade-up animate-ease-in-out animate-delay-500 animate-duration-1000',
                      contentVisible ? 'opacity-100 max-md:top-[130px] md:-bottom-[50px]' : 'opacity-0 -bottom-0',
                      'w-[220px] sm:w-[300px] lg:w-[350px] h-auto max-md:left-0 max-md:mx-auto right-0 xl:right-20 absolute '
                    )}
                    src={fields.focusimage}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
