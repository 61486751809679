import clsx from 'clsx'

export default function Logo({ className }: { className?: string }) {
  return (
    <svg
      className={clsx(className && className)}
      width="845"
      height="178"
      viewBox="0 0 845 178"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_401_111)">
        <path
          d="M96.2599 177.32H74.1399V76.12H134.13C139.33 76.12 144.06 76.94 148.3 78.58C152.54 80.22 156.15 82.51 159.14 85.45C162.13 88.39 164.44 91.93 166.08 96.08C167.72 100.23 168.54 104.85 168.54 109.96C168.54 115.36 167.43 120.03 165.21 123.98C162.99 127.93 160.08 131.19 156.46 133.74C152.85 136.3 148.73 138.2 144.1 139.45C139.47 140.7 134.75 141.33 129.93 141.33H96.2499V177.33L96.2599 177.32ZM96.2599 121.66H129.94C134.86 121.66 138.61 120.75 141.22 118.91C143.82 117.08 145.12 114.09 145.12 109.95C145.12 104.75 143.7 101.08 140.86 98.96C138.02 96.84 134.14 95.78 129.22 95.78H96.2599V121.66Z"
          fill="currentColor"
        />
        <path
          d="M206.85 177.32H184.73V76.12H238.36C242.7 76.12 247.13 76.53 251.66 77.35C256.19 78.17 260.33 79.59 264.09 81.61C267.85 83.63 270.91 86.31 273.27 89.63C275.63 92.96 276.81 97.17 276.81 102.28C276.81 105.56 275.94 108.45 274.21 110.95C272.48 113.46 270.28 115.58 267.63 117.31C264.98 119.04 262.06 120.35 258.88 121.21C255.7 122.08 252.71 122.51 249.92 122.51C264.77 122.51 276.81 134.55 276.81 149.4V177.3H253.1V151.9C253.1 146.37 248.61 141.88 243.08 141.88H206.84V177.3L206.85 177.32ZM206.85 95.78V122.52H238.22C242.56 122.52 246.19 121.41 249.13 119.19C252.07 116.97 253.54 113.55 253.54 108.93C253.54 104.31 252.12 100.71 249.28 98.74C246.44 96.77 242.8 95.78 238.37 95.78H206.85Z"
          fill="currentColor"
        />
        <path
          d="M401.58 126.72C401.58 143.2 396.9 155.75 387.56 164.38C378.21 173.01 364.77 177.32 347.23 177.32C329.69 177.32 316.03 173.01 306.82 164.38C297.62 155.76 293.01 143.2 293.01 126.72C293.01 110.24 297.73 97.45 307.18 88.92C316.62 80.39 330.02 76.13 347.37 76.13C364.72 76.13 378.09 80.3 387.49 88.63C396.89 96.97 401.59 109.66 401.59 126.72H401.58ZM379.03 126.43C379.03 121.9 378.33 117.83 376.93 114.21C375.53 110.6 373.48 107.49 370.79 104.89C368.09 102.29 364.79 100.29 360.89 98.89C356.99 97.49 352.53 96.79 347.52 96.79C337.3 96.79 329.42 99.49 323.88 104.89C318.34 110.29 315.57 117.56 315.57 126.72C315.57 135.88 318.34 143.63 323.88 148.84C329.42 154.04 337.3 156.65 347.52 156.65C358.31 156.65 366.26 153.9 371.37 148.41C376.48 142.92 379.03 135.59 379.03 126.44V126.43Z"
          fill="currentColor"
        />
        <path d="M501.18 76.12V96.65H439.88V117.9H501.18V137.85H439.88V177.32H417.76V76.12H501.17H501.18Z" fill="currentColor" />
        <path d="M518.1 76.12H540.22V177.32H518.1V76.12Z" fill="currentColor" />
        <path d="M640.4 76.12V96.65H579.25V116.46H640.4V135.83H579.25V156.79H640.4V177.32H557.13V76.12H640.4Z" fill="currentColor" />
        <path d="M678.71 76.12V156.79H734.51V177.32H656.59V76.12H678.71Z" fill="currentColor" />
        <path
          d="M795.37 177.32H750.7V76.12H794.07C801.78 76.12 808.72 77.4 814.89 79.95C821.06 82.51 826.33 86.02 830.72 90.5C835.1 94.98 838.48 100.33 840.84 106.55C843.2 112.77 844.38 119.59 844.38 127.01C844.38 134.43 843.2 141.23 840.84 147.39C838.48 153.56 835.15 158.86 830.87 163.29C826.58 167.72 821.42 171.17 815.4 173.63C809.38 176.09 802.7 177.32 795.38 177.32H795.37ZM772.82 96.65V156.79H793.2C797.92 156.79 802.02 156.04 805.49 154.55C808.96 153.06 811.85 151.01 814.16 148.41C816.47 145.81 818.18 142.68 819.29 139.01C820.4 135.35 820.95 131.35 820.95 127.01C820.95 117.57 818.44 110.15 813.43 104.75C808.42 99.35 801.24 96.65 791.89 96.65H772.81H772.82Z"
          fill="currentColor"
        />
        <path d="M62.9601 0H40.6201V33.3H62.9601V0Z" fill="#78BF00" />
        <path d="M62.9601 70.28H40.6201V103.58H62.9601V70.28Z" fill="#78BF00" />
        <path d="M103.58 40.62H70.28V62.96H103.58V40.62Z" fill="#78BF00" />
        <path d="M33.3 40.62H0V62.96H33.3V40.62Z" fill="#78BF00" />
      </g>
    </svg>
  )
}
