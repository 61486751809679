import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import type { Expert } from '~/graphql/types'

export default function Expert({
  expert,
  type = 'normal',
  clickable = true
}: {
  expert: Expert
  type?: 'small' | 'normal'
  clickable?: boolean
}) {
  return (
    <LosseLink
      to={clickable ? expert.uri : undefined}
      className={clsx(
        type === 'normal' && 'h-[280px] xs:h-[340px]',
        type === 'small' && 'h-[250px] xs:h-[340px] sm:h-[260px]',
        'bg-site-base relative group'
      )}
    >
      {clickable && (
        <div className="group-hover:opacity-20 bg-site-accent opacity-0 transition-all duration-200 absolute h-full w-full left-0 top-0" />
      )}
      <LossePlaatjie loading="eager" className="h-full w-full object-cover" src={expert.featuredImage?.node} />
    </LosseLink>
  )
}
