import { useSearchParams } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useState } from 'react'
import { useLocalStorage } from 'usehooks-ts'
import { type SearchBucket, type SearchBucketAggregation } from '~/services/elastic.server'
import { motion } from 'framer-motion'
import useIsMobile from '~/hooks/useIsMobile'

const MAX_ITEMS = 6

export default function Checkboxlist({
  field,
  aggregation,
  className
}: {
  field: string
  aggregation: SearchBucketAggregation
  className?: string
}) {
  const [searchParams] = useSearchParams()
  const [showMore, setShowMore] = useLocalStorage(`show-more-${field}`, false)
  const isMobile = useIsMobile()
  const [_isOpen, setOpen] = useState(!isMobile)
  const isOpen = isMobile ? _isOpen : true
  const selected = searchParams.get(field)?.split(',') || []
  const selectedBuckets = aggregation?.buckets.filter((bucket) => selected.includes(bucket.key))

  return (
    <div className={clsx('mb-4 lg:mb-10 animate-fade-up', className)}>
      <h3
        role={isMobile ? 'button' : undefined}
        className="max-lg:text-2xl title--tiny opacity-40 mb-2 flex items-center justify-between"
        onClick={() => {
          if (isMobile) setOpen(!isOpen)
        }}
      >
        {field}
        <span className={clsx('lg:hidden transition-all', isOpen && 'rotate-90')}>
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
            <g clipPath="url(#clip0_956_10641)">
              <path
                d="M3.74999 14.0626C3.75001 13.9394 3.77431 13.8175 3.8215 13.7037C3.86869 13.5899 3.93783 13.4865 4.02499 13.3995L8.81936 8.60512C8.96449 8.46002 9.07962 8.28776 9.15817 8.09816C9.23672 7.90856 9.27715 7.70535 9.27715 7.50012C9.27715 7.2949 9.23672 7.09168 9.15817 6.90208C9.07962 6.71249 8.96449 6.54022 8.81936 6.39512L4.03061 1.60637C3.85984 1.42956 3.76534 1.19274 3.76748 0.946932C3.76962 0.701122 3.86821 0.465985 4.04203 0.292165C4.21585 0.118345 4.45099 0.0197493 4.6968 0.0176133C4.94261 0.0154773 5.17942 0.109972 5.35624 0.280745L10.145 5.0695C10.7888 5.71455 11.1505 6.58872 11.1505 7.50012C11.1505 8.41152 10.7888 9.28569 10.145 9.93075L5.34998 14.7257C5.21884 14.8567 5.05181 14.9459 4.87 14.982C4.6882 15.0181 4.49977 14.9995 4.32854 14.9285C4.15731 14.8575 4.01096 14.7374 3.90799 14.5833C3.80501 14.4292 3.75003 14.248 3.74999 14.0626Z"
                fill="currentColor"
              />
            </g>
          </svg>
        </span>
      </h3>
      <motion.div
        initial={{ height: 0 }}
        animate={{ height: isOpen ? 'auto' : 0 }}
        transition={{ duration: 0.3, ease: 'easeInOut' }}
        className="overflow-hidden"
      >
        <div className="max-lg:pb-4">
          {selectedBuckets.length > 0 && (
            <>
              <ul className="flex flex-col gap-3 max-lg:mt-2 mb-3">
                {selectedBuckets.map((bucket) => (
                  <Option bucket={bucket} field={field} key={bucket.key} />
                ))}
              </ul>
            </>
          )}
          <ul className="flex flex-col gap-3 max-lg:mt-2">
            {aggregation?.buckets
              .filter((bucket) => !selected.includes(bucket.key))
              .sort((a, b) => b.doc_count - a.doc_count)
              .filter((_, index) => showMore || index < MAX_ITEMS || isMobile)
              .map((bucket) => (
                <Option bucket={bucket} field={field} key={bucket.key} />
              ))}
          </ul>
          {aggregation?.buckets.length > MAX_ITEMS && (
            <button
              type="button"
              onClick={() => {
                setShowMore(!showMore)
              }}
              className="mt-4 text-sm max-lg:hidden font-bold flex items-center gap-1 btn--psueod--link btn--base--link"
            >
              {showMore ? 'Toon minder' : 'Toon meer'}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                className={clsx(showMore && 'rotate-180', !showMore && 'top-[2px]', 'w-5 h-5 relative transition-all')}
              >
                <path
                  d="M14.812 6.49955C14.7384 6.42535 14.6509 6.36646 14.5544 6.32627C14.4579 6.28607 14.3544 6.26538 14.2499 6.26538C14.1454 6.26538 14.042 6.28607 13.9455 6.32627C13.849 6.36646 13.7614 6.42535 13.6879 6.49955L10.062 10.1254C9.98842 10.1996 9.90086 10.2585 9.80439 10.2987C9.70792 10.3389 9.60444 10.3596 9.49994 10.3596C9.39543 10.3596 9.29195 10.3389 9.19548 10.2987C9.09901 10.2585 9.01145 10.1996 8.93785 10.1254L5.31202 6.49955C5.23842 6.42535 5.15086 6.36646 5.05439 6.32627C4.95792 6.28607 4.85445 6.26538 4.74994 6.26538C4.64543 6.26538 4.54195 6.28607 4.44548 6.32627C4.34901 6.36646 4.26145 6.42535 4.18785 6.49955C4.0404 6.64788 3.95764 6.84853 3.95764 7.05768C3.95764 7.26682 4.0404 7.46747 4.18785 7.6158L7.8216 11.2495C8.26692 11.6943 8.87056 11.9441 9.49994 11.9441C10.1293 11.9441 10.733 11.6943 11.1783 11.2495L14.812 7.6158C14.9595 7.46747 15.0422 7.26682 15.0422 7.05768C15.0422 6.84853 14.9595 6.64788 14.812 6.49955Z"
                  fill="#1D1D1B"
                />
              </svg>
            </button>
          )}
        </div>
      </motion.div>
    </div>
  )
}

function Option({ bucket, field }: { bucket: SearchBucket; field: string }) {
  const [searchParams, setSearchParams] = useSearchParams()

  return (
    <li key={bucket.key}>
      <label
        htmlFor={bucket.key}
        className="text-sm flex items-center gap-3 hover:cursor-pointer group hover:bg-site-grey-light/50 rounded-md"
        title={`${bucket.key} (${bucket.doc_count})`}
      >
        <input
          id={bucket.key}
          type="checkbox"
          onChange={() => {
            const currentParams = searchParams.get(field)?.split(',')

            if (currentParams?.includes(bucket.key)) {
              searchParams.set(field, currentParams.filter((param) => param !== bucket.key).join(','))
              if (searchParams.get(field) === '') searchParams.delete(field)
            } else {
              currentParams?.push(bucket.key)
              searchParams.set(field, currentParams ? currentParams.join(',') : bucket.key)
            }

            setSearchParams(searchParams, {
              replace: true,
              state: {
                scroll: false
              }
            })
          }}
          className="hidden peer"
          defaultChecked={searchParams.get(field)?.split(',').includes(bucket.key)}
        />
        <div className="w-5 h-5 rounded-[4px] peer-checked:bg-site-accent [&_>_svg]:hidden peer-checked:[&_>_svg]:block bg-site-grey flex--center">
          <svg xmlns="http://www.w3.org/2000/svg" width="81" height="79" viewBox="0 0 81 79" fill="none" className="w-4 h-4 text-white">
            <path d="M49.125 0H31.875V25.2361H49.125V0Z" fill="currentColor" />
            <path d="M49.125 53.7637H31.875V78.9998H49.125V53.7637Z" fill="currentColor" />
            <path d="M81 31.0879H55.125V47.912H81V31.0879Z" fill="currentColor" />
            <path d="M25.875 31.0879H0V47.912H25.875V31.0879Z" fill="currentColor" />
          </svg>
        </div>
        <span className="group-hover:underline line-clamp-1">
          {bucket.key} ({bucket.doc_count})
        </span>
      </label>
    </li>
  )
}
