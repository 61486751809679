import { useEffect, useState } from 'react'

export type RichJobPosting = {
  job: {
    id: number | string
    title: string
    description: string
    type: 'FULL_TIME' | 'PART_TIME' | 'INTERN' | 'OTHER'
    datePosted: string
  }
  organization?: {
    name: string
    url: string
  }
  location?: {
    address: string
    zipcode: string
    countryCode: string
    addressLocality?: string
    addressRegion?: string
  }
  salary?: {
    currency: 'USD' | 'EUR' | 'GBP'
    value?: number
    minValue?: number
    maxValue?: number
    time: 'HOUR' | 'DAY' | 'WEEK' | 'MONTH' | 'YEAR'
  }
}

const useRichJobPosting = ({ job, organization, location, salary }: RichJobPosting) => {
  const [isLoaded, setLoaded] = useState(false)

  useEffect(() => {
    if (!isLoaded) {
      setLoaded(true)
      return
    }

    const script = `
      <script type="application/ld+json" id="losse-schema-job-posting">
        {
          "@context" : "https://schema.org/",
          "@type" : "JobPosting",
          "title" : "${job.title}",
          "description" : "${job.description}",
          "identifier": {
            "@type": "PropertyValue",
            "name": "Google",
            "value": "${job.id}"
          },
          "datePosted" : "${job.datePosted}",
          ${job.type ? `"employmentType" : "${job.type}",` : ''}
          ${
            organization
              ? `"hiringOrganization" : {
            "@type" : "Organization",
            "name" : "${organization.name}",
            "sameAs" : "${organization.url}"
          },`
              : ''
          }
          ${
            location
              ? `"jobLocation": {
            "@type": "Place",
              "address": {
              "@type": "PostalAddress",
              "streetAddress": "${location.address}",
              "postalCode": "${location.zipcode}",
              "addressCountry": "${location.countryCode}"${
                  location.addressLocality
                    ? `,
              "addressLocality": "${location.addressLocality}"`
                    : ''
                }${
                  location.addressRegion
                    ? `,
              "addressRegion": "${location.addressRegion}"`
                    : ''
                }
            }
          },`
              : ''
          }
          ${
            salary
              ? `"baseSalary": {
            "@type": "MonetaryAmount",
            "currency": "${salary.currency}",
            "value": {
              "@type": "QuantitativeValue",
              ${salary.value ? `"value": ${salary.value},` : ''}
              ${salary.minValue ? `"minValue": ${salary.minValue},` : ''}
              ${salary.maxValue ? `"maxValue": ${salary.maxValue},` : ''}
              "unitText": "${salary.time}"
            }
          }`
              : ''
          }
        }
      </script>
    `

    document.head.insertAdjacentHTML('beforeend', script)

    return () => {
      const script = document.getElementById('losse-schema-job-posting')
      if (script) script.remove()
    }
  }, [isLoaded])
}

export default useRichJobPosting
