import Links from '~/components/elements/Links'
import CrossHair from '~/components/elements/CrossHair'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import { Fragment } from 'react'
import useIsVisible from '~/hooks/useIsVisible'
import clsx from 'clsx'

export default function BannerProof({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const [ref, isVisible] = useIsVisible()

  return (
    <section
      data-component="BannerProof"
      className="max-md:-z-20 section section--with-bg relative bg-site-base pb-1 lg:pt-1 pt-12  header--margin--top--negative text-white"
      ref={ref}
    >
      <div className="container relative md:grid md:grid-cols-[40%_60%] max-lg:pt-20">
        <div className="md:container max-md:opacity-30 max-md:-z-10 max-md:absolute max-md:w-[60%] md:w-[80%] md:mt-28 lg:mt-32 max-md:left-0">
          <CrossHair
            className={clsx(
              isVisible && 'animate-fade animate-ease-in-out animate-delay-500',
              isVisible ? 'opacity-100' : 'opacity-0',
              'w-full'
            )}
          />
        </div>
        <div className="max-md:-z-0">
          <div className="title--normal lg:title--medium lg:leading-[55px] max-md:mt-16 max-md:py-7 md:36 lg:mt-40 xl:mt-48">
            {fields?.subtitle?.split(' | ')?.map((row, key) => (
              <Fragment key={key}>
                {key > 0 && <br />}
                <span className="leading-[1]">{row}</span>
              </Fragment>
            ))}
          </div>
          <div className="title--large text-transparent border-text--white mt-4">{fields?.title}</div>
          <div className="max-md:mt-16 lg:mt-20">
            <Links items={fields?.links} className="spacing--normal--top pb-9 lg:pb-6" />
          </div>
        </div>
      </div>
    </section>
  )
}
