import clsx from 'clsx'
import { useInView } from 'framer-motion'
import { useRef } from 'react'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentFormula({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const titleRef = useRef(null)

  const titleVisible = useInView(titleRef, { once: true })

  return (
    <section data-component="ContentFormula" className="bg-site-base py-20 sm:py-32 relative overflow-hidden" id={fields.sectionId}>
      <div ref={titleRef} className="container">
        <Content
          className={clsx(
            titleVisible && 'animate-fade-up animate-ease-in-out animate-delay-300 animate-duration-500',
            titleVisible ? 'opacity-100' : 'opacity-0',
            'text-xl xs:title--medium children-strong:children-p:border-text--white children-strong:children-p:text-transparent children-p:!leading-[1.2] children-p:font-bold children-p:text-white'
          )}
        >
          {fields.description}
        </Content>
      </div>
    </section>
  )
}
