import clsx from 'clsx'
import { Fragment, useState } from 'react'
import Content from '~/components/elements/Content'
import CrossHair from '~/components/elements/CrossHair'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import useIsVisible from '~/hooks/useIsVisible'
import { motion } from 'framer-motion'

export default function ContentCollapsable({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const [contentRef, contentVisible] = useIsVisible()
  const [indexOpen, setIndexOpen] = useState(0)

  return (
    <section data-component="ContentCollapsable" className="section--large section--with-bg bg-site-base" id={fields.sectionId}>
      <div ref={contentRef} className="container">
        <h2
          className={clsx(
            contentVisible && 'animate-fade-up animate-ease-in-out animate-duration-500',
            contentVisible ? 'opacity-100' : 'opacity-0',
            'text-transparent border-text--white title--large pb-4 sm:pb-6'
          )}
        >
          {fields.title}
        </h2>
        <h3
          className={clsx(
            contentVisible && 'animate-fade-up animate-ease-in-out animate-delay-150 animate-duration-500',
            contentVisible ? 'opacity-100' : 'opacity-0',
            'text-site-accent title--small'
          )}
        >
          {fields.subtitle}
        </h3>
        <div
          className={clsx(
            contentVisible && 'animate-fade-up animate-ease-in-out animate-delay-300 animate-duration-500',
            contentVisible ? 'opacity-100' : 'opacity-0',
            'mt-8 sm:mt-16 lg:mt-24 max-w-screen-md mx-auto flex flex-col gap-5 sm:gap-9'
          )}
        >
          {fields.items.map((item, i) => (
            <Fragment key={i}>
              {i !== 0 && <hr />}
              <CollapsableItem fields={item} index={i} indexOpen={indexOpen} setIndexOpen={setIndexOpen} />
            </Fragment>
          ))}
        </div>
      </div>
    </section>
  )
}

function CollapsableItem({
  fields,
  index,
  indexOpen,
  setIndexOpen
}: {
  fields: Page_Flexcontent_Flex_Content['items'][0]
  index: number
  indexOpen: number
  setIndexOpen: (index: number) => void
}) {
  const isOpen = index === indexOpen

  return (
    <div className="flex sm:gap-9">
      <div className="cursor-pointer" onClick={() => setIndexOpen(index)}>
        <CrossHair
          className={clsx(
            isOpen
              ? 'children-path:fill-site-accent'
              : 'children-path:fill-transparent children-path:stroke-white children-path:stroke-[10px]',
            'w-[50px] sm:block hidden children-path:transition-all children-path:duration-200'
          )}
        />
      </div>
      <div className="sm:pt-2">
        <div className="flex gap-5 items-center">
          <CrossHair
            className={clsx(
              isOpen
                ? 'children-path:fill-site-accent'
                : 'children-path:fill-transparent children-path:stroke-white children-path:stroke-[10px]',
              'w-[50px] block sm:hidden children-path:transition-all children-path:duration-200'
            )}
          />
          <h4 onClick={() => setIndexOpen(index)} className="text-white cursor-pointer title--normal">
            {fields.title}
          </h4>
        </div>

        <motion.div
          animate={isOpen ? 'open' : 'closed'}
          variants={{
            open: { height: 'auto', opacity: 1 },
            closed: { height: 0, opacity: 0 }
          }}
          className="overflow-hidden"
        >
          <Content className="animate-fade-down animate-ease-in-out mt-3 sm:mt-6 animate-duration-500" theme="dark">
            {fields.description}
          </Content>
        </motion.div>
      </div>
    </div>
  )
}
