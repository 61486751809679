/* eslint-disable react-hooks/exhaustive-deps */
import type { LoaderData } from '@ubo/losse-sjedel'
import { useLoaderData } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { motion, useMotionValue } from 'framer-motion'
import useIsMobile from '~/hooks/useIsMobile'

type Section = {
  title: string
  href: string
}

const SECTIONS: { [key: string]: Section[] } = {
  vacature: [
    {
      title: 'Samenvatting',
      href: '#samenvatting'
    },
    {
      title: 'Organisatie',
      href: '#organisatie'
    },
    {
      title: 'Functie',
      href: '#functie'
    },
    {
      title: 'Aanbod',
      href: '#aanbod'
    },
    {
      title: 'Functie-eisen',
      href: '#functie-eisen'
    },
    {
      title: 'Sollicitatie procedure',
      href: '#sollicitatie-procedure'
    }
  ],
  interneVacature: [
    {
      title: 'Functie',
      href: '#functie'
    },
    {
      title: 'Inhoudelijk',
      href: '#inhoudelijk'
    },
    {
      title: 'Expert',
      href: '#expert'
    },
    {
      title: 'Wat ben je waard',
      href: '#waard'
    },
    {
      title: 'Over Profield',
      href: '#over-profield'
    }
  ]
}

export default function HeaderVacancy() {
  const data = useLoaderData<LoaderData>()
  const contentType = data?.page?.contentType?.node?.name
  const [activeSection, setActiveSection] = useState<number>(null)
  const opacity = useMotionValue(0)
  const [showHeader, setShowHeader] = useState(false)
  const mobile = useIsMobile('(max-width: 640px)')

  const sections = SECTIONS[contentType] || []

  useEffect(() => {
    if (!sections.length) return

    const handleScroll = () => {
      const sectionElements = sections
        .map((section) => {
          if (!section) return null
          const element = document.querySelector<HTMLDivElement>(section.href)
          return element
        })
        // we reverse the array because we want to find the last section that is active
        .reverse()

      const header = document.querySelector<HTMLDivElement>('[data-component="HeaderVacancy"]')
      if (!header) return
      const headerHeight = header.offsetHeight
      const scrollTop = window.scrollY

      const foundActiveSection = sectionElements.findIndex((section) => {
        if (!section) return false
        const sectionScrollX = section.offsetTop - headerHeight
        // we dont want to trigger the active perfectly, we just want to trigger it when its around the middle of the screen
        const activationOffset = 100

        if (scrollTop >= sectionScrollX - activationOffset) {
          return true
        }

        return false
      })

      // we animate the header a bit before the first section is active
      if (foundActiveSection !== null) {
        const firstSection = document.querySelector<HTMLDivElement>(sections[0].href)
        if (!firstSection) return

        const startAnimationAtScrollY = firstSection.offsetTop - 400
        const endAnimationAtScrollY = firstSection.offsetTop - 200

        // interpolate from 0 to 1, using the scrollTop
        // never be lower than 0
        const opac = Math.max(0, (scrollTop - startAnimationAtScrollY) / (endAnimationAtScrollY - startAnimationAtScrollY))
        opacity.set(opac)
        setShowHeader(opac > 0)
      }

      // map reversed array index back to original
      setActiveSection(foundActiveSection === -1 ? null : sections.length - foundActiveSection - 1)
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  if (!sections.length) return null

  return (
    <motion.div
      data-component="HeaderVacancy"
      className={clsx(
        'fixed left-0 top-0 w-full bg-white text-site-base z-30 py-5 lg:border-none border-b-2 border-b-site-conversion block--shadow'
      )}
      style={{ opacity, display: showHeader && !mobile ? 'block' : 'none' }}
    >
      <div className="container flex lg:flex-row flex-col w-full gap-10">
        <div className="flex flex-col justify-between lg:w-[80%]">
          <span className="font-bold xl:text-2xl text-xl">{data.page.title}</span>

          <div className="items-center gap-x-8 mt-4 xl:text-lg text-base lg:flex hidden">
            {sections.map((section, index) => {
              const isActive = activeSection === index

              return (
                <a
                  key={index}
                  href={section.href}
                  onClick={(e) => {
                    if (mobile) return
                    e.preventDefault()
                    const element = document.querySelector<HTMLDivElement>(section.href)
                    if (!element) return
                    const header = document.querySelector<HTMLDivElement>('[data-component="HeaderVacancy"]')
                    if (!header) return
                    const headerHeight = header.offsetHeight
                    const sectionScrollX = element.offsetTop - headerHeight
                    window.scrollTo({ top: sectionScrollX, behavior: 'smooth' })
                  }}
                  className={clsx('hover:text-site-accent transition-colors', isActive ? 'text-site-accent' : 'text-site-grey-other')}
                >
                  {section.title}

                  <div className="relative h-1 w-full">
                    <motion.div
                      animate={{ width: isActive ? '100%' : '0' }}
                      className="h-1 bg-site-accent rounded-full absolute top-0 left-0"
                    />
                  </div>
                </a>
              )
            })}
          </div>
        </div>

        <div className="lg:w-[20%] flex lg:flex-col justify-center flex-row gap-4">
          <a href="#solliciteren" className="btn--conversion">
            Solliciteer direct
          </a>
        </div>
      </div>
    </motion.div>
  )
}
