import { LosseLink, useLoaderData } from '@ubo/losse-sjedel'
import CrossHair from '~/components/elements/CrossHair'
import type { Page_Flexcontent_Flex_Posts } from '~/graphql/types'
import useGlobalCounters from '~/hooks/useGlobalCounters'
import { type PageBodyData } from '~/templates/page'
import Vacancy from './post-types/Vacancy'
import useIsVisible from '~/hooks/useIsVisible'
import clsx from 'clsx'
import Links from '~/components/elements/Links'

export default function PostsVacanciesRelated({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const globalCounter = useGlobalCounters()
  const { middleware } = useLoaderData<PageBodyData>()
  const [ref, isVisible] = useIsVisible()

  if (middleware?.relatedVacancies?.status === 'failed' || middleware?.relatedVacancies?.data?.hits?.total === 0) return null

  return (
    <section data-component="PostsVacanciesRelated" className="section bg-site-base" ref={ref}>
      <div className="container section--large section--with-bg">
        <div className="pl-6 sm:pl-10 relative">
          <CrossHair className="absolute -left-6 -top-10 sm:-top-14 h-16 sm:h-20" />
          <h2 className="title--semi-large border-text--white text-transparent mb-6">{fields?.title}</h2>
          <LosseLink to="/vacatures/" className="rounded-full px-4 py-2 -ml-4 btn--pseudo--conversion text-white">
            Naar alle<span className="font-bold text-site-accent"> vacatures ({globalCounter.vacancies})</span>
          </LosseLink>
        </div>
        <div className="flex sm:grid grid-cols-2 xl:grid-cols-4 gap-4 mt-10 max-sm:overflow-x-auto max-sm:px-6 max-sm:-mx-6 max-sm:pb-4 no-scrollbar">
          {middleware?.relatedVacancies?.data?.hits?.hits?.map((vacancy, index) => (
            <Vacancy
              key={vacancy?._id}
              source={vacancy?._source}
              theme="dark"
              className={clsx('max-sm:min-w-[65%] max-xs:min-w-[85%]', isVisible ? 'animate-fade-up opacity-100' : 'opacity-0')}
              style={{ animationDelay: `${index * 100 + 250}ms` }}
            />
          ))}
        </div>
        {fields.links && (
          <div className="mt-8 sm:mt-16 flex justify-center">
            <Links items={fields.links} />
          </div>
        )}
      </div>
    </section>
  )
}
