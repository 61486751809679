import clsx from 'clsx'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import useIsVisible from '~/hooks/useIsVisible'

export default function ContentVacancy({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const [ref, isVisible] = useIsVisible()

  return (
    <section data-component="ContentVacancyUsps" className="section bg-site-grey-light section--with-bg" id={fields.sectionId} ref={ref}>
      <div className="container">
        {fields?.addbreadcrumbs && (
          <div className="lg:block hidden">
            <Breadcrumbs />
          </div>
        )}

        <div className="lg:grid flex flex-col grid-cols-2 max-w-screen-lg mx-auto lg:mt-20 lg:mb-10 lg:gap-6 gap-4">
          <div className="">
            <h2
              className={clsx(
                'leading-none mb-3',
                fields?.title?.length > 10 && 'title--semi-large',
                fields?.title?.length <= 10 && 'title--large',
                isVisible && 'animate-fade-up animate-ease-in-out',
                isVisible ? 'opacity-100' : 'opacity-0'
              )}
            >
              {fields.title}
            </h2>
            {fields.subtitle && (
              <h3
                className={clsx(
                  'font-bold sm:text-xl text-lg',
                  isVisible && 'animate-fade-up animate-ease-in-out animate-delay-200',
                  isVisible ? 'opacity-100' : 'opacity-0'
                )}
              >
                {fields.subtitle}
              </h3>
            )}
          </div>

          <Content
            className={clsx(
              'list--crosshair mt-4 max-sm:px-4',
              isVisible && 'animate-fade-up animate-ease-in-out animate-delay-500',
              isVisible ? 'opacity-100' : 'opacity-0'
            )}
          >
            {fields.description}
          </Content>
        </div>
      </div>
    </section>
  )
}
