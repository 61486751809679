import { SliderButton } from '@typeform/embed-react'
import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { Fragment, useMemo, useState } from 'react'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import CopyButton from '~/components/elements/CopyButton'
import type { Expert, Page_Flexcontent_Flex_Content } from '~/graphql/types'
import useIsVisible from '~/hooks/useIsVisible'

export default function ContentExpertCTAAlternative({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const [expert] = useState<Expert>(fields.relation)

  const [imageRef, imageVisible] = useIsVisible()
  const [contentRef, contentVisible] = useIsVisible()

  return (
    <section data-component="ContentExpertCTAAlternative" className="section--large section--with-bg bg-site-grey-light">
      <div className="container">
        <div className="flex xs:flex-row flex-col sm:grid grid-cols-2 max-sm:gap-6 max-w-screen-lg mx-auto">
          <div
            ref={imageRef}
            className={clsx(
              imageVisible && 'animate-fade-up animate-ease-in-out animate-duration-500',
              imageVisible ? 'opacity-100' : 'opacity-0',
              'flex justify-center'
            )}
          >
            <LossePlaatjie
              maxwidth={280}
              className="max-sm:min-w-[130px] w-[130px] sm:w-[220px] lg:w-[280px]"
              src={expert.featuredImage.node}
            />
          </div>
          <div ref={contentRef}>
            <h2
              className={clsx(
                contentVisible && 'animate-fade-up animate-delay-100 animate-ease-in-out animate-duration-500',
                contentVisible ? 'opacity-100' : 'opacity-0',
                'title--semi-large mb-6 lg:mb-10'
              )}
            >
              {fields.title.split('|').map((t, i) => (
                <Fragment key={i}>
                  {t}
                  {i < fields.title.split('|').length - 1 && <br />}
                </Fragment>
              ))}
            </h2>
            <Content
              className={clsx(
                contentVisible && 'animate-fade-up animate-delay-150 animate-ease-in-out animate-duration-500',
                contentVisible ? 'opacity-100' : 'opacity-0'
              )}
              type={fields.addbiggerdescription ? 'slighty-thicc' : 'normal'}
            >
              {fields.description}
            </Content>
            <div className="max-w-[350px]">
              <div
                className={clsx(
                  contentVisible && 'animate-fade-up animate-delay-300 animate-ease-in-out animate-duration-500',
                  contentVisible ? 'opacity-100' : 'opacity-0',
                  'mt-6 lg:mt-8'
                )}
              >
                {fields.id ? (
                  <TypeFormButton expert={expert} id={fields.id} />
                ) : (
                  <Button className="btn--accent w-full" to={expert.uri}>
                    {`Neem contact op met ${expert.recap.firstname}`}
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <g id="fi-rr-arrow-small-right">
                        <path
                          id="Vector"
                          d="M18 11.9996C17.9951 11.4735 17.7832 10.9705 17.41 10.5996L13.12 6.29958C12.9326 6.11333 12.6792 6.00879 12.415 6.00879C12.1508 6.00879 11.8974 6.11333 11.71 6.29958C11.6163 6.39254 11.5419 6.50315 11.4911 6.625C11.4403 6.74686 11.4142 6.87757 11.4142 7.00958C11.4142 7.14159 11.4403 7.2723 11.4911 7.39416C11.5419 7.51602 11.6163 7.62662 11.71 7.71958L15 10.9996H5C4.73478 10.9996 4.48043 11.1049 4.29289 11.2925C4.10536 11.48 4 11.7344 4 11.9996C4 12.2648 4.10536 12.5191 4.29289 12.7067C4.48043 12.8942 4.73478 12.9996 5 12.9996H15L11.71 16.2896C11.5217 16.4765 11.4154 16.7307 11.4144 16.996C11.4135 17.2614 11.518 17.5163 11.705 17.7046C11.892 17.8929 12.1461 17.9992 12.4115 18.0001C12.6768 18.0011 12.9317 17.8965 13.12 17.7096L17.41 13.4096C17.7856 13.0362 17.9978 12.5292 18 11.9996Z"
                          fill="white"
                        />
                      </g>
                    </svg>
                  </Button>
                )}
              </div>
              <div className="flex items-center justify-between gap-4 mt-4">
                {expert?.recap?.phone && (
                  <CopyButton
                    className="btn--outline--black text-sm w-full"
                    value={
                      <>
                        <svg
                          className="lg:hidden block"
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="18"
                          viewBox="0 0 12 18"
                          fill="none"
                        >
                          <path
                            d="M0.289062 0.755371V15.5383C0.289062 16.0984 0.51156 16.6355 0.907607 17.0316C1.30365 17.4276 1.84081 17.6501 2.4009 17.6501H9.44038C10.0005 17.6501 10.5376 17.4276 10.9337 17.0316C11.3297 16.6355 11.5522 16.0984 11.5522 15.5383V0.755371H0.289062ZM10.1443 2.16327V12.0185H1.69696V2.16327H10.1443ZM9.44038 16.2422H2.4009C2.21421 16.2422 2.03515 16.168 1.90314 16.036C1.77112 15.904 1.69696 15.725 1.69696 15.5383V13.4264H10.1443V15.5383C10.1443 15.725 10.0702 15.904 9.93814 16.036C9.80613 16.168 9.62708 16.2422 9.44038 16.2422Z"
                            fill="currentColor"
                          />
                          <path d="M6.62421 14.1299H5.21631V15.5378H6.62421V14.1299Z" fill="currentColor" />
                        </svg>

                        <span className="lg:block hidden">
                          Bel<span className={clsx(fields?.relations?.length > 4 && 'hidden')}> met {expert.recap.firstname}</span>
                        </span>
                      </>
                    }
                    hiddenValue={expert?.recap?.phone}
                    mobileHiddenValue={`tel:${expert?.recap?.phone}`}
                  />
                )}

                {expert?.recap?.email && (
                  <CopyButton
                    className="btn--outline--black text-sm overflow-hidden w-full"
                    value={
                      <>
                        <svg
                          className="lg:hidden block"
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_1711_897)">
                            <path
                              d="M12.9659 1.41797H3.69038C2.81212 1.41902 1.97014 1.76837 1.34911 2.3894C0.72809 3.01042 0.378738 3.8524 0.377686 4.73066L0.377686 12.6811C0.378738 13.5594 0.72809 14.4014 1.34911 15.0224C1.97014 15.6434 2.81212 15.9928 3.69038 15.9938H12.9659C13.8442 15.9928 14.6862 15.6434 15.3072 15.0224C15.9282 14.4014 16.2776 13.5594 16.2786 12.6811V4.73066C16.2776 3.8524 15.9282 3.01042 15.3072 2.3894C14.6862 1.76837 13.8442 1.41902 12.9659 1.41797V1.41797ZM3.69038 2.74305H12.9659C13.3626 2.74383 13.75 2.86331 14.0783 3.08611C14.4065 3.30892 14.6606 3.62485 14.8078 3.99326L9.73406 9.06764C9.36067 9.43953 8.85514 9.64833 8.32815 9.64833C7.80116 9.64833 7.29563 9.43953 6.92224 9.06764L1.84852 3.99326C1.9957 3.62485 2.24976 3.30892 2.57801 3.08611C2.90625 2.86331 3.29366 2.74383 3.69038 2.74305V2.74305ZM12.9659 14.6687H3.69038C3.16323 14.6687 2.65767 14.4593 2.28492 14.0866C1.91217 13.7138 1.70276 13.2083 1.70276 12.6811V5.72447L5.98541 10.0045C6.60729 10.6248 7.44979 10.9731 8.32815 10.9731C9.20651 10.9731 10.049 10.6248 10.6709 10.0045L14.9535 5.72447V12.6811C14.9535 13.2083 14.7441 13.7138 14.3714 14.0866C13.9986 14.4593 13.4931 14.6687 12.9659 14.6687Z"
                              fill="currentColor"
                            />
                          </g>
                        </svg>

                        <span className="lg:block hidden">
                          Mail<span className={clsx(fields?.relations?.length > 4 && 'hidden')}> met {expert.recap.firstname}</span>
                        </span>
                      </>
                    }
                    hiddenValue={expert?.recap?.email}
                    mobileHiddenValue={`mailto:${expert?.recap?.email}`}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function TypeFormButton({ expert, id }: { expert: Expert; id: string }) {
  return useMemo(() => {
    return (
      <SliderButton
        key="typeform"
        // tracking={{
        //   title: data.page.title,

        //   page_url: url
        // }}
        id={id}
        data-mode="drawer_right"
        className="btn--accent w-full typeform-share"
      >
        {`Neem contact op met ${expert.recap.firstname}`}
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
          <g id="fi-rr-arrow-small-right">
            <path
              id="Vector"
              d="M18 11.9996C17.9951 11.4735 17.7832 10.9705 17.41 10.5996L13.12 6.29958C12.9326 6.11333 12.6792 6.00879 12.415 6.00879C12.1508 6.00879 11.8974 6.11333 11.71 6.29958C11.6163 6.39254 11.5419 6.50315 11.4911 6.625C11.4403 6.74686 11.4142 6.87757 11.4142 7.00958C11.4142 7.14159 11.4403 7.2723 11.4911 7.39416C11.5419 7.51602 11.6163 7.62662 11.71 7.71958L15 10.9996H5C4.73478 10.9996 4.48043 11.1049 4.29289 11.2925C4.10536 11.48 4 11.7344 4 11.9996C4 12.2648 4.10536 12.5191 4.29289 12.7067C4.48043 12.8942 4.73478 12.9996 5 12.9996H15L11.71 16.2896C11.5217 16.4765 11.4154 16.7307 11.4144 16.996C11.4135 17.2614 11.518 17.5163 11.705 17.7046C11.892 17.8929 12.1461 17.9992 12.4115 18.0001C12.6768 18.0011 12.9317 17.8965 13.12 17.7096L17.41 13.4096C17.7856 13.0362 17.9978 12.5292 18 11.9996Z"
              fill="white"
            />
          </g>
        </svg>
      </SliderButton>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
