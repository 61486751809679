import TitleWithGreenBlock from '~/components/elements/TitleWithGreenBlock'
import type { Page_Flexcontent_Flex_Posts, Post as PostProps } from '~/graphql/types'
import PostHighlighted from './post-types/PostHighlighted'
import Post from './post-types/Post'
import useIsVisible from '~/hooks/useIsVisible'
import clsx from 'clsx'

export default function PostsNewsHighlighted({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const [ref, isVisible] = useIsVisible()
  const posts = fields?.relations as unknown as PostProps[]

  return (
    <section data-component="PostsNewsHighlighted" className="section" ref={ref}>
      <div className="container">
        <div className="flex items-end gap-16 px-6 sm:px-4">
          <TitleWithGreenBlock title={fields?.title} />
        </div>
        <div className="spacing--big--top grid gap-6 lg:gap-10 lg:grid-cols-2">
          <div>
            <PostHighlighted
              data={posts?.[0]}
              className={clsx('max-sm:h-[250px] max-lg:h-[350px]', isVisible ? 'animate-fade-up opacity-100' : 'opacity-0')}
              showReadtime={false}
              showCategories={false}
            />
          </div>
          <div className="grid gap-6 lg:gap-10 lg:grid-cols-1">
            <div>
              <Post
                data={posts?.[1]}
                type="dark"
                className={clsx(isVisible ? 'animate-fade-up animate-delay-300 opacity-100' : 'opacity-0')}
                showReadtime={false}
                showCategories={false}
              />
            </div>
            <div>
              <Post
                data={posts?.[2]}
                type="dark"
                className={clsx(isVisible ? 'animate-fade-up animate-delay-400 opacity-100' : 'opacity-0')}
                showReadtime={false}
                showCategories={false}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
