import { useInView } from 'framer-motion'
import { useRef } from 'react'
import useIsLighthouse from './useIsLighthouse'

type Response<T> = [React.MutableRefObject<T>, boolean]

export default function useIsVisible<T = HTMLDivElement>() {
  const isLighthouse = useIsLighthouse()
  const ref = useRef<T>(null)
  const isVisible = useInView(ref as React.RefObject<Element>, { once: true })

  if (isLighthouse) return [ref, true] as Response<T>

  return [ref, isVisible] as Response<T>
}
