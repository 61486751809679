import { LossePlaatjie } from '@ubo/losse-sjedel'
import { Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import type { Page_Flexcontent_Flex_Slider } from '~/graphql/types'
import { useComponentTheme } from '../Content/ContentColumnsBig'

export default function SliderLogos({ fields, index }: { fields: Page_Flexcontent_Flex_Slider; index: number }) {
  const { theme } = useComponentTheme(index, fields?.styletype)

  return (
    <section data-component="SliderLogos" className="section overflow-hidden" id={fields.sectionId}>
      <div className="container mx-auto relative">
        {theme === 'light' && <h2 className="title--medium mb-8 pt-4">{fields.title}</h2>}

        <Swiper
          className="w-full"
          autoplay
          modules={[Autoplay]}
          wrapperClass="items-center"
          slidesPerView={6}
          spaceBetween={15}
          breakpoints={{
            320: {
              slidesPerView: 2
            },
            640: {
              slidesPerView: 3
            },
            768: {
              slidesPerView: 4
            },
            1024: {
              slidesPerView: 5
            },
            1280: {
              slidesPerView: 6
            }
          }}
        >
          {fields.items.map((item, index) => (
            <SwiperSlide className="flex items-center" key={index}>
              <div className="relative flex flex-col items-center justify-center">
                <LossePlaatjie src={item.image} className="w-full h-auto object-contain" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  )
}
