import { SliderButton } from '@typeform/embed-react'
import type { LoaderData } from '@ubo/losse-sjedel'
import { LossePlaatjie, useLoaderData, useLosseLayout } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useEffect, useMemo, useState } from 'react'
import type { Page_Flexcontent_Flex_Banner, Vacature } from '~/graphql/types'
import useIsMobile from '~/hooks/useIsMobile'
import useIsVisible from '~/hooks/useIsVisible'
import useRichJobPosting from '~/hooks/useRichJobPosting'
import { gaViewItem } from '~/utils/ga'

export default function BannerVacancy({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const [hideFixedMobileButton, setHideFixedMobileButton] = useState(false)
  const data = useLoaderData<LoaderData<Vacature>>()
  const isMobile = useIsMobile('(max-width: 640px)')
  const { isScrollable } = useLosseLayout()
  const [showTypeform, setShowTypeform] = useState(false)
  const [ref, isVisible] = useIsVisible()
  const vakgebied = data.page?.vakgebieden?.nodes?.[0]
  const recap = data.page?.recap

  const url = data.request.url.replace('https://www.profield.nl:80', 'https://www.profield.nl')
  const title = data?.page?.title.replace('&', 'en')

  useRichJobPosting({
    job: {
      id: data?.page?.recap?.vacancyNr,
      title: data?.page?.title,
      description: data?.page?.title,
      type: 'FULL_TIME',
      datePosted: data?.page?.date
    },
    salary: {
      currency: 'EUR',
      minValue: recap?.salaryMax,
      maxValue: recap?.salaryMax,
      time: 'MONTH'
    },
    location: {
      countryCode: 'NL',
      address: recap?.region,
      zipcode: recap?.zipcode,
      addressRegion: recap?.region,
      addressLocality: recap?.state
    },
    organization: {
      name: 'Profield',
      url: 'https://www.profield.nl'
    }
  })

  useEffect(() => {
    const formApply = document.querySelector<HTMLDivElement>('#solliciteren')
    if (!formApply) return
    // check if formApply is in view
    const observer = new IntersectionObserver((entries) => {
      if (entries?.[0].isIntersecting) {
        // if it is, add the fixed class
        setHideFixedMobileButton(true)
      } else {
        setHideFixedMobileButton(false)
      }
    })
    observer.observe(formApply)

    return () => {
      observer.disconnect()
    }
  }, [])

  useEffect(() => {
    gaViewItem(data.page)

    setTimeout(() => {
      setShowTypeform(true)
    }, 10)
  }, [data.page])

  return (
    <section className="bg-site-base pb-1 lg:pt-1 pt-12  text-white header--margin--top--negative" data-component="BannerVacancy" ref={ref}>
      <div className="container section lg:grid flex flex-col-reverse grid-cols-2 gap-x-12">
        <div className="flex flex-col md:spacing--big--top">
          {vakgebied && <strong className="text-site-accent text-lg mb-4">{vakgebied.name}</strong>}
          <h1
            className={clsx(
              'title--medium lg:leading-[55px] mb-12',
              isVisible && 'animate-fade-up animate-once',
              isVisible ? 'opacity-100' : 'opacity-0'
            )}
          >
            {data.page.title}
          </h1>

          {recap?.salaryMin && (
            <span className="font-bold text-xl">
              {recap.salaryMin.toLocaleString('nl')}
              {recap.salaryMax ? ` - ${recap.salaryMax.toLocaleString('nl')}` : ''} euro
            </span>
          )}

          <div className="flex flex-wrap mt-4 gap-3 sm:mb-16">
            {recap.region && (
              <span className="label--outline--grey text-sm">{recap.region.replace('regio ', '').replace('Regio ', '')}</span>
            )}
            {recap.hoursAmount && (
              <span className="label--outline--grey text-sm">
                {recap.hoursAmount} {recap.hoursAmount !== 'Fulltime' && <span>uur</span>}{' '}
              </span>
            )}
            {recap.extraInfo && <span className="label--outline--grey text-sm">{recap.extraInfo}</span>}
          </div>

          {/* turns into a fixed button on mobile */}
          <div
            className={clsx(
              ' grid-cols-2 sm:max-w-lg gap-x-4 sm:relative fixed left-0 bottom-0 w-full bg-site-base sm:p-0 p-4 sm:z-0',
              hideFixedMobileButton ? 'sm:grid hidden' : 'sm:grid flex',
              isScrollable && 'z-40',
              !isScrollable && 'z-0'
            )}
          >
            <a className="btn--conversion px-10 sm:w-auto w-full" href="#solliciteren">
              Solliciteer direct
            </a>

            {showTypeform && !isMobile && <TypeFormButton data={data as LoaderData<Vacature>} url={url} />}

            <div className="sm:block hidden">
              {fields?.links?.[0]?.microcopy && (
                <p className="text-xs text-center mt-4 text-site-grey-dark">{fields?.links?.[0]?.microcopy}</p>
              )}
            </div>
            <div className="sm:block hidden">
              <div className="flex justify-center items-center gap-x-4 p-4">
                <span>Deel vacature</span>

                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href={`https://wa.me/?text=Misschien%20is%20deze%20vacature%20iets%20voor%20jou!: '${title}'. Bekijk de vacature hier: ${url}`}
                >
                  <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" width="24" height="23" fill="none" viewBox="0 0 24 23">
                    <g clipPath="url(#clip0_803_6497)">
                      <path
                        fill="#EAEAEB"
                        d="M22.679 11.204a11.31 11.31 0 01-16.748 9.81L-.32 23l2.044-6.01a11.084 11.084 0 01-1.618-5.793 11.292 11.292 0 0122.58 0l-.006.007zM11.387 1.786a9.466 9.466 0 00-9.493 9.418c0 1.986.633 3.921 1.809 5.523l-1.186 3.5 3.636-1.146a9.442 9.442 0 105.234-17.295zm5.704 11.997c-.072-.112-.256-.184-.532-.32-.276-.137-1.639-.801-1.888-.893-.248-.092-.44-.136-.623.136-.184.273-.716.893-.876 1.077-.16.184-.34.208-.6.072a7.672 7.672 0 01-2.225-1.363 8.279 8.279 0 01-1.54-1.902c-.163-.276-.017-.422.123-.562.14-.14.29-.32.412-.487.112-.139.204-.293.273-.457a.494.494 0 00-.02-.48c-.072-.14-.624-1.49-.856-2.045-.232-.555-.46-.456-.62-.456-.16 0-.34-.024-.532-.024a1.022 1.022 0 00-.74.34 3.066 3.066 0 00-.967 2.304c0 1.363.992 2.658 1.131 2.838.14.181 1.912 3.067 4.727 4.147 2.814 1.08 2.814.733 3.322.682.508-.052 1.635-.665 1.867-1.305.181-.411.238-.866.164-1.309v.007z"
                      ></path>
                    </g>
                  </svg>
                </a>

                <a
                  href={`mailto:?subject=${data?.page?.title} - Profield&body=Misschien is deze vacature iets voor jou!: ${title}. Bekijk de vacature hier: ${url}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24">
                    <g clipPath="url(#clip0_803_6499)">
                      <path
                        fill="#EAEAEB"
                        d="M18.679 1h-14a5.006 5.006 0 00-5 5v12a5.006 5.006 0 005 5h14a5.006 5.006 0 005-5V6a5.006 5.006 0 00-5-5zm-14 2h14a3 3 0 012.78 1.887L13.8 12.546a3.007 3.007 0 01-4.244 0l-7.658-7.66A3 3 0 014.679 3zm14 18h-14a3 3 0 01-3-3V7.5l6.464 6.46a5.007 5.007 0 007.072 0l6.464-6.46V18a3 3 0 01-3 3z"
                      ></path>
                    </g>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          {/* <Content className="z-20" theme="dark"></Content> */}
        </div>

        <div className="flex lg:flex-col lg:justify-center justify-between lg:items-end items-center lg:mb-0 mb-10">
          <button
            onClick={() => {
              if (typeof window === 'undefined') return
              window.history.back()
            }}
            className="lg:hidden flex items-center gap-x-2 text-white/50 lg:text-site-grey-dark"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14">
              <path
                fill="currentColor"
                d="M10.454.878a.875.875 0 01-.257.618L5.722 5.972a1.458 1.458 0 000 2.062l4.47 4.47a.875.875 0 01-1.238 1.237l-4.47-4.466a3.212 3.212 0 010-4.538L8.96.26a.875.875 0 011.494.619z"
              ></path>
            </svg>
            Terug
          </button>
          {data?.page?.recap?.vacancyNr && (
            <div className="text-white/50 lg:text-site-grey-dark text-right lg:mb-6">Vacature #{data?.page?.recap?.vacancyNr}</div>
          )}

          <div
            className={clsx(
              'ml-auto max-w-lg w-full lg:block hidden',
              isVisible && 'animate-fade-left animate-once',
              isVisible ? 'opacity-100' : 'opacity-0'
            )}
          >
            {data?.page?.featuredImage && <LossePlaatjie className="w-full h-full" src={data?.page?.featuredImage?.node} maxwidth={1000} />}
          </div>
        </div>
      </div>
    </section>
  )
}

function TypeFormButton({ data, url }: { data: LoaderData<Vacature>; url: string }) {
  return useMemo(() => {
    return (
      <SliderButton
        key="typeform"
        tracking={{
          title: data.page.title,
          advisor_name: data.page.recap.advisor.title,
          advisor_email: data.page.recap.advisor.recap.email,
          advisor_phone: data.page.recap.advisor.recap.phone,
          page_url: url
        }}
        id="yn7jGLK4"
        data-mode="drawer_right"
        className="btn--outline--white sm:px-6 p-1 sm:w-auto w-12 sm:h-auto h-12 sm:flex-auto flex-none typeform-share"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
          <path
            d="M1.94382 7.16876C1.60898 7.26443 1.30492 7.44588 1.06173 7.69514C0.818541 7.94441 0.644653 8.25285 0.557271 8.58995C0.46989 8.92705 0.472042 9.28113 0.563517 9.61714C0.654992 9.95316 0.832618 10.2595 1.07882 10.5058L4.49082 13.9137V20.4997H11.0838L14.5188 23.9297C14.7032 24.1157 14.9225 24.2634 15.1641 24.3643C15.4058 24.4652 15.665 24.5173 15.9268 24.5177C16.0989 24.5174 16.2703 24.4952 16.4368 24.4517C16.7738 24.3669 17.0824 24.1947 17.3317 23.9526C17.581 23.7106 17.7621 23.4071 17.8568 23.0727L24.4908 0.520763L1.94382 7.16876ZM2.49982 9.09175L19.5278 4.07176L6.49282 17.0857V13.0857L2.49982 9.09175ZM15.9398 22.5157L11.9128 18.4997H7.91282L20.9458 5.47776L15.9398 22.5157Z"
            fill="#EAEAEB"
          />
        </svg>
        <span className="sm:inline hidden">Stuur naar jezelf</span>
      </SliderButton>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
