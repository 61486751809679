/* eslint-disable react-hooks/exhaustive-deps */
import { useFetcher, useSearchParams } from '@ubo/losse-sjedel'
import type { Component_Popup } from '~/graphql/types'
import usePopup from '~/hooks/usePopup'
import Modal from '../../elements/Modal'
import Loading from '../../elements/Loading'
import Content from '../../elements/Content'
import StepProgress from '~/components/elements/StepProgress'
import { useEffect, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { type SearchResponse } from '~/services/elastic.server'
import Form, { Submit } from '~/components/elements/Form'
import { FormDuufField, useFormDuuf } from '@ubo/form-duuf'
import clsx from 'clsx'

export default function PopupVacancyAlert() {
  const [isOpen, setOpen] = usePopup('vacature-alert')

  return (
    <Modal show={isOpen} setShow={setOpen} setPaddings={false}>
      <ModalContent />
    </Modal>
  )
}

function ModalContent() {
  const fetcher = useFetcher<{ popup: { data: Component_Popup }; functions: SearchResponse; functionGroups: SearchResponse }>()
  const [currentStep, setCurrentStep] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [functionGroups, setFunctionGroups] = useState<string[]>([])

  useEffect(() => {
    fetcher.load(`/api/vacancy-alert`)
  }, [])

  useEffect(() => {
    if (fetcher.state === 'idle' && isLoading) setLoading(false)
  }, [fetcher.state])

  if (fetcher.state === 'loading' || !fetcher?.data?.popup) {
    return (
      <div className="flex--center py-20">
        <Loading />
      </div>
    )
  }

  return (
    <Form
      data={fetcher?.data?.popup?.data?.form}
      theme="light"
      useChildren={true}
      className="pb-12 pt-10 sm:pt-14 z-10 px-4 md:px-14 relative form--light"
    >
      <>
        {fetcher?.data?.popup?.data?.title && <h2 className="title--medium text-site-base mb-6">{fetcher?.data?.popup?.data?.title}</h2>}
        <Content className="mb-4 text-sm children-p:leading-6">{fetcher?.data?.popup?.data?.description}</Content>
        <div className="px-2 md:px-4 pt-4">
          <div className="max-sm:px-6">
            <StepProgress currentStep={currentStep} steps={['Vakgebied', 'Reistijd', 'Gegevens']} />
          </div>

          <div className="overflow-hidden">
            <AnimatePresence mode="wait">
              {currentStep === 0 && (
                <motion.div key={0} initial={{ opacity: 0, x: '100%' }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: '-100%' }}>
                  <div className="mt-4">
                    <h3 className="title--tiny">Vakgebieden</h3>
                    <div className="mt-4 grid gap-3">
                      {/* @ts-ignore */}
                      {fetcher?.data?.search?.data?.aggregations?.functionGroup?.buckets.map((bucket) => (
                        <Option
                          key={bucket.key}
                          value={bucket.key}
                          name="input_8"
                          onChange={(values) => {
                            setFunctionGroups(values)
                          }}
                        />
                      ))}
                    </div>
                  </div>

                  <Functions functionGroups={functionGroups} />

                  <div className="flex justify-center mt-20">
                    <button
                      type="button"
                      className="btn--conversion"
                      onClick={() => {
                        setCurrentStep(1)
                      }}
                      disabled={functionGroups.length === 0}
                    >
                      Naar volgende stap
                    </button>
                  </div>
                </motion.div>
              )}

              {currentStep === 1 && (
                <motion.div key={1} initial={{ opacity: 0, x: '100%' }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: '-100%' }}>
                  <div className="mt-4">
                    <Zipcode />
                  </div>
                  <div className="mt-6">
                    <Traveltimes />
                  </div>
                  <div className="flex flex-col items-center mt-20">
                    <div className="text-sm font-site-base mb-2">Deze stap is optioneel</div>
                    <button
                      type="button"
                      className="btn--conversion"
                      onClick={() => {
                        setCurrentStep(2)
                      }}
                    >
                      Naar volgende stap
                    </button>
                  </div>
                </motion.div>
              )}

              {currentStep === 2 && (
                <motion.div key={2} initial={{ opacity: 0, x: '100%' }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: '-100%' }}>
                  <div className="mt-4">
                    <FormDuufField id={10} />
                  </div>
                  <div>
                    <FormDuufField id={6} />
                  </div>
                  <div className="flex flex-col items-center mt-20">
                    <Submit theme="light" />
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </>
    </Form>
  )
}

function Functions({ functionGroups }: { functionGroups: string[] }) {
  const fetcher = useFetcher<{ search: SearchResponse }>()
  const duuf = useFormDuuf()

  useEffect(() => {
    if (functionGroups.length === 0) return

    fetcher.load(`/api/vacancy-alert-functions?Vakgebieden=${encodeURI(functionGroups.join(',').replace(/&/g, '%26'))}`)
  }, [JSON.stringify(functionGroups)])

  useEffect(() => {
    if (functionGroups.length > 0) return

    duuf.formik.handleChange({
      target: {
        name: 'input_9',
        value: ''
      }
    })
  }, [functionGroups])

  if (functionGroups.length === 0) return null

  return (
    <div className="mt-6">
      <h3 className="title--tiny">Functies</h3>
      <div className="mt-4 grid gap-3">
        {/* @ts-ignore */}
        {fetcher?.data?.search?.data?.aggregations?.function?.buckets.map((bucket) => (
          <Option key={bucket.key} value={bucket.key} name="input_9" />
        ))}
      </div>
    </div>
  )
}

function Zipcode() {
  const [searchParams] = useSearchParams()
  const [zipcode, setZipcode] = useState(searchParams.get('Postcode') || '')
  const duuf = useFormDuuf()

  return (
    <label className="flex flex-col" htmlFor="alert-Postcode">
      <span className="font-bold mb-1 lg:text-lg">Jouw postcode</span>
      <input
        type="text"
        placeholder="Bijv. 3751AA"
        name="alert-Postcode"
        id="alert-Postcode"
        className="form-duuf-input !text-base"
        value={zipcode}
        onChange={(event) => {
          const value = event.target.value.toUpperCase().replace(/ /g, '')

          setZipcode(value)

          duuf.formik.handleChange({
            target: {
              name: 'input_1',
              value: event.target.value
            }
          })
        }}
        maxLength={6}
      />
    </label>
  )
}

function Traveltimes() {
  const [searchParams] = useSearchParams()
  const [travelTime, setTravelTime] = useState(searchParams.get('Reistijd') || '')
  const duuf = useFormDuuf()

  return (
    <label className="flex flex-col" htmlFor="alert-Reistijd">
      <span className="font-bold mb-1 lg:text-lg">Max. reistijd</span>

      <select
        name="alert-Reistijd"
        id="alert-Reistijd"
        className={clsx('form-duuf-select__control form-duuf-input bg-white !text-base select--icon', !travelTime && '!text-site-base/50')}
        value={travelTime}
        placeholder="Kies een reistijd"
        onChange={(event) => {
          setTravelTime(event.target.value)

          duuf.formik.handleChange({
            target: {
              name: 'input_3',
              value: event.target.value
            }
          })
        }}
      >
        <option disabled hidden value="">
          Kies een reistijd
        </option>
        <option value="">-</option>
        <option value={15}>15 min.</option>
        <option value={30}>30 min.</option>
        <option value={45}>45 min.</option>
        <option value={60}>60 min.</option>
        <option value={75}>1 uur en 15 min.</option>
        <option value={90}>1 uur en 30 min.</option>
        <option value={105}>1 uur en 45 min.</option>
        <option value={120}>2 uur</option>
      </select>
    </label>
  )
}

function Option({ name, value, onChange }: { name: string; value: string; onChange?: (value: string[]) => void }) {
  const duuf = useFormDuuf()
  const values = duuf.formik.values[name].split(',')
  const isSelected = values.includes(value)

  return (
    <label
      className="text-sm flex items-center gap-3 hover:cursor-pointer group hover:bg-site-grey-light/50 rounded-md"
      htmlFor={`${name}-${value}`}
    >
      <input
        type="checkbox"
        className="mr-2 hidden peer"
        id={`${name}-${value}`}
        onChange={() => {
          let _values = [...values]

          if (isSelected) {
            _values.splice(_values.indexOf(value), 1)
          } else {
            _values.push(value)
          }

          _values = _values.filter((value) => !!value)

          if (typeof onChange === 'function') onChange(_values)

          duuf.formik.handleChange({
            target: {
              name,
              value: _values.join(',').replace(/^,/, '')
            }
          })
        }}
        checked={isSelected}
      />
      <div className="min-w-[20px] min-h-[20px] max-w-[20px] max-h-[20px] rounded-[4px] peer-checked:bg-site-accent [&_>_svg]:hidden peer-checked:[&_>_svg]:block bg-site-grey flex--center">
        <svg xmlns="http://www.w3.org/2000/svg" width="81" height="79" viewBox="0 0 81 79" fill="none" className="w-4 h-4 text-white">
          <path d="M49.125 0H31.875V25.2361H49.125V0Z" fill="currentColor" />
          <path d="M49.125 53.7637H31.875V78.9998H49.125V53.7637Z" fill="currentColor" />
          <path d="M81 31.0879H55.125V47.912H81V31.0879Z" fill="currentColor" />
          <path d="M25.875 31.0879H0V47.912H25.875V31.0879Z" fill="currentColor" />
        </svg>
      </div>
      <span className="group-hover:underline line-clamp-1">{value}</span>
    </label>
  )
}
