import { Fragment } from 'react'
import Links from '~/components/elements/Links'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentCta({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentCta" className="section">
      <div className="container">
        <div className="bg-site-grey py-10 px-8 md:px-20 grid gap-10 grid-cols-1 lg:grid-cols-2">
          <div>
            <h2 className="border-text max-lg:text-center text-transparent title--large xl:!text-7xl">
              {fields?.title?.split(' | ')?.map((row, key) => (
                <Fragment key={key}>
                  {key > 0 && <br />}
                  {row}
                </Fragment>
              ))}
            </h2>
            {fields?.subtitle && <h3 className="text-site-accent title--small mt-4 max-lg:text-center">{fields.subtitle}</h3>}
          </div>
          <div className="flex justify-center items-center">
            <Links icons="arrow" items={fields.links} />
          </div>
        </div>
      </div>
    </section>
  )
}
