import clsx from 'clsx'
import { motion } from 'framer-motion'
import { useState } from 'react'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentSwitch({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const [switched, setSwitch] = useState(true)

  return (
    <section data-component="ContentSwitch" className="bg-site-grey-light section section--with-bg">
      <div className="container">
        <div className="max-w-screen-lg mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div className="flex justify-center">
            <div
              className={clsx(
                // switched ? 'justify-end' : 'justify-start',
                'bg-white rounded-full transition-all duration-200 w-[200px] sm:w-[400px] h-[100px] sm:h-[200px] py-4 px-4 sm:px-8 relative'
              )}
            >
              {switched ? (
                <motion.div
                  key={'left'}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="absolute top-0 sm:text-lg text-sm my-auto h-fit bottom-0 left-8 lg:left-16"
                >
                  {fields.subtitle.split('|')[0]}
                </motion.div>
              ) : (
                <motion.div
                  key={'right'}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="absolute top-0 sm:text-lg text-sm my-auto h-fit bottom-0 right-8 lg:right-16"
                >
                  {fields.subtitle.split('|')[2]}
                </motion.div>
              )}
              <motion.div
                animate={{ x: switched ? '100%' : 0 }}
                transition={{ duration: 0.2, ease: 'easeInOut' }}
                onClick={() => setSwitch(!switched)}
                className={clsx(
                  switched ? 'bg-site-accent' : 'bg-red-500',
                  'absolute top-0 bottom-0 my-auto h-[calc(100%_-_16px)] sm:h-[calc(100%_-_32px)] cursor-pointer transition-all duration-200 flex justify-center items-center text-2xl sm:text-4xl font-bold rounded-full aspect-square'
                )}
              >
                {switched ? fields.subtitle.split('|')[1] : fields.subtitle.split('|')[3]}
              </motion.div>
            </div>
          </div>
          <div>
            {switched ? (
              <div>
                <h2 className="title--semi-large mb-6 sm:mb-10 text-site-accent">{fields.title}</h2>
                <Content className="list--stripe--accent children-ul:gap-4 sm:children-ul:gap-8 children-strong:text-site-base children-p:text-lg">
                  {fields.description}
                </Content>
              </div>
            ) : (
              <div>
                <h2 className="title--semi-large mb-6 sm:mb-10 text-red-500">{fields.extratitle}</h2>
                <Content className="list--stripe--accent children-ul:gap-4 sm:children-ul:gap-8 children-strong:text-site-base children-p:text-lg">
                  {fields.extradescription}
                </Content>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
