import { Link } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { type ElasticVacancy } from '~/services/elastic.server'
import { gaSelectItem } from '~/utils/ga'
import valuta from '~/utils/valuta'

interface VacancyProps extends React.HTMLAttributes<HTMLDivElement> {
  source: ElasticVacancy
  theme?: 'light' | 'dark'
}

export default function Vacancy({ source, className, theme = 'light', ...rest }: VacancyProps) {
  return (
    <Link
      className={clsx(
        theme === 'light' && 'hover:bg-site-base',
        theme === 'dark' && 'hover:bg-site-grey',
        'block--shadow p-4 rounded-md flex flex-col bg-white group',
        className
      )}
      to={source.uri}
      // @ts-ignore
      onClick={(_e) => {
        gaSelectItem(source)
      }}
      {...rest}
    >
      <div className="bg-site-grey-light px-4 py-4 sm:pt-12 sm:pb-8 rounded-md flex-grow flex flex-col justify-between relative">
        {source.highlighted > 0 && <div className="absolute right-3 top-3 label--black--small">Uitgelicht!</div>}
        <div>
          <span className="text-sm text-site-accent">{source.functionGroup}</span>
          <h3 className="title--light text-site-base mt-2 sm:line-clamp-4">{source.title}</h3>
        </div>
        <div className="flex flex-wrap mt-4 sm:mt-10 gap-3">
          {source.hoursAmount && <span className="label--outline--black">{source.hoursAmount} uur</span>}
          {source.region && <span className="label--outline--black">{source.region.replace('regio ', '').replace('Regio ', '')}</span>}
        </div>
      </div>
      <div className="pt-4 pb-2 pl-2 flex justify-between items-end">
        <div>
          <div className={clsx(theme === 'light' && 'group-hover:text-white', 'text-lg font-bold text-site-base')}>
            {valuta(source.salaryMin)} - {valuta(source.salaryMax)}
          </div>
          <span className={clsx(theme === 'light' && 'group-hover:text-white/50', 'text-site-base/40 text-sm font-bold block -mt-[6px]')}>
            euro
          </span>
        </div>
        <div className="btn--conversion h-[36px]">Bekijk</div>
      </div>
    </Link>
  )
}
