/* eslint-disable react-hooks/exhaustive-deps */
import { useSearchParams } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { useDebounce } from 'usehooks-ts'
import { type SearchValueAggregation } from '~/services/elastic.server'
import valuta from '~/utils/valuta'
import { motion } from 'framer-motion'
import useIsMobile from '~/hooks/useIsMobile'

export default function RangeSlider({
  field,
  aggregationFrom,
  aggregationTo,
  className
}: {
  field: string
  aggregationFrom: SearchValueAggregation
  aggregationTo: SearchValueAggregation
  className?: string
}) {
  const [searchParams, setSearchParams] = useSearchParams()
  const init = useRef(false)
  const currentValue = Number(searchParams.get(field))
  const [value, setValue] = useState(currentValue || 0)
  const debouncedValue = useDebounce<number>(value, 500)
  const isMobile = useIsMobile()
  const [_isOpen, setOpen] = useState(!isMobile)
  const isOpen = isMobile ? _isOpen : true

  useEffect(() => {
    if (currentValue !== value) setValue(currentValue)
  }, [currentValue])

  useEffect(() => {
    if (init.current === false) {
      init.current = true
      return
    }

    if (value === 0 || value === currentValue) return

    searchParams.set(field, value.toString())
    setSearchParams(searchParams, {
      replace: true,
      state: {
        scroll: false
      }
    })
  }, [debouncedValue.toString()])

  const percentage = value ? Math.round(((value - aggregationFrom.value) / (aggregationTo.value - aggregationFrom.value)) * 100) : 0

  return (
    <div className={clsx('mb-4 lg:mb-10 animate-fade-up', className)}>
      <h3
        role={isMobile ? 'button' : undefined}
        className="max-lg:text-2xl title--tiny opacity-40 mb-2 flex items-center justify-between"
        onClick={() => {
          if (isMobile) setOpen(!isOpen)
        }}
      >
        {field}
        <span className={clsx('lg:hidden transition-all', isOpen && 'rotate-90')}>
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
            <g clipPath="url(#clip0_956_10641)">
              <path
                d="M3.74999 14.0626C3.75001 13.9394 3.77431 13.8175 3.8215 13.7037C3.86869 13.5899 3.93783 13.4865 4.02499 13.3995L8.81936 8.60512C8.96449 8.46002 9.07962 8.28776 9.15817 8.09816C9.23672 7.90856 9.27715 7.70535 9.27715 7.50012C9.27715 7.2949 9.23672 7.09168 9.15817 6.90208C9.07962 6.71249 8.96449 6.54022 8.81936 6.39512L4.03061 1.60637C3.85984 1.42956 3.76534 1.19274 3.76748 0.946932C3.76962 0.701122 3.86821 0.465985 4.04203 0.292165C4.21585 0.118345 4.45099 0.0197493 4.6968 0.0176133C4.94261 0.0154773 5.17942 0.109972 5.35624 0.280745L10.145 5.0695C10.7888 5.71455 11.1505 6.58872 11.1505 7.50012C11.1505 8.41152 10.7888 9.28569 10.145 9.93075L5.34998 14.7257C5.21884 14.8567 5.05181 14.9459 4.87 14.982C4.6882 15.0181 4.49977 14.9995 4.32854 14.9285C4.15731 14.8575 4.01096 14.7374 3.90799 14.5833C3.80501 14.4292 3.75003 14.248 3.74999 14.0626Z"
                fill="currentColor"
              />
            </g>
          </svg>
        </span>
      </h3>
      <motion.div
        initial={{ height: 0 }}
        animate={{ height: isOpen ? 'auto' : 0 }}
        transition={{ duration: 0.3, ease: 'easeInOut' }}
        className="overflow-hidden"
      >
        <div className="max-lg:pb-4">
          <div className="rounded-md flex--center mt-9">
            <div className="relative w-full hover:bg-site-grey-light/50 rounded-md px-1">
              <div
                className={clsx(
                  !value && 'opacity-50',
                  'absolute bottom-7 px-2 py-1 rounded-md bg-site-grey-light text-xs pointer-events-none'
                )}
                style={{ left: `${percentage > 0 ? percentage : 0}%`, transform: `translateX(-${percentage}%)` }}
              >
                {value ? valuta(value, true) : '-'}
              </div>
              <input
                type="range"
                min={aggregationFrom.value}
                max={aggregationTo.value}
                step={50}
                value={value}
                onChange={(event) => {
                  setValue(event.target.valueAsNumber)
                }}
                className="w-full range--slider hover:cursor-pointer relative bottom-[2px]"
              />
            </div>
          </div>
          <div className="flex items-center justify-between mt-1 max-lg:font-bold">
            <span>{valuta(aggregationFrom.value, true)}</span>
            <span>{valuta(aggregationTo.value, true)}</span>
          </div>
        </div>
      </motion.div>
    </div>
  )
}
