import { Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentTechSpecs({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentTechSpecs" className="section mt-6" id={fields.sectionId}>
      <div className="container">
        <div className="flex flex-col max-w-screen-md mx-auto  bg-site-base text-white items-center text-center sm:p-10 p-4">
          <div className="mb-10">
            <h2 className="title--medium leading-none border-text--white !text-transparent mb-2 mt-2">{fields.title}</h2>
            <h3 className="font-bold lg:text-xl text-lg text-site-accent">{fields.subtitle}</h3>
          </div>

          {fields?.items && (
            <div className="w-full">
              <Swiper slidesPerView={1} autoplay modules={[Autoplay]}>
                {fields?.items?.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className="max-w-xl mx-auto">
                      <h4 className="font-bold text-site-accent title--medium mb-3">{item?.title}</h4>
                      <Content className="content--dark sm:text-xl sm:mb-4 mb-3">{item?.description}</Content>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
