import { losseContentParse } from '@ubo/losse-sjedel'
import { Fragment } from 'react'

export default function BreakTitle({ title }: { title: string }) {
  return title?.split(' | ')?.map((row, key) => {
    return (
      <Fragment key={key}>
        {key > 0 && <br />}
        {losseContentParse(row)}
      </Fragment>
    )
  })
}
