import Links from '~/components/elements/Links'
import clsx from 'clsx'
import { Fragment } from 'react'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import { LossePlaatjie } from '@ubo/losse-sjedel'
import useIsVisible from '~/hooks/useIsVisible'

export default function BannerLanding({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const [ref, isVisible] = useIsVisible()

  return (
    <section data-component="BannerLanding" className="sm:section section--with-bg relative">
      <div
        className={clsx(
          'sm:absolute -z-10 grid sm:grid-cols-2 w-full h-full max-w-[2000px] sm:top-1/2 sm:left-1/2 sm:transform sm:-translate-x-1/2 sm:-translate-y-1/2',
          fields?.videoUrl && 'z-10'
        )}
      >
        <div />
        <div className="sm:relative">
          {fields?.videoUrl && (
            <iframe
              width="100%"
              height="100%"
              className="w-full max-sm:h-[250px] sm:!w-[calc(100%_+_25px)] xl:!w-[calc(100%_+_90px)] !max-w-[unset] object-cover sm:absolute sm:right-0 sm:top-0 sm:h-[440px] md:h-[415px] lg:h-[565px] xl:h-[545px]"
              src={fields?.videoUrl}
              title="Profield presents!"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          )}
          {!fields?.videoUrl && (
            <LossePlaatjie
              maxwidth={2000}
              className="w-full max-sm:h-[250px] sm:!w-[calc(100%_+_90px)] !max-w-[unset] object-cover sm:absolute sm:right-0 sm:top-0 sm:h-[440px] md:h-[415px] lg:h-[565px] xl:h-[545px]"
              src={fields?.image}
            />
          )}
        </div>
      </div>
      <div className="sm:container md:container lg:container">
        <div className="grid sm:grid-cols-2 h-full">
          <div>
            <div
              ref={ref}
              className={clsx(
                isVisible && 'animate-fade-right animate-once animate-delay-300 block',
                isVisible ? 'opacity-100' : 'opacity-0',
                'bg-site-base w-full sm:!pl-0 max-sm:p-4 max-sm:py-8 max-sm:-mt-1 sm:p-4 md:p-8 lg:p-14 sm:relative'
              )}
            >
              <div className="text-white title--semi-large">
                {fields?.title?.split(' | ')?.map((row, key) => {
                  return (
                    <Fragment key={key}>
                      {key > 0 && <br />}
                      <span>{row}</span>
                    </Fragment>
                  )
                })}
              </div>
              <div className="text-site-accent title--small pt-4">
                {fields?.subtitle?.split(' | ')?.map((row, key) => {
                  return (
                    <Fragment key={key}>
                      {key > 0 && <br />}
                      <span>{row}</span>
                    </Fragment>
                  )
                })}
              </div>
              <Links items={fields?.links} className="spacing--normal--top lg:pb-6 max-sm:justify-center" />
              <span className="sm:block hidden absolute top-0 -left-[100px] w-[101px] h-full bg-site-base -z-10" />
            </div>
            <div className="pt-4 max-sm:p-4">
              <Breadcrumbs />
            </div>
          </div>
          <div />
        </div>
      </div>
    </section>
  )
}
