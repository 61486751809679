import clsx from 'clsx'
import { useInView } from 'framer-motion'
import { Fragment, useRef } from 'react'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentTitle({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const titleRef = useRef(null)
  const contentRef = useRef(null)

  const titleVisible = useInView(titleRef, { once: true })
  const contentVisible = useInView(contentRef, { once: true })

  return (
    <section data-component="ContentTitle" className="section" id={fields.sectionId}>
      <div className="container">
        {fields.addbreadcrumbs && (
          <div
            className={clsx(
              titleVisible && 'animate-fade-up animate-ease-in-out animate-delay-300 animate-duration-500 -mt-8 lg:-mt-20',
              titleVisible ? 'opacity-100' : 'opacity-0',
              'py-6 sm:py-10 lg:py-16'
            )}
          >
            <Breadcrumbs />
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 max-md:gap-6">
          <div ref={titleRef}>
            <h2
              className={clsx(
                titleVisible && 'animate-fade-up animate-ease-in-out animate-duration-500',
                titleVisible ? 'opacity-100' : 'opacity-0',
                'title--large pb-4'
              )}
            >
              {fields.title.split('|').map((t, i) => (
                <Fragment key={i}>
                  <span>{t}</span>
                  <br />
                </Fragment>
              ))}
            </h2>
            <h3
              className={clsx(
                titleVisible && 'animate-fade-up animate-ease-in-out animate-delay-100 animate-duration-500',
                titleVisible ? 'opacity-100' : 'opacity-0',
                'title--normal text-site-accent'
              )}
            >
              {fields.subtitle}
            </h3>
          </div>
          <div
            ref={contentRef}
            className={clsx(
              contentVisible && 'animate-fade-up animate-ease-in-out animate-duration-500 animate-delay-150',
              contentVisible ? 'opacity-100' : 'opacity-0',
              'lg:pt-4'
            )}
          >
            <Content type={fields.addbiggerdescription ? 'slighty-thicc' : 'normal'}>{fields.description}</Content>
          </div>
        </div>
      </div>
    </section>
  )
}
