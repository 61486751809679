import CrossHair from '~/components/elements/CrossHair'
import type { InterneVacature, Page_Flexcontent_Flex_Posts } from '~/graphql/types'
import InternalVacancy from './post-types/InternalVacancy'
import { useState } from 'react'
import useIsVisible from '~/hooks/useIsVisible'
import clsx from 'clsx'

export default function InternalVacanciesHighlighted({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  // If they picked relations it gets filtered otherwise it just picks all the internal vacancies :)
  const [vacancies] = useState(() => {
    if (fields.relations) {
      return fields.posts.edges.filter((e) => {
        const v = e.node as InterneVacature

        return fields.relations.map((r) => r.databaseId).includes(v.databaseId)
      })
    } else {
      return fields.posts.edges
    }
  })

  const [titleRef, titleVisible] = useIsVisible()
  const [vacanciesRef, vacanciesVisible] = useIsVisible()

  return (
    <section data-component="InternalVacanciesHighlighted" className="section section--with-bg bg-site-base" id="interne-vacatures">
      <div className="container pt-12">
        <div ref={titleRef} className="max-w-screen-lg mx-auto">
          <h2
            className={clsx(
              titleVisible && 'animate-fade-up animate-ease-in-out animate-duration-500',
              titleVisible ? 'opacity-100' : 'opacity-0',
              'text-transparent lg:spacing--small--bottom border-text--white title--large relative'
            )}
          >
            <CrossHair
              className={clsx(
                titleVisible && 'animate-fade-up animate-ease-in-out animate-delay-300 animate-duration-500',
                titleVisible ? 'opacity-100' : 'opacity-0',
                'absolute w-[80px] -z-10 max-sm:opacity-25 sm:-left-16 -top-16'
              )}
            />
            {fields.title}
          </h2>
          <p
            className={clsx(
              titleVisible && 'animate-fade-up animate-ease-in-out animate-delay-100 animate-duration-500',
              titleVisible ? 'opacity-100' : 'opacity-0',
              'text-white'
            )}
          >
            {fields.subtitle}
          </p>
        </div>
        <div
          ref={vacanciesRef}
          className={clsx(
            vacanciesVisible && 'animate-fade-up animate-ease-in-out animate-delay-500 animate-duration-500',
            vacanciesVisible ? 'opacity-100' : 'opacity-0',
            'max-w-screen-md mx-auto flex flex-col gap-4 mt-8 lg:mt-14'
          )}
        >
          {vacancies?.map((e) => {
            const v = e.node as InterneVacature

            return <InternalVacancy key={v.databaseId} fields={v} />
          })}
        </div>
      </div>
    </section>
  )
}
