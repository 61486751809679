import type { Page_Flexcontent_Flex_Posts, Review, ReviewObject } from '~/graphql/types'
import Content from '~/components/elements/Content'
import clsx from 'clsx'
import date from '~/utils/date'
import { useSearchParams } from '@ubo/losse-sjedel'
import Select from '~/components/elements/post-overview/Select'
import Pagination from '~/components/elements/post-overview/Pagination'
import Stars from '~/components/elements/Stars'
import { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

type PostsReviews = Page_Flexcontent_Flex_Posts & {
  subdata: {
    Reviews: ReviewObject
  }
}

const SortingOptions = ['Laatst toegevoegd', 'Eerst toegevoegd']

const handleSort = (reviews: Review[], sortingType: string) => {
  switch (sortingType) {
    case SortingOptions[0]:
      return reviews.sort((a: Review, b: Review) => (date(a.createdAt).isBefore(b.createdAt) ? 1 : -1))
    case SortingOptions[1]:
      return reviews.sort((a: Review, b: Review) => (date(a.createdAt).isBefore(b.createdAt) ? -1 : 1))
    case SortingOptions[2]:
      return reviews.sort((a: Review, b: Review) => (a.rating > b.rating ? -1 : 1))
    case SortingOptions[3]:
      return reviews.sort((a: Review, b: Review) => (a.rating > b.rating ? 1 : -1))
    default:
      return reviews.sort((a: Review, b: Review) => (date(a.createdAt).isBefore(b.createdAt) ? 1 : -1))
  }
}

export default function PostsReviews({ fields: _fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const fields = _fields as PostsReviews
  const [searchParams] = useSearchParams()
  const data = fields.subdata.Reviews

  const reviewAmounts = Array.from(
    { length: 5 },
    (_, i) => data?.reviews.filter((review) => Math.ceil(review.rating / 2) === i + 1).length
  ).reverse()

  const sorted = handleSort(data?.reviews, searchParams.get('Sorteren'))

  const reviews = sorted.slice(
    (Number.parseInt(searchParams.get('Pagina')) || 1) * 10 - 10,
    (Number.parseInt(searchParams.get('Pagina')) || 1) * 10
  )

  const getBarPercentage = (amount: number): string => Math.round((amount / data?.totalReviews) * 100) + '%'

  return (
    <>
      <section
        data-component="PostsReviews"
        className="section section--with-bg bg-site-base header--margin--top--negative header--padding--top"
      >
        <div className="container">
          <div className="content--dark md:columns-2 content--white gap-16 lg:gap-24 pt-12 lg:pt-14 lg:pb-0 xl:px-28">
            <div className="break-after-column">
              <div className="flex max-sm:flex-wrap max-sm:gap-y-5 mb-4 sm:mb-8">
                <span className="title--semi-unit pr-8">{data?.averageRating.toString().replace('.', ',')}</span>
                <div className="flex items-end">
                  <Stars rating={data?.averageRating} size="large" className="max-lg:hidden" />
                  <Stars rating={data?.averageRating} size="medium" className="lg:hidden" />
                </div>
              </div>
              <div className="border-text--white text-transparent title--medium py-3">{fields?.title}</div>
              <div className="py-3 lg:py-6 lg:pr-20 xl:pr-40">
                <Content>{fields?.description}</Content>
              </div>
            </div>
            <div className="pt-6 lg:pt-16">
              <p className="sm:text-xl max-md:hidden">Aantal beoordelingen</p>
              <span className="title--medium">{data?.totalReviews}</span>
              <div className="flex flex-col pt-4 lg:pt-12">
                {reviewAmounts.map((value, key) => (
                  <div key={key} className="flex lg:w-[335px]">
                    <div className="w-8">{[5, 4, 3, 2, 1][key]}</div>
                    <div className="w-8 mt-[3px]">
                      <svg key={key} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24">
                        <path
                          className="fill-white"
                          d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2L9.19 8.63L2 9.24l5.46 4.73L5.82 21L12 17.27z"
                        />
                      </svg>
                    </div>
                    <div className="w-full ml-2 mr-5 bg-white rounded-full h-2.5 my-[6px] overflow-hidden">
                      <div className="bg-site-accent h-2.5 rounded-full" style={{ width: getBarPercentage(value) }}></div>
                    </div>
                    <div className="w-8 text-left">{value}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section xl:!mt-12">
        <div className="container">
          <div className="content--light">
            <div className="w-full flex sm:flex-row flex-col md:columns-2 py-1 md:py-3 sm:gap-0 gap-2 max-sm:mb-2">
              <div className="w-full break-after-column flex justify-start">
                <div className="flex title--tiny text-site-accent pr-1">{data.totalReviews}</div>
                <div className="flex title--tiny">beoordelingen</div>
              </div>
              <div className="w-full flex sm:justify-end ">
                <Select name="Sorteren" options={SortingOptions} />
              </div>
            </div>
            <div className="w-full h-full flex flex-row py-1 md:py-3">
              <div className="grid gap-6 md:grid-cols-2">
                {reviews.map((review, key) => (
                  <ReviewItem key={key} index={key} review={review} />
                ))}
              </div>
            </div>
            <div className="w-full h-full flex flex-row justify-center spacing--big--top">
              <Pagination total={data.totalReviews} limit={10} />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

function ReviewItem({ review, index }: { review: Review; index: number }) {
  const [commentOpen, setCommentOpen] = useState(false)
  const _date = date(review?.createdAt)
  const hit = index % 4 === 1 || index % 4 === 2

  return (
    <div key={index} className={clsx(hit ? 'bg-white lg:bg-gray-100' : 'bg-white', 'block w-full p-4 sm:p-6 border rounded-lg shadow')}>
      <div className="w-full flex flex-row columns-3 mb-2 justify-end">
        <div className="w-28 sm:w-32 flex break-after-column">
          <div className="title--small">{review?.rating},0</div>
        </div>
        <div className="w-full flex break-after-column justify-start">
          <div className="pt-1">
            <Stars rating={review?.rating} size="medium" className="max-sm:hidden" />
            <Stars rating={review?.rating} size="small" className="sm:hidden" />
          </div>
        </div>
        <div className="w-full text-right text-gray-500 pt-1 max-sm:text-sm">{_date.format("DD MMM 'YY")}</div>
      </div>
      <div className="w-full grid grid-flow-row md:grid-flow-col justify-start my-2">
        <div className="title--tiny mr-3">{review?.author}</div>
        <div className="w-full text-base sm:text-lg text-gray-500 font-thin">- {review?.functiongroup}</div>
      </div>
      <div className="w-full flex flex-row max-sm:mt-4">
        <Content className="!text-gray-500 line-clamp-3 hover:line-clamp-none leading-7">{parseContent(review?.description)}</Content>
      </div>

      {review?.comment && (
        <div className="lg:px-8 pt-10 pb-4">
          <button type="button" className="flex items-center justify-between gap-3 w-full" onClick={() => setCommentOpen(!commentOpen)}>
            <div className="flex items-center gap-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clipPath="url(#clip0_1204_8861)">
                  <path
                    d="M23.9992 11.2472C23.8546 8.90722 23.0283 6.66071 21.6224 4.78462C20.2164 2.90854 18.2922 1.48489 16.0869 0.689192C13.8816 -0.106505 11.4917 -0.239469 9.21174 0.306693C6.9318 0.852855 4.86152 2.05427 3.25613 3.76282C1.65074 5.47137 0.580416 7.61236 0.177117 9.92186C-0.226181 12.2314 0.055172 14.6084 0.986492 16.7599C1.91781 18.9114 3.45838 20.7434 5.41828 22.0299C7.37818 23.3165 9.67173 24.0014 12.0162 24.0002H18.9992C20.3249 23.9989 21.5959 23.4717 22.5332 22.5343C23.4706 21.5969 23.9979 20.3259 23.9992 19.0002V11.2472ZM21.9992 19.0002C21.9992 19.7958 21.6831 20.5589 21.1205 21.1215C20.5579 21.6841 19.7948 22.0002 18.9992 22.0002H12.0162C10.6051 21.9996 9.21002 21.7015 7.92187 21.1255C6.63373 20.5495 5.48145 19.7084 4.54018 18.6572C3.59435 17.6065 2.88411 16.3657 2.45707 15.018C2.03003 13.6703 1.89607 12.2469 2.06418 10.8432C2.32961 8.62914 3.32436 6.56639 4.89162 4.98012C6.45887 3.39386 8.50948 2.37431 10.7202 2.0822C11.1514 2.02815 11.5856 2.00076 12.0202 2.0002C14.3507 1.99384 16.609 2.8081 18.3992 4.3002C19.4447 5.16914 20.3029 6.24135 20.9217 7.45183C21.5405 8.6623 21.9071 9.98584 21.9992 11.3422V19.0002Z"
                    fill="#78BF00"
                  />
                  <path
                    d="M8 8.99981H12C12.2652 8.99981 12.5196 8.89446 12.7071 8.70692C12.8946 8.51938 13 8.26503 13 7.99981C13 7.7346 12.8946 7.48025 12.7071 7.29271C12.5196 7.10517 12.2652 6.99982 12 6.99982H8C7.73478 6.99982 7.48043 7.10517 7.29289 7.29271C7.10536 7.48025 7 7.7346 7 7.99981C7 8.26503 7.10536 8.51938 7.29289 8.70692C7.48043 8.89446 7.73478 8.99981 8 8.99981Z"
                    fill="#78BF00"
                  />
                  <path
                    d="M16 11.0002H8C7.73478 11.0002 7.48043 11.1055 7.29289 11.2931C7.10536 11.4806 7 11.735 7 12.0002C7 12.2654 7.10536 12.5198 7.29289 12.7073C7.48043 12.8948 7.73478 13.0002 8 13.0002H16C16.2652 13.0002 16.5196 12.8948 16.7071 12.7073C16.8946 12.5198 17 12.2654 17 12.0002C17 11.735 16.8946 11.4806 16.7071 11.2931C16.5196 11.1055 16.2652 11.0002 16 11.0002Z"
                    fill="#78BF00"
                  />
                  <path
                    d="M16 15H8C7.73478 15 7.48043 15.1054 7.29289 15.2929C7.10536 15.4804 7 15.7348 7 16C7 16.2652 7.10536 16.5196 7.29289 16.7071C7.48043 16.8947 7.73478 17 8 17H16C16.2652 17 16.5196 16.8947 16.7071 16.7071C16.8946 16.5196 17 16.2652 17 16C17 15.7348 16.8946 15.4804 16.7071 15.2929C16.5196 15.1054 16.2652 15 16 15Z"
                    fill="#78BF00"
                  />
                </g>
              </svg>
              <div className="title--light">Reactie van Profield</div>
            </div>
            <motion.svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              animate={commentOpen ? 'open' : 'closed'}
              variants={{
                open: { rotate: 180 },
                closed: { rotate: 0 }
              }}
            >
              <path
                d="M18.7108 8.21001C18.6178 8.11628 18.5072 8.04189 18.3854 7.99112C18.2635 7.94035 18.1328 7.91422 18.0008 7.91422C17.8688 7.91422 17.7381 7.94035 17.6162 7.99112C17.4944 8.04189 17.3838 8.11628 17.2908 8.21001L12.7108 12.79C12.6178 12.8837 12.5072 12.9581 12.3854 13.0089C12.2635 13.0597 12.1328 13.0858 12.0008 13.0858C11.8688 13.0858 11.7381 13.0597 11.6162 13.0089C11.4944 12.9581 11.3838 12.8837 11.2908 12.79L6.71079 8.21001C6.61783 8.11628 6.50723 8.04189 6.38537 7.99112C6.26351 7.94035 6.1328 7.91422 6.00079 7.91422C5.86878 7.91422 5.73808 7.94035 5.61622 7.99112C5.49436 8.04189 5.38376 8.11628 5.29079 8.21001C5.10454 8.39737 5 8.65083 5 8.91501C5 9.1792 5.10454 9.43265 5.29079 9.62001L9.88079 14.21C10.4433 14.7718 11.2058 15.0874 12.0008 15.0874C12.7958 15.0874 13.5583 14.7718 14.1208 14.21L18.7108 9.62001C18.897 9.43265 19.0016 9.1792 19.0016 8.91501C19.0016 8.65083 18.897 8.39737 18.7108 8.21001Z"
                fill="#1D1D1B"
              />
            </motion.svg>
          </button>
          <AnimatePresence>
            {commentOpen && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                className="overflow-hidden"
              >
                <Content className="mt-4 !text-gray-500 leading-7">{parseContent(review.comment)}</Content>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      )}
    </div>
  )
}

function parseContent(content: string) {
  return content.replace(/\n\n/g, '<br />')
}
