/* eslint-disable react-hooks/exhaustive-deps */
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import ValhallaSearch from '~/components/elements/ValhallaSearch'
import useGlobalCounters from '~/hooks/useGlobalCounters'
import CrossHair from '~/components/elements/CrossHair'
import ToggleVacancyAlert from '~/components/elements/ToggleVacancyAlert'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import useIsVisible from '~/hooks/useIsVisible'
import clsx from 'clsx'
import BreakTitle from '~/components/elements/BreakTitle'
import useIsLighthouse from '~/hooks/useIsLighthouse'

export default function BannerWithSearch({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const [ref, isVisible] = useIsVisible()
  const isLighthouse = useIsLighthouse()
  const globalCounter = useGlobalCounters()
  const [activeLeadingTitleIndex, setActiveLeadingTitleIndex] = useState(0)
  const subtitle = fields?.subtitle?.split(': ')
  const leadingTitles = subtitle[0].split(',')
  const trailingTitle = subtitle[1]

  useEffect(() => {
    if (isLighthouse) return

    const interval = setInterval(() => {
      setActiveLeadingTitleIndex((prevIndex) => {
        if (prevIndex >= leadingTitles.length - 1) {
          return 0
        } else {
          return prevIndex + 1
        }
      })
    }, 3000)
    return () => clearInterval(interval)
  }, [])

  return (
    <section
      data-component="BannerWithSearch"
      className="md:h-[100vh] relative header--margin--top--negative header--padding--top overflow-hidden min-h-[900px] max-h-[1300px]"
      ref={ref}
    >
      <div className="container relative z-20 h-full md:flex items-center">
        <div className="w-full">
          <div className="md:w-[60%] relative z-10 max-sm:pt-[150px] max-md:pt-[225px]">
            <h1 className="text-3xl font-semibold sm:title--semi-large text-white mb-4 sm:mb-8 xl:!leading-[4.5rem]">
              <BreakTitle title={fields?.title} />
            </h1>
            <h2 className="title--normal flex">
              <span className="text-site-accent max-sm:hidden flex">
                <div className="overflow-hidden flex">
                  <AnimatePresence mode="wait">
                    {leadingTitles
                      .filter((_, index) => index === activeLeadingTitleIndex)
                      .map((leadingTitle) => (
                        <motion.div
                          key={leadingTitle}
                          initial={{
                            y: isLighthouse ? 0 : '-100%'
                          }}
                          animate={{
                            y: '0%'
                          }}
                          exit={{
                            y: '100%'
                          }}
                        >
                          {leadingTitle}
                        </motion.div>
                      ))}
                  </AnimatePresence>
                </div>
                :
              </span>
              <span className="text-site-accent sm:text-white sm:pl-2">{trailingTitle}</span>
            </h2>
          </div>
          <div className="flex justify-center mt-8 md:mt-20 md:relative max-sm:-mx-6">
            <div className="md:hidden">
              <div className="absolute right-0 max-sm:left-0 max-sm:mx-auto sm:right-10 max-xxs:-top-[180px] max-sm:-top-[240px] max-md:-top-6 md:bottom-16 lg:bottom-0 pointer-events-none z-0">
                <CrossHair className="w-[170%] max-sm:-left-[35%] relative sm:w-[400px] lg:w-[600px] opacity-50" />
              </div>
              <div className="absolute right-0 max-sm:left-0 max-sm:mx-auto sm:right-10 max-sm:-top-28 max-md:-top-6 md:bottom-0 w-[400px] sm:w-[400px] lg:w-[600px] flex--center -z-10">
                <LossePlaatjie src={fields?.focusimage} maxwidth={250} className="w-[140px] relative max-md:top-36 max-lg:top-20" />
              </div>
            </div>
            <div className="max-md:w-full xl:w-[90%] relative z-10">
              <ValhallaSearch type="gradient" popularSearches={['Servicemonteur', 'Monteur technische dienst', 'Operator']} />
              <div className="md:mt-3 md:pl-8 flex justify-center md:justify-between items-center max-md:bg-site-grey section--bottom max-md:pb-10">
                <LosseLink
                  to="/vacatures/"
                  className="text-site-base md:text-white font-bold btn--pseudo--conversion btn--base py-[2px] px-3 border-transparent group !gap-0"
                >
                  Direct naar
                  <span className="text-site-accent group-hover:text-white">&nbsp;alle vacatures ({globalCounter.vacancies})</span>
                </LosseLink>
                <ToggleVacancyAlert className="max-md:hidden mr-6" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={clsx(
          'absolute w-full h-full left-0 top-0 z-10 pointer-events-none scale-100 transition-all md:duration-[2500ms] md:delay-[1500ms] origin-left',
          isVisible && 'scale-125'
        )}
      >
        <LossePlaatjie src={fields?.image} alt={fields?.title} className="w-full h-full object-cover" maxwidth={2000} />
        <div className="absolute z-10 left-0 top-0 w-full h-full bg-black/50" />
        <div className="max-md:hidden absolute left-1/2 z-20 header-animation-crosshair">
          <motion.div
            initial={{
              opacity: isLighthouse ? 1 : 0
            }}
            animate={{
              opacity: 1
            }}
            transition={{
              duration: 2.5,
              delay: 1.5
            }}
          >
            <LossePlaatjie src={fields?.focusimage} maxwidth={250} className="w-[150px]" />
          </motion.div>
          <div className="absolute left-1/2 -translate-x-1/2 lg:-top-1/2 -top-[150px] animate-delay-[5000ms] animate-fade">
            <CrossHair className="w-[350px] sm:w-[400px] lg:w-[550px] opacity-50" />
          </div>
        </div>
      </div>
    </section>
  )
}
