import { LosseLink, useLoaderData, losseContentParse } from '@ubo/losse-sjedel'
import CrossHair from '~/components/elements/CrossHair'
import Slider, { Slide } from '~/components/elements/Slider'
import type { Page_Flexcontent_Flex_Posts } from '~/graphql/types'
import useGlobalCounters from '~/hooks/useGlobalCounters'
import { type PageBodyData } from '~/templates/page'
import Vacancy from './post-types/Vacancy'
import useIsVisible from '~/hooks/useIsVisible'
import clsx from 'clsx'

export default function PostsVacanciesHighlighted({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const { page, rest, middleware } = useLoaderData<PageBodyData>()
  const [ref, isVisible] = useIsVisible()

  if (middleware?.highlightedVacancies?.status === 'failed' || middleware?.highlightedVacancies?.data?.hits?.total === 0) return null

  return (
    <section data-component="PostsVacanciesHighlighted" className="section" ref={ref}>
      <div className="bg-site-base">
        <div className="container section--large section--with-bg">
          <div className="grid grid-cols-1 lg:grid-cols-10 gap-10">
            <div className="lg:col-span-4 flex items-center order-2">
              <div>
                <h2 className="title--semi-large text-white">{fields?.extratitle}</h2>
                <div className="title--small text-site-accent">{fields?.extrasubtitle}</div>
                <div className="spacing--normal--top flex flex-wrap gap-3">
                  {fields?.items?.map((item, index) => {
                    const _function = rest.globalCounters.functions.find(
                      (f) => losseContentParse(f.name) === losseContentParse(item?.link?.title)
                    )

                    return (
                      <LosseLink
                        key={index}
                        to={`${item?.link?.url}${_function ? `?Functies=${_function.name}` : ''}`}
                        target={item?.link?.target}
                        className="btn--outline--white !py-1 !px-3 max-sm:w-full"
                      >
                        {item?.link?.title}
                        {_function ? ` (${_function?.count})` : ''}
                      </LosseLink>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className="lg:col-span-6 md:relative order-1 lg:order-3 max-sm:-mr-4">
              <CrossHair className="absolute h-20 -top-16 -left-16" />
              <h2 className="title--semi-large border-text--white">{fields?.title}</h2>
              <Sutitle title={fields?.subtitle} compareTitle={fields.functiongroupname || page.title} />
              <div className="spacing--normal--top">
                <Slider
                  breakpoints={{
                    1280: {
                      slidesPerView: 2.5
                    },
                    1024: {
                      slidesPerView: 2.25
                    },
                    768: {
                      slidesPerView: 2.75
                    },
                    640: {
                      slidesPerView: 2.25
                    },
                    420: {
                      slidesPerView: 1.25
                    },
                    0: {
                      slidesPerView: 1.25
                    }
                  }}
                  className="overflow-hidden sm:rounded-md"
                >
                  {middleware?.highlightedVacancies?.data?.hits?.hits?.map((item, index) => (
                    <Slide key={item._id} className="!h-auto pr-4">
                      <Vacancy
                        source={item._source}
                        theme="dark"
                        className={clsx('h-full', isVisible && 'animate-fade-up')}
                        style={{ animationDelay: `${index * 100 + 250}ms` }}
                      />
                    </Slide>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function Sutitle({ title, compareTitle }: { title: string; compareTitle: string }) {
  const globalCounters = useGlobalCounters()

  if (!title) return null

  const counter = globalCounters?.functiongroups?.find((node) => losseContentParse(node.name) === compareTitle)

  if (!counter) return null

  const _title = title.split('|')

  function replaceCounter(value: string) {
    return value?.replace('{count}', counter?.count?.toString())
  }

  return (
    <LosseLink
      to={`/vacatures/?Vakgebieden=${encodeURIComponent(compareTitle)}`}
      className="text-white font-bold spacing--small--top btn--pseudo--conversion rounded-full group px-2 py-[2px] -mx-2 inline-block"
    >
      {replaceCounter(_title[0])} <span className="text-site-accent group-hover:text-white">{replaceCounter(_title[1])}</span>
    </LosseLink>
  )
}
