/* eslint-disable no-script-url */
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import type { Expert } from '~/graphql/types'
import CopyButton from './CopyButton'

export default function Expert({ title, className, fields }: { title?: string; className?: string; fields: Expert }) {
  return (
    <div className={clsx('bg-site-accent px-4 py-6 grid xl:grid-cols-12 gap-4', className)}>
      <div className="xl:col-span-5">
        <LossePlaatjie
          src={fields?.featuredImage?.node}
          alt={fields?.title}
          maxwidth={100}
          className="aspect-[9/12] object-cover mx-auto block"
        />
      </div>
      <div className="xl:col-span-7">
        <h2 className="title--small">{title}</h2>

        <div className="mt-4 flex flex-col gap-2">
          <CopyButton
            className="btn--outline--black text-xs"
            value={<>Bel met {fields?.recap?.firstname}</>}
            hiddenValue={fields?.recap?.phone}
            mobileHiddenValue={`tel:${fields?.recap?.phone}`}
          />
          <LosseLink to="javascript:void(Tawk_API.toggle())" className="btn--outline--black text-xs">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <g clipPath="url(#clip0_737_1437)">
                <path
                  d="M15.5 10.5V15.5H10.5C9.62319 15.4991 8.76202 15.2676 8.00291 14.8288C7.24379 14.39 6.61341 13.7593 6.175 13C6.64617 12.9966 7.11573 12.9447 7.57625 12.845C7.92697 13.2835 8.37187 13.6375 8.87799 13.8807C9.38411 14.1239 9.93847 14.2502 10.5 14.25H14.25V10.5C14.2499 9.93827 14.1233 9.38378 13.8796 8.87765C13.636 8.37151 13.2815 7.92672 12.8425 7.57625C12.943 7.11582 12.9958 6.64626 13 6.175C13.7593 6.61341 14.39 7.24379 14.8288 8.00291C15.2676 8.76202 15.4991 9.62319 15.5 10.5ZM11.75 6.125C11.75 4.63316 11.1574 3.20242 10.1025 2.14752C9.04758 1.09263 7.61684 0.5 6.125 0.5C4.63316 0.5 3.20242 1.09263 2.14752 2.14752C1.09263 3.20242 0.5 4.63316 0.5 6.125L0.5 11.75H6.125C7.61633 11.7483 9.04611 11.1552 10.1006 10.1006C11.1552 9.04611 11.7483 7.61633 11.75 6.125ZM1.75 6.125C1.75 5.25971 2.00659 4.41385 2.48732 3.69438C2.96805 2.97492 3.65133 2.41416 4.45076 2.08303C5.25019 1.75189 6.12985 1.66525 6.97852 1.83406C7.82719 2.00287 8.60674 2.41955 9.21859 3.03141C9.83045 3.64326 10.2471 4.42281 10.4159 5.27148C10.5847 6.12015 10.4981 6.99981 10.167 7.79924C9.83584 8.59867 9.27508 9.28195 8.55562 9.76268C7.83615 10.2434 6.99029 10.5 6.125 10.5H1.75V6.125Z"
                  fill="white"
                />
              </g>
            </svg>
            Chat direct
          </LosseLink>
        </div>
      </div>
    </div>
  )
}
