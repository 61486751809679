/* eslint-disable react-hooks/exhaustive-deps */
import { LosseBlogBink, useSearchParams, type LosseBlogBinkReadableParams, useLosseBlogBink } from '@ubo/losse-sjedel'
import type {
  Page_Flexcontent_Flex_Posts,
  Post as PostProps,
  RootQueryToCategoryConnection,
  RootQueryToPostConnection
} from '~/graphql/types'
import Post from './post-types/Post'
import Content from '~/components/elements/Content'
import PostHighlighted from './post-types/PostHighlighted'
import clsx from 'clsx'
import { useDebounce } from 'usehooks-ts'
import { useEffect, useRef, useState } from 'react'
import NoResults from '~/components/elements/post-overview/NoResults'
import Pagination from '~/components/elements/post-overview/Pagination'

export const READABLE_PARAMS: LosseBlogBinkReadableParams = {
  filters: 'Onderwerpen',
  direction: 'Volgorde',
  sort: 'Sorteren',
  cursor: 'Pagina',
  query: 'Zoeken'
}

export const POSTS_LIMIT = 10

type PostsNews = Page_Flexcontent_Flex_Posts & {
  subdata: {
    Posts: RootQueryToPostConnection
    Categories: RootQueryToCategoryConnection
  }
}

export default function PostsNews({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  return (
    <LosseBlogBink readableParams={READABLE_PARAMS} useFormElement={false}>
      <section data-component="PostsNews" className="section">
        <div className="container">
          <Header fields={fields as PostsNews} />
        </div>
        <div className="container spacing--big--top">
          <Overview fields={fields as PostsNews} />
        </div>
      </section>
    </LosseBlogBink>
  )
}

function Header({ fields }: { fields: PostsNews }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const blogBink = useLosseBlogBink()
  const [query, setQuery] = useState('')
  const init = useRef(false)
  const debouncedValue = useDebounce<string>(query, 500)

  useEffect(() => {
    if (!init.current) {
      init.current = true
      return
    }

    if (!debouncedValue) {
      if (searchParams.get(READABLE_PARAMS.query)) {
        searchParams.delete(READABLE_PARAMS.query)
        setSearchParams(searchParams)
      }
      return
    }

    searchParams.set(READABLE_PARAMS.query, debouncedValue)
    setSearchParams(searchParams)
  }, [debouncedValue.toString()])

  return (
    <div className="grid lg:grid-cols-2 gap-6 sm:gap-10 lg:gap-16 xl:gap-20">
      <div>
        <h1 className="title--large">
          <span>{fields?.title?.split('&')[0]}</span>
          <span className="pl-20 border-text text-transparent block -mt-1 sm:-mt-4">& {fields?.title?.split('&')[1]}</span>
        </h1>
        <Content className="spacing--normal--top">{fields?.description}</Content>
      </div>
      <div>
        <div className="bg-site-grey-light p-4 sm:px-6 xl:px-10 sm:py-6">
          <label className="text-lg font-bold">Zoeken in artikelen</label>

          <div className="mt-2">
            <div className="bg-white rounded-full overflow-hidden relative w-full md:w-[80%]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="22"
                viewBox="0 0 21 22"
                fill="none"
                className="absolute left-5 top-1/2 -translate-y-1/2 w-5 h-5"
              >
                <g clipPath="url(#clip0_799_5320)">
                  <path
                    d="M20.7608 20.1062L15.6358 14.9813C16.9097 13.4494 17.6772 11.4823 17.6772 9.33887C17.6772 4.4651 13.7122 0.5 8.83865 0.5C3.96499 0.5 0 4.4651 0 9.33887C0 14.2123 3.96499 18.1771 8.83865 18.1771C10.9819 18.1771 12.9492 17.4097 14.4811 16.1358L19.6062 21.2608C19.7656 21.4203 19.9746 21.5 20.1835 21.5C20.3924 21.5 20.6015 21.4203 20.7609 21.2608C21.0798 20.942 21.0798 20.4251 20.7608 20.1062ZM1.63294 9.33887C1.63294 5.3655 4.86539 2.13294 8.83865 2.13294C12.8118 2.13294 16.0441 5.3655 16.0441 9.33887C16.0441 13.3119 12.8118 16.5441 8.83865 16.5441C4.86539 16.5441 1.63294 13.3119 1.63294 9.33887Z"
                    fill="#1D1D1B"
                  />
                </g>
              </svg>
              <input
                type="text"
                className="w-full h-10 pl-14 pr-4"
                placeholder="Bijv. Werkgeluk"
                onChange={(event) => setQuery(event.target.value)}
                defaultValue={searchParams.get(READABLE_PARAMS.query)}
              />
            </div>
          </div>
        </div>

        <div className="mt-6 md:spacing--normal--top sm:px-6 xl:px-10">
          <div className="title--small">Snel naar</div>

          <div className="mt-2 md:mt-4 flex flex-wrap gap-2 sm:gap-x-4 sm:gap-y-3">
            {fields?.subdata?.Categories?.nodes
              ?.sort((a, b) => {
                const sortingDatabaseIds = [90, 91]
                const aIndex = sortingDatabaseIds.indexOf(a.databaseId)
                const bIndex = sortingDatabaseIds.indexOf(b.databaseId)

                if (aIndex === bIndex) return 0

                if (aIndex === -1) return 1

                if (bIndex === -1) return -1

                return aIndex - bIndex
              })
              ?.filter((filter) => filter.databaseId !== 3 && filter.databaseId !== 2)
              ?.map((filter) => {
                const isActive = blogBink.isFilterActive(filter.databaseId)

                return (
                  <button
                    key={filter.databaseId}
                    type="button"
                    className={clsx(isActive ? 'btn--conversion' : 'btn--outline--black')}
                    onClick={() => {
                      blogBink.toggleFilter(filter.databaseId)
                    }}
                  >
                    {filter?.name}
                    {isActive && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="81"
                        height="79"
                        viewBox="0 0 81 79"
                        fill="none"
                        className="w-4 h-4 text-white"
                      >
                        <path d="M49.125 0H31.875V25.2361H49.125V0Z" fill="currentColor" />
                        <path d="M49.125 53.7637H31.875V78.9998H49.125V53.7637Z" fill="currentColor" />
                        <path d="M81 31.0879H55.125V47.912H81V31.0879Z" fill="currentColor" />
                        <path d="M25.875 31.0879H0V47.912H25.875V31.0879Z" fill="currentColor" />
                      </svg>
                    )}
                  </button>
                )
              })}
          </div>
        </div>
      </div>
    </div>
  )
}

function Overview({ fields }: { fields: PostsNews }) {
  const [searchParams] = useSearchParams()
  const page = searchParams.get(READABLE_PARAMS.cursor)
  const filters = searchParams.get(READABLE_PARAMS.filters)
  const search = searchParams.get(READABLE_PARAMS.query)
  const showHighlighted = !page && !filters && !search
  const posts = fields.subdata?.Posts?.edges as unknown as { node: PostProps }[]

  return (
    <>
      {posts.length > 0 && (
        <>
          {showHighlighted && (
            <>
              <h2 className="title--medium spacing--normal--bottom">Uitgelicht</h2>
              <div className="grid md:grid-cols-12 gap-6 lg:gap-10 spacing--small pb-16">
                {posts
                  ?.filter((_, index) => index <= 3)
                  ?.map((edge, index) => {
                    const compact = index === 1 || index === 2

                    return (
                      <div
                        key={index}
                        className={clsx(
                          !compact ? 'md:col-span-7' : 'md:col-span-5',
                          'h-[250px] sm:h-[300px] md:h-[400px] xl:h-[500px] animate-fade-up'
                        )}
                        style={{ animationDelay: `${index * 100 + 250}ms` }}
                      >
                        <PostHighlighted data={edge.node} type={compact ? 'compact' : 'normal'} />
                      </div>
                    )
                  })}
              </div>
              <h2 className="spacing--big--top title--medium spacing--normal--bottom">Meer artikelen</h2>
            </>
          )}

          <div className="grid lg:grid-cols-2 gap-4 md:gap-6 xl:gap-8 spacing--small--top">
            {posts
              ?.filter((_, index) => !showHighlighted || index > 3)
              ?.map((edge, index) => (
                <div key={index} className="animate-fade-up" style={{ animationDelay: `${index * 100 + 250}ms` }}>
                  <Post data={edge.node} />
                </div>
              ))}
          </div>
        </>
      )}

      {posts.length === 0 && <NoResults />}

      <div className="flex justify-center spacing--big--top">
        <Pagination total={fields.subdata?.Posts?.pageInfo?.offsetPagination?.total} limit={POSTS_LIMIT} />
      </div>
    </>
  )
}
