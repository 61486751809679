import { LosseLink } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import type { InterneVacature } from '~/graphql/types'
import valuta from '~/utils/valuta'

export default function InternalVacancy({ fields }: { fields: InterneVacature }) {
  return (
    <div className={clsx('block--shadow rounded-md p-4 sm:p-7 bg-white')}>
      <div className="flex flex-col gap-4 lg:pl-4">
        <div className="flex items-center justify-between ">
          <h3 className="text-3xl font-bold hover:underline">
            <LosseLink to={fields.uri}>{fields.title}</LosseLink>
          </h3>
          <Button className="btn--conversion sm:block hidden" to={fields.uri}>
            Bekijk vacature
          </Button>
        </div>

        <div className="flex flex-wrap flex-row gap-2">
          {fields?.recap?.salaryMin && (
            <div className="border border--fix text-xs border-site-base rounded-full px-4 py-1 text-site-base font-light">
              {fields?.recap?.salaryMin.includes('+') ? fields?.recap?.salaryMin : valuta(fields.recap.salaryMin)}
              {fields.recap.salaryMax ? ` - ${valuta(fields.recap.salaryMax)}` : ''}
              &nbsp;euro
            </div>
          )}
          <div className="border border--fix text-xs border-site-base rounded-full px-4 py-1 text-site-base font-light">
            {fields.recap.region}
          </div>
          {fields.recap.extraInfo && (
            <div className="border border--fix text-xs border-site-base rounded-full px-4 py-1 text-site-base font-light">
              {fields.recap.extraInfo}
            </div>
          )}
        </div>
      </div>
      <Button className="btn--conversion mt-3 text-center sm:hidden block" to={fields.uri}>
        Bekijk vacature
      </Button>
      <div className="bg-site-grey-light rounded-md pl-8 p-6 mt-3 sm:mt-5">
        <Content className="list--stripe--accent">{fields.recap.description}</Content>
      </div>
    </div>
  )
}
