import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Form, Vacature } from '~/graphql/types'
import type { LoaderData } from '@ubo/losse-sjedel'
import { useLoaderData } from '@ubo/losse-sjedel'
import FormApplyCustom from '~/components/elements/FormApplyCustom'
// import useIsVisible from '~/hooks/useIsVisible'
import clsx from 'clsx'
import { useUtmData } from '~/hooks/utm'

export default function FormApply({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  // const [ref, isVisible] = useIsVisible()
  const [isLoaded, utmData] = useUtmData()
  const data = useLoaderData<LoaderData<Vacature>>()

  const url = new URL(data.request.url.replace(':80', ''))
  const getRedirectUrl = (name: string) =>
    `${url.protocol + '//' + url.host + url.pathname + (url.pathname.endsWith('/') ? '' : '/')}bedankt?Naam=${name}`

  if (!isLoaded) {
    return null
  }

  return (
    <section
      data-component="FormApply"
      className="section section--with-bg min-h-[110vh] flex flex-col justify-center relative z-40 bg-site-base"
      id={fields.sectionId}
    >
      <div className="container">
        <h2 className="xl:title--unit title--semi-unit text-center text-transparent border-text--white">{fields.title}</h2>

        <div className="xl:grid flex flex-col grid-cols-2 xl:gap-20 gap-10 xl:-mt-2 mt-8">
          <div
            className={clsx(
              'sm:bg-white sm:p-8',
              isLoaded && 'animate-fade-right animate-ease-in-out',
              isLoaded ? 'opacity-100' : 'opacity-0'
            )}
            // ref={ref}
          >
            <h3 className="title--normal text-site-accent mb-4">{fields.formtitle}</h3>
            <Content className="mb-4 sm:content--light content--dark" theme="none">
              {fields.formdescription}
            </Content>
            <FormApplyCustom
              theme="grey"
              data={fields.form}
              defaultFields={{
                input_8: data.page.databaseId.toString(),
                input_9: utmData.source,
                input_10: utmData.medium,
                input_11: utmData.content
              }}
              onSubmitDone={async (values) => {
                window.location.href = getRedirectUrl(values.input_1)
              }}
              scrollToConfirm={false}
            />
          </div>

          <div
            className={clsx(
              'xl:pt-32 xl:pb-20',
              isLoaded && 'animate-fade-up animate-ease-in-out animate-delay-200',
              isLoaded ? 'opacity-100' : 'opacity-0'
            )}
          >
            <h3 className="text-white title--normal mb-6">{fields.subtitle}</h3>
            <Content className="list--crosshair content--dark children-a:text-site-accent">{fields.description}</Content>
          </div>
        </div>
      </div>
    </section>
  )
}
