import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import TitleWithGreenBlock from '~/components/elements/TitleWithGreenBlock'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentGrid({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const bigBlockWrapping = [true, false, false, false]

  return (
    <section data-component="ContentGrid" className="section" id={fields.sectionId}>
      <div className="container lg:grid grid-cols-2 flex flex-col gap-8">
        <div className="relative col-span-2">
          <TitleWithGreenBlock title={fields?.title} className="my-6 lg:mb-20" />
        </div>

        {fields.items.map((item, index) =>
          bigBlockWrapping[index % bigBlockWrapping.length] ? (
            <BigGridItem key={item.title} item={item} />
          ) : (
            <SmallGridItem key={item.title} item={item} />
          )
        )}
      </div>
    </section>
  )
}

function BigGridItem({ item }: { item: Page_Flexcontent_Flex_Content['items'][0] }) {
  return (
    <div className="bg-site-base text-white sm:p-8 p-6 lg:row-span-2 lg:col-span-1 col-span-2 flex flex-col">
      <h3
        className="sm:title--medium font-bold text-2xl mb-8 !leading-tight max-w-[400px]"
        dangerouslySetInnerHTML={{ __html: item.title }}
      />

      <div className="sm:grid flex flex-col grid-cols-2 gap-6 sm:items-end h-full">
        {item.link && (
          <div>
            <LosseLink src={item.link} className="btn--accent text-center" />
          </div>
        )}

        {item.image && (
          <div className="flex justify-end">
            <LossePlaatjie src={item.image} className="sm:h-auto w-[100px] sm:w-[125px] lg:w-[175px] object-contain " />
          </div>
        )}
      </div>
    </div>
  )
}

function SmallGridItem({ item }: { item: Page_Flexcontent_Flex_Content['items'][0] }) {
  return (
    <div className="bg-site-base text-white p-6 flex flex-row items-center sm:gap-10 gap-6">
      {item.image && (
        <LossePlaatjie
          src={item.image}
          className="sm:w-[125px] sm:h-[125px] xs:h-[100px] xs:w-[100px] h-[75px] w-[75px] max-w-none object-contain flex-none"
        />
      )}

      <div className="flex flex-col gap-y-4 items-start w-full">
        {item.title && <h3 className="sm:title--small font-bold" dangerouslySetInnerHTML={{ __html: item.title }} />}
        {item.link && <LosseLink src={item.link} className="btn--accent" />}
      </div>
    </div>
  )
}
