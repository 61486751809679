import { useSearchParams } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import ReactPaginate from 'react-paginate'

export default function Pagination({ total, limit, className }: { total: number; limit: number; className?: string }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const pages = total / limit

  if (pages < 1) return null

  return (
    <ReactPaginate
      onPageChange={({ selected }) => {
        const page = selected + 1

        if (page === 1) {
          searchParams.delete('Pagina')
        } else {
          searchParams.set('Pagina', (selected + 1).toString())
        }

        setSearchParams(searchParams)
      }}
      marginPagesDisplayed={1}
      pageRangeDisplayed={2}
      pageCount={Math.ceil(total / limit)}
      forcePage={searchParams.get('Pagina') ? Number(searchParams.get('Pagina')) - 1 : 0}
      nextLabel="Volgende"
      previousLabel="Vorige"
      className={clsx('flex--center', className)}
      pageLinkClassName="pagination--item"
      activeClassName="children-a:bg-site-base children-a:text-white children-a:border-site-base children-a:font-bold"
      nextClassName="pagination--button"
      previousClassName="pagination--button"
      breakClassName="pagination--item"
      renderOnZeroPageCount={null}
    />
  )
}
