/* eslint-disable react-hooks/exhaustive-deps */
import type { LoaderData } from '@ubo/losse-sjedel'
import { LossePlaatjie, useLoaderData } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useInView } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import Content from '~/components/elements/Content'
import CrossHair from '~/components/elements/CrossHair'
import Links from '~/components/elements/Links'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import useIsLastComponent from '~/hooks/useIsLastComponent'
import useIsMobile from '~/hooks/useIsMobile'
import useIsVisible from '~/hooks/useIsVisible'

let preventWheel = false

export default function ContentMatchOnboarding({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const data = useLoaderData<LoaderData>()
  const isLastComponent = useIsLastComponent(fields)
  const [sectionRef, isVisible] = useIsVisible()
  const scrollRef = useRef<HTMLDivElement>(null)
  const triggerRef = useRef<HTMLDivElement>(null)
  const init = useRef(false)
  const isMobile = useIsMobile('(max-width: 767px)')
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>('down')
  const [isScrolling, setIsScrolling] = useState(false)
  const inView = useInView(triggerRef, { amount: 'all' })
  const titleSplit = fields?.title?.split('&')
  const hasTitle = !!titleSplit

  useEffect(() => {
    document.body.style.overflow = 'auto'

    const timeout = setTimeout(() => {
      init.current = true
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  useEffect(() => {
    if (isMobile || !init.current) return

    const onWheel = (event: WheelEvent) => {
      const deltaY = event.deltaY

      if (deltaY < 0) {
        setScrollDirection('up')
      } else {
        setScrollDirection('down')
      }

      if (preventWheel) {
        event.preventDefault()
        return
      }

      const sectionPosition = {
        top: sectionRef.current.offsetTop,
        bottom: sectionRef.current.offsetTop + sectionRef.current.offsetHeight
      }

      const scrollPosition = {
        top: window.scrollY,
        bottom: window.scrollY + window.innerHeight
      }

      if (sectionPosition.top <= scrollPosition.bottom && sectionPosition.bottom >= scrollPosition.top) {
        if (document.body.style.overflow !== 'hidden') return

        if (deltaY < 0 && scrollRef.current?.scrollLeft === 0) {
          document.body.style.overflow = 'auto'
          return
        }

        if (
          deltaY > 0 &&
          Math.ceil(scrollRef.current?.scrollLeft) >= scrollRef.current?.scrollWidth - (scrollRef.current?.offsetWidth + 1)
        ) {
          document.body.style.overflow = 'auto'
          return
        }

        scrollRef.current?.scrollTo({
          left: scrollRef.current?.scrollLeft + deltaY
        })
      }
    }

    const onScroll = () => {
      if (scrollRef.current?.scrollLeft >= 250) {
        setIsScrolling(true)
      } else {
        setIsScrolling(false)
      }
    }

    scrollRef.current?.addEventListener('scroll', onScroll)
    document.addEventListener('wheel', onWheel, { passive: false })

    return () => {
      document.removeEventListener('wheel', onWheel)
      scrollRef.current?.removeEventListener('scroll', onScroll)
    }
  }, [init.current])

  useEffect(() => {
    if (!init.current) return

    if (inView && !isMobile) {
      preventWheel = true

      window.scrollTo({
        top: scrollRef.current?.getBoundingClientRect().top + window.scrollY,
        behavior: 'smooth'
      })

      setTimeout(() => {
        document.body.style.overflow = 'hidden'
        preventWheel = false
      }, 50)
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [inView, init.current])

  function scrollToNext() {
    scrollRef.current?.scrollTo({
      left: scrollRef.current?.querySelector('[data-id="match-onboarding-1"]')?.getBoundingClientRect().left,
      behavior: 'smooth'
    })
  }

  const type = fields.typeOnboarding || 'double'
  const isMatchOnboardingPage = data.page.databaseId === 419

  return (
    <section
      data-component="ContentMatchOnboarding"
      id={fields.sectionId}
      className={clsx(
        (type === 'double' || type === 'single_first') && !isLastComponent ? 'section' : 'section--mt',
        (type === 'double' || type === 'single_first') && 'bg-site-base',
        type === 'single_last' && 'bg-site-grey-alternative'
      )}
    >
      <Text
        className="md:hidden container"
        fields={fields}
        hasTitle={hasTitle}
        isVisible={isVisible}
        titleSplit={titleSplit}
        scrollToNext={scrollToNext}
        type={type}
        isMatchOnboardingPage={isMatchOnboardingPage}
      />
      <div className={clsx('relative z-20', !hasTitle && 'relative')} ref={sectionRef}>
        {type === 'double' && (
          <div className={clsx('absolute top-0 left-0 w-full z-30 transition-opacity match-onboarding-next', isScrolling && 'opacity-0')}>
            <div className="container flex justify-end pt-20">
              <button type="button" onClick={scrollToNext}>
                <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53" fill="none">
                  <g clipPath="url(#clip0_2149_4363)">
                    <path
                      d="M42.1545 40.693L50.8089 32.0385C52.2152 30.5664 53 28.6088 53 26.5729C53 24.537 52.2152 22.5794 50.8089 21.1073L42.1545 12.4528C41.5297 11.8494 40.693 11.5155 39.8244 11.5231C38.9559 11.5306 38.1251 11.879 37.5109 12.4932C36.8968 13.1073 36.5484 13.9381 36.5409 14.8067C36.5333 15.6752 36.8672 16.5119 37.4706 17.1367L43.6097 23.2758L3.30771 23.3266C2.42918 23.3266 1.58664 23.6756 0.965423 24.2968C0.344207 24.9181 -0.00478807 25.7606 -0.00478811 26.6391C-0.00478814 27.5177 0.344207 28.3602 0.965423 28.9814C1.58663 29.6026 2.42918 29.9516 3.30771 29.9516L43.5788 29.9008L37.4706 36.0091C37.1542 36.3147 36.9019 36.6802 36.7282 37.0843C36.5546 37.4885 36.4633 37.9231 36.4594 38.363C36.4556 38.8028 36.5394 39.239 36.706 39.6461C36.8725 40.0532 37.1185 40.423 37.4295 40.734C37.7405 41.045 38.1104 41.291 38.5175 41.4576C38.9246 41.6241 39.3608 41.7079 39.8006 41.7041C40.2404 41.7003 40.6751 41.6089 41.0792 41.4353C41.4834 41.2617 41.8489 41.0093 42.1545 40.693Z"
                      fill="#EAEAEB"
                    />
                  </g>
                </svg>
              </button>
            </div>
          </div>
        )}
        <div
          className={clsx('absolute', scrollDirection === 'down' && 'bottom-20', scrollDirection === 'up' && 'top-20')}
          ref={triggerRef}
        />
        <div className="md:hidden absolute bottom-16 z-30 w-full text-center">
          <button type="button" onClick={scrollToNext}>
            <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53" fill="none">
              <g clipPath="url(#clip0_2149_4363)">
                <path
                  d="M42.1545 40.693L50.8089 32.0385C52.2152 30.5664 53 28.6088 53 26.5729C53 24.537 52.2152 22.5794 50.8089 21.1073L42.1545 12.4528C41.5297 11.8494 40.693 11.5155 39.8244 11.5231C38.9559 11.5306 38.1251 11.879 37.5109 12.4932C36.8968 13.1073 36.5484 13.9381 36.5409 14.8067C36.5333 15.6752 36.8672 16.5119 37.4706 17.1367L43.6097 23.2758L3.30771 23.3266C2.42918 23.3266 1.58664 23.6756 0.965423 24.2968C0.344207 24.9181 -0.00478807 25.7606 -0.00478811 26.6391C-0.00478814 27.5177 0.344207 28.3602 0.965423 28.9814C1.58663 29.6026 2.42918 29.9516 3.30771 29.9516L43.5788 29.9008L37.4706 36.0091C37.1542 36.3147 36.9019 36.6802 36.7282 37.0843C36.5546 37.4885 36.4633 37.9231 36.4594 38.363C36.4556 38.8028 36.5394 39.239 36.706 39.6461C36.8725 40.0532 37.1185 40.423 37.4295 40.734C37.7405 41.045 38.1104 41.291 38.5175 41.4576C38.9246 41.6241 39.3608 41.7079 39.8006 41.7041C40.2404 41.7003 40.6751 41.6089 41.0792 41.4353C41.4834 41.2617 41.8489 41.0093 42.1545 40.693Z"
                  fill="#EAEAEB"
                />
              </g>
            </svg>
          </button>
        </div>
        <div
          className={clsx(
            'mx-auto overflow-x-scroll no-scrollbar md:min-h-screen-dynamic relative z-20',
            (type === 'double' || type === 'single_first') && 'bg-site-base',
            type === 'single_last' && 'bg-site-grey-alternative'
          )}
          ref={scrollRef}
        >
          <div className="md:container md:pt-[100%] md:-mt-[100%] relative h-full">
            <div className="md:h-fit md:absolute left-0 right-0 md:w-fit md:-mr-[100%]">
              <div className="md:flex items-start w-full h-full">
                <Text
                  className="max-md:hidden"
                  fields={fields}
                  hasTitle={hasTitle}
                  isVisible={isVisible}
                  titleSplit={titleSplit}
                  scrollToNext={scrollToNext}
                  type={type}
                  isMatchOnboardingPage={isMatchOnboardingPage}
                />
                {/*
                width: 2147
                height: 812
                = 2,644088669950739
                lg: 1719 x 650
                sm: 1454 x 550

                width: 2028
                height: 812
                = 2,497536945812808
                lg: 1623 x 650
                sm: 1374 x 550

                + 200 space
                lg: 125
                sm: 100
              */}
                <div
                  className={clsx(
                    'flex max-md:pl-10 lg:-ml-32 overflow-y-hidden min-h-screen-dynamic',
                    type === 'double' && 'w-[2928px] sm:w-[3467px] lg:w-[4375px]',
                    type === 'single_first' && 'w-[1554px] sm:w-[1844px] lg:w-[2347px]',
                    type === 'single_last' && 'w-[1474px] sm:w-[1748px] lg:w-[2228px]'
                  )}
                >
                  {fields?.items?.map((item, index) => (
                    <div
                      key={index}
                      className={clsx(
                        index === 0 && type !== 'single_last' && 'w-[1454px] sm:w-[1719px] lg:w-[2147px]',
                        (index === 1 || type === 'single_last') && 'w-[1474px] sm:w-[1748px] lg:w-[2228px] bg-site-grey-alternative',
                        'relative'
                      )}
                      data-id={`match-onboarding-${index}`}
                    >
                      {index === 0 && type === 'double' && (
                        <div className="absolute -right-0 match-onboarding-crosshair translate-x-1/2 z-10">
                          <div className="flex justify-center">
                            <CrossHair className="lg:w-[100px] lg:h-[100px] w-[80px] h-[80px]" />
                          </div>
                          <h3 className="title--normal text-white mt-6 lg:mt-8 text-center">
                            Gefeliciteerd met
                            <br />
                            je nieuwe baan!
                          </h3>
                        </div>
                      )}
                      {index === 1 && hasTitle && type === 'double' && (
                        <h2 className="match-onboarding-title absolute top-20 left-20 z-10">
                          <span className="border-text--white text-transparent">{titleSplit[0]} &</span>
                          <span className="text-white">
                            <br />
                            <span className="flex items-start lg:gap-10 gap-6">
                              {type === 'double' && (
                                <button
                                  type="button"
                                  onClick={() => {
                                    scrollRef.current?.scrollTo({
                                      left: 0,
                                      behavior: 'smooth'
                                    })
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="100"
                                    height="60"
                                    viewBox="0 0 100 60"
                                    fill="none"
                                    className="relative xl:top-6 xl:w-[100px] xl:h-[60px] top-4 lg:w-[80px] lg:h-[48px] w-[60px] h-[32px]"
                                  >
                                    <rect width="100" height="60" rx="30" fill="white" />
                                    <circle cx="67.5" cy="30" r="22.5" fill="#78BF00" />
                                  </svg>
                                </button>
                              )}
                              {titleSplit[1]}
                            </span>
                          </span>
                        </h2>
                      )}
                      <LossePlaatjie
                        className={clsx(
                          index === 0 && type === 'double' && 'w-[1454px] sm:w-[1719px] lg:w-[2147px]',
                          index === 1 && type === 'double' && 'w-[1374px] sm:w-[1623px] lg:w-[2028px]',
                          type === 'single_first' && 'w-[1454px] sm:w-[1719px] lg:w-[2147px]',
                          type === 'single_last' && 'w-[1374px] sm:w-[1623px] lg:w-[2028px]',
                          'object-contain h-[550px] sm:h-[650px] lg:h-[812px] relative match-onboarding-image'
                        )}
                        src={item?.image}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function Text({
  className,
  fields,
  hasTitle,
  isVisible,
  titleSplit,
  scrollToNext,
  type,
  isMatchOnboardingPage
}: {
  className?: string
  fields: Page_Flexcontent_Flex_Content
  hasTitle: boolean
  isVisible: boolean
  titleSplit: string[]
  scrollToNext: () => void
  type: string
  isMatchOnboardingPage: boolean
}) {
  const hasContent = fields?.subtitle || fields?.description || fields?.links?.length

  return (
    <div className={clsx('relative z-20', className)}>
      <div className={clsx(hasContent && 'pt-20 pb-10 md:py-28 xl:pt-32 xl:pb-36', !hasContent && 'md:py-28 xl:pt-32 xl:pb-36')}>
        {hasTitle && (type === 'double' || type === 'single_first') && (
          <h2
            className={clsx(
              'match-onboarding-title',
              isVisible && 'animate-fade-right animate-once block',
              isVisible ? 'opacity-100' : 'opacity-0'
            )}
          >
            <span className="text-white">{titleSplit[0]}</span>
            <span className="border-text--white">
              &<br />
              <span className={clsx('flex items-start lg:gap-10 gap-6', type !== 'double' && 'ml-20')}>
                {type === 'double' && (
                  <button type="button" onClick={scrollToNext}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="100"
                      height="60"
                      viewBox="0 0 100 60"
                      fill="none"
                      className="relative xl:top-6 xl:w-[100px] xl:h-[60px] top-4 lg:w-[80px] lg:h-[48px] w-[60px] h-[32px]"
                    >
                      <rect width="100" height="60" rx="30" fill="white" />
                      <circle cx="30" cy="30" r="22.5" fill="#A4A4A4" />
                    </svg>
                  </button>
                )}
                {titleSplit[1]}
              </span>
            </span>
          </h2>
        )}
        {hasTitle && type === 'single_last' && (
          <h2 className="match-onboarding-title">
            <span className="border-text--white text-transparent">{titleSplit[0]} &</span>
            <span className="text-white">
              <br />
              <span className="flex items-start lg:gap-10 gap-6 ml-20">{titleSplit[1]}</span>
            </span>
          </h2>
        )}
        <div
          className={clsx(
            'max-w-[550px]',
            isVisible && 'animate-fade-up animate-once md:animate-delay-500 block',
            isVisible ? 'opacity-100' : 'opacity-0'
          )}
        >
          {fields?.subtitle && <h3 className="md:title--normal title--semi-large text-site-accent">{fields?.subtitle}</h3>}
          {fields?.description && (
            <Content theme="dark" className="mt-4">
              {fields?.description}
            </Content>
          )}
          {!isMatchOnboardingPage && <Links className="mt-10" items={fields?.links} />}
        </div>
      </div>
    </div>
  )
}
