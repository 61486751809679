import clsx from 'clsx'
import { Fragment } from 'react'
import useIsVisible from '~/hooks/useIsVisible'

interface Props extends React.HTMLAttributes<HTMLHeadingElement> {
  title: string
}

export default function TitleWithGreenBlock({ title, className, ...rest }: Props) {
  const [ref, isVisible] = useIsVisible()

  return (
    <h2 className={clsx('title--large relative', className)} ref={ref} {...rest}>
      <span
        className={clsx(
          isVisible && 'animate-fade-right animate-once animate-delay-300 block',
          isVisible ? 'opacity-100' : 'opacity-0',
          'absolute w-[125px] sm:w-[150px] h-[80px] lg:w-[150px] lg:h-[125px] xl:w-[200px] xl:h-[140px] top-[20px] sm:top-[35px] -left-4 lg:top-[50px] bg-site-accent -z-[1]'
        )}
      />
      {title?.split(' | ')?.map((row, key) => {
        return (
          <Fragment key={key}>
            {key > 0 && <br />}
            <span className={clsx(key === 0 ? 'md:pr-6' : 'ml-16 sm:ml-20 lg:ml-32', 'leading-[1]')}>{row}</span>
          </Fragment>
        )
      })}
    </h2>
  )
}
