/* eslint-disable react-hooks/exhaustive-deps */
import type { SearchTotalHits } from '@elastic/elasticsearch/lib/api/types'
import { useLoaderData, useSearchParams } from '@ubo/losse-sjedel'
import { Fragment, useEffect, useRef } from 'react'
import Checkboxlist from '~/components/elements/post-overview/CheckboxList'
import type { Page_Flexcontent_Flex_Posts } from '~/graphql/types'
import type { SearchValueAggregation, SearchBucketAggregation } from '~/services/elastic.server'
import type { PageBodyData } from '~/templates/page'
import Vacancy from './post-types/Vacancy'
import RangeSlider from '~/components/elements/post-overview/RangeSlider'
import CallToAction from '~/components/elements/post-overview/CallToAction'
import { SomethingWentWrong } from '~/components/elements/post-overview/SomethingWentWrong'
import ClearFilters from '~/components/elements/post-overview/ClearFilters'
import Expert from '~/components/elements/Expert'
import { useInView } from 'framer-motion'
import clsx from 'clsx'
import VacancyAlert from '~/components/elements/post-overview/VacancyAlert'
import Overview from '~/components/elements/post-overview/Overview'
import ValhallaSearch from '~/components/elements/ValhallaSearch'
import ToggleVacancyAlert from '~/components/elements/ToggleVacancyAlert'
import { gaSendViewItemList } from '~/utils/ga'

export default function PostsVacancies({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const containerRef = useRef(null)
  const containerIsVisible = useInView(containerRef, { once: true })
  const [searchParams] = useSearchParams()
  const { middleware } = useLoaderData<PageBodyData>()

  useEffect(() => {
    gaSendViewItemList(middleware.vacancies.data.hits.hits.map((hit) => hit._source))
  }, [middleware?.vacancies?.data])

  if (middleware?.vacancies.status === 'failed') {
    return <SomethingWentWrong />
  }

  const total = getTotal(middleware?.vacancies?.data?.hits?.total)

  return (
    <section
      data-component="PostsVacancies"
      className={clsx('header--margin--top--negative', containerIsVisible ? 'opacity-100' : 'opacity-0')}
      ref={containerRef}
    >
      <div className="bg-site-base header--padding--top">
        <div className="container pt-12 pb-[270px] sm:pb-80 md:pb-32 animate-fade-up">
          <h1 className="text-white title--semi-large">{fields?.title}</h1>
        </div>
      </div>

      <div className="container -mt-60 md:-mt-[66px] flex gap-6 relative z-10">
        <ValhallaSearch className="mb-10" />
        <div className="flex justify-center items-start text-site-base pt-5 max-xl:hidden min-w-[230px]">
          <ToggleVacancyAlert />
        </div>
      </div>

      <Overview
        filtersChildren={
          <>
            <Expert title={fields?.relationtitle} fields={fields?.relation} className="max-lg:hidden mb-12" />
            <div className="flex items-center justify-between mb-6 lg:mb-12">
              <h2 className="max-lg:text-3xl title--small">Filters</h2>
              <ClearFilters sessionName="vacancies-filters" filters={['Vakgebieden', 'Functies', 'Salaris', 'Werkrooster']} />
            </div>
            <div>
              {/* If vakgebied is selected using ValhallaSearch then don't show the checkboxlist */}
              {!searchParams.get('Vakgebied') && (
                <Checkboxlist
                  field="Vakgebieden"
                  aggregation={middleware?.vacancies.data.aggregations.functionGroup as SearchBucketAggregation}
                  className="animate-delay-[250ms]"
                />
              )}
              {/* If functie is selected using ValhallaSearch then don't show the checkboxlist */}
              {!searchParams.get('Functie') && (
                <Checkboxlist
                  field="Functies"
                  aggregation={middleware?.vacancies.data.aggregations.function as SearchBucketAggregation}
                  className="animate-delay-[350ms]"
                />
              )}
              <Checkboxlist
                field="Werkrooster"
                aggregation={middleware?.vacancies.data.aggregations.schedule as SearchBucketAggregation}
                className="animate-delay-[450ms]"
              />
              <RangeSlider
                field="Salaris"
                aggregationFrom={middleware?.vacancies.data.aggregations.salaryMin as SearchValueAggregation}
                aggregationTo={middleware?.vacancies.data.aggregations.salaryMax as SearchValueAggregation}
                className="lg:w-[75%] animate-delay-[550ms]"
              />
            </div>
            <VacancyAlert className="mt-12 max-lg:hidden" />
          </>
        }
        sortOptions={['Laatst toegevoegd', 'Eerst toegevoegd']}
        total={total}
        title={`vacature${total !== 1 ? 's' : ''}`}
        hits={middleware?.vacancies?.data?.hits?.hits?.length}
        limit={middleware?.vacancies?.limit}
      >
        <div className="grid sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 mt-6 gap-6">
          {middleware?.vacancies?.data?.hits?.hits?.map((hit, index) => (
            <Fragment key={hit._id}>
              <Vacancy
                source={hit._source}
                className="col-span-1 animate-fade-up"
                style={{
                  animationDelay: `${index * 100 + 250}ms`
                }}
              />
              {index === 6 && <CallToAction fields={fields?.cta} className="col-span-1" />}
            </Fragment>
          ))}
        </div>
      </Overview>
    </section>
  )
}

export function getTotal(hits: SearchTotalHits | number): number {
  if (typeof hits === 'number') return hits
  return hits?.value
}
