import { LosseLink, losseContentParse, useLoaderData } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import type {
  Maybe,
  Page_Flexcontent_Flex_Banner_Links,
  Page_Flexcontent_Flex_Content_Links,
  Page_Flexcontent_Flex_Form_Links,
  Component_Header_MenuHeader_Submenu_MainItems_Items,
  Page_Flexcontent_Flex_Posts_Links
} from '~/graphql/types'
import { type PageBodyData } from '~/templates/page'

const LINK_CLASSES: { [key: string]: string } = {
  main: 'btn--accent',
  main_outline: 'btn--outline--accent',
  conversion: 'btn--conversion',
  conversion_outline: 'btn--outline--conversion',
  white: 'btn--white',
  white_outline: 'btn--outline--white',
  black_outline: 'btn--outline--black'
}

const ICON_FILL_CLASSES: { [key: string]: string } = {
  main: 'fill-white',
  main_outline: 'fill-accent',
  conversion: 'fill-white',
  conversion_outline: 'fill-conversion',
  white: 'fill-white',
  white_outline: 'fill-white',
  black_outline: 'fill-black'
}

export default function Links({
  items,
  className,
  theme,
  icons,
  microcopyPlacement
}: {
  items:
    | Maybe<Page_Flexcontent_Flex_Content_Links>[]
    | Maybe<Page_Flexcontent_Flex_Banner_Links>[]
    | Maybe<Page_Flexcontent_Flex_Form_Links>[]
    | Maybe<Page_Flexcontent_Flex_Posts_Links>[]
  className?: string
  theme?: 'light' | 'dark' | 'accent'
  icons?: 'arrow'
  microcopyPlacement?: 'right' | 'bottom'
}) {
  const page = useLoaderData<PageBodyData>()

  if (!items || items.length === 0) return null

  const _items = replaceWithCounter(page as PageBodyData, items)

  return (
    <div className={clsx('flex items-center gap-2 lg:gap-4', className)}>
      {_items.map((link, index) => (
        <div
          key={index}
          className={clsx(
            link?.microcopy && microcopyPlacement === 'right' && 'sm:flex--center',
            link?.microcopy && microcopyPlacement === 'bottom' && 'flex flex-col gap-2',
            !link.microcopy && 'flex--center',
            'max-sm:w-full'
          )}
        >
          <LosseLink
            className={clsx(LINK_CLASSES[link?.buttonstyle || 'main'], 'max-sm:w-full')}
            target={link?.link.target}
            to={link?.link.url}
          >
            {link?.link.title.replace(' vacatures)', ')')}
            {icons === 'arrow' && (
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g id="fi-rr-arrow-small-right">
                  <path
                    id="Vector"
                    d="M18 12.0001C17.9951 11.474 17.7832 10.971 17.41 10.6001L13.12 6.30007C12.9326 6.11382 12.6792 6.00928 12.415 6.00928C12.1508 6.00928 11.8974 6.11382 11.71 6.30007C11.6163 6.39303 11.5419 6.50363 11.4911 6.62549C11.4403 6.74735 11.4142 6.87806 11.4142 7.01007C11.4142 7.14208 11.4403 7.27279 11.4911 7.39465C11.5419 7.5165 11.6163 7.62711 11.71 7.72007L15 11.0001H5C4.73478 11.0001 4.48043 11.1054 4.29289 11.293C4.10536 11.4805 4 11.7348 4 12.0001C4 12.2653 4.10536 12.5196 4.29289 12.7072C4.48043 12.8947 4.73478 13.0001 5 13.0001H15L11.71 16.2901C11.5217 16.477 11.4154 16.7312 11.4144 16.9965C11.4135 17.2619 11.518 17.5168 11.705 17.7051C11.892 17.8934 12.1461 17.9997 12.4115 18.0006C12.6768 18.0016 12.9317 17.897 13.12 17.7101L17.41 13.4101C17.7856 13.0367 17.9978 12.5296 18 12.0001Z"
                    className={ICON_FILL_CLASSES[link?.buttonstyle || 'main']}
                  />
                </g>
              </svg>
            )}
          </LosseLink>
          {link?.microcopy && (
            <span
              className={clsx(
                theme === 'dark' ? 'text-white font-light text-base sm:ml-6' : 'font-bold text-xs sm:ml-2 lg:ml-4',
                'max-sm:mt-2 max-sm:block max-sm:text-center max-sm:text-xs',
                microcopyPlacement && 'text-center !ml-0'
              )}
            >
              {link.microcopy}
            </span>
          )}
        </div>
      ))}
    </div>
  )
}

// Looks if there is a replacable {count} in the link title, then replaces it with the global counter corresponding with the searchparams in referenced link url
export function replaceWithCounter(
  page: PageBodyData,
  items:
    | Maybe<Page_Flexcontent_Flex_Content_Links>[]
    | Maybe<Page_Flexcontent_Flex_Banner_Links>[]
    | Maybe<Page_Flexcontent_Flex_Form_Links>[]
    | Maybe<Page_Flexcontent_Flex_Posts_Links>[]
    | Component_Header_MenuHeader_Submenu_MainItems_Items[],
  appendText = true
) {
  for (const item in items) {
    if (items[item].link.title.includes('{count}')) {
      let replacementValue = 'vacatures'

      const functionGroup = new URL(items[item].link.url, new URL(page.request.url)).searchParams.get('Vakgebieden') || false

      if (functionGroup) {
        const functionGroupCounter = page.rest.globalCounters.functiongroups.find((f) => losseContentParse(f.name) === functionGroup)
        replacementValue = `${functionGroupCounter.count}${appendText ? ' vacatures' : ''}`
      } else {
        replacementValue = `${page.rest.globalCounters.vacancies}${appendText ? ' vacatures' : ''}`
      }

      items[item].link.title = items[item].link.title.replace('{count}', replacementValue)
    }

    if (items[item].link.title.includes('{internal_count}')) {
      let replacementValue = '(1)'

      // Somehow fill it with the internal vacancies count YET TO BE IMPLEMENTED

      items[item].link.title = items[item].link.title.replace('{internal_count}', replacementValue)
    }
  }

  return items as Page_Flexcontent_Flex_Content_Links[]
}
