import { LosseLink } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import useGlobalCounters from '~/hooks/useGlobalCounters'
import Stars from '../elements/Stars'

export default function ReviewsHandle() {
  const globalCounters = useGlobalCounters()

  return (
    <div className="fixed md:block hidden right-0 top-40 z-10 shadow">
      <LosseLink
        to="/beoordelingen/"
        className={clsx('bg-white text-site-base group hover:bg-site-grey-light flex flex-col items-center gap-2 px-2 py-3')}
      >
        <div className="vertical-rl">
          <span className="text-sm flex rotate-180 md:text-xs font-light md:font-bold group-hover:underline">
            uit {globalCounters?.reviews?.total} beoordelingen
          </span>
        </div>
        <div>
          <Stars type="vertical--to-t" rating={globalCounters?.reviews?.average} size="small" />
        </div>
        <div className="flex vertical-lr items-start font-bold">
          <span className="text-base rotate-180 ml-[3px]">{globalCounters?.reviews?.average?.toString()?.split('.')[1]}</span>
          <span className="text-2xl rotate-180">{globalCounters?.reviews?.average?.toString()?.split('.')[0]}.</span>
        </div>
      </LosseLink>
    </div>
  )
}
