/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation, useLosseLayout } from '@ubo/losse-sjedel'
import { useEffect, useState } from 'react'

export default function usePopup(name: string) {
  const [isOpen, setOpen] = useState(false)
  const location = useLocation()
  const { isScrollable, setScrollable } = useLosseLayout()

  useEffect(() => {
    const _isOpen = location.hash === `#${name}`

    if (!isOpen && _isOpen) setOpen(true)
    if (isOpen && !_isOpen) setOpen(false)
  }, [location.hash])

  useEffect(() => {
    function handleClick(event: MouseEvent) {
      const scrollY = window.scrollY

      if (event.target instanceof HTMLElement && event.target.tagName === 'A') {
        const href = event.target.getAttribute('href')

        if (href.includes(`#${name}`)) {
          setScrollable(false)

          setTimeout(() => {
            window.scrollTo({ top: scrollY, behavior: 'smooth' })
          }, 100)
        }
      }
    }

    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  useEffect(() => {
    if (isOpen && isScrollable) setScrollable(false)
    if (!isOpen && !isScrollable) setScrollable(true)

    if (isOpen) return

    window.location.hash = ''
  }, [isOpen])

  return [isOpen, setOpen] as [boolean, (open: boolean) => void]
}
