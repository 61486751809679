import { type BreadcrumbProps, LosseKruumels, useLoaderData } from '@ubo/losse-sjedel'
import useIsMobile from '~/hooks/useIsMobile'
import { type PageBodyData } from '~/templates/page'

const POST_TYPE_CRUMB: { [key: string]: BreadcrumbProps } = {
  vacature: { title: 'Vacatures', uri: '/vacatures/' },
  post: { title: 'Artikelen', uri: '/artikelen/' },
  case: { title: 'Businesscases', uri: '/werkgevers/businesscases/' }
}

export default function Breadcrumbs() {
  const { page } = useLoaderData<PageBodyData>()
  let contentType = page?.contentType?.node?.name

  if (page?.flexContent?.flex?.[0]?.styletype === 'banner_case') contentType = 'case'

  const crumbs: BreadcrumbProps[] = [{ title: 'Home', uri: '/' }]
  const isMobile = useIsMobile()

  if (contentType && contentType in POST_TYPE_CRUMB) crumbs.push(POST_TYPE_CRUMB[contentType])

  return (
    <LosseKruumels
      type={isMobile ? 'history' : 'fragment'}
      className="children-ol:flex children-li:flex children-ol:items-start custom-breadcrumb text-site-base hover:children-a:underline children-a:first-letter:uppercase children-a:inline-block"
      preCrumbs={crumbs}
      seperator={
        <span className="mx-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#A4A4A4"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <polyline points="9 18 15 12 9 6" />
          </svg>
        </span>
      }
    />
  )
}
