import { useLoaderData, type LoaderData } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useInView } from 'framer-motion'
import { useRef, useState } from 'react'
import Content, { type ContentProps } from '~/components/elements/Content'
import Links from '~/components/elements/Links'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentColumnsBig({ fields, index }: { fields: Page_Flexcontent_Flex_Content; index: number }) {
  const { theme } = useComponentTheme(index, fields?.styletype)

  const [columns] = useState(fields.description.split('<p>|</p>'))

  const titleRef = useRef(null)
  const containerRef = useRef(null)

  const titleVisible = useInView(titleRef, { once: true })
  const containerVisible = useInView(containerRef, { once: true })

  return (
    <section
      data-component="ContentColumnsBig"
      className={clsx(
        theme === 'light' && 'section--large',
        theme === 'dark' && 'bg-site-base pt-12 md:pt-20 lg:pt-36 section--large--mt',
        theme === 'none' && 'bg-site-grey-light pt-12 md:pt-20 lg:pt-36'
      )}
      id={fields.sectionId || (theme === 'dark' ? 'match' : '') || (theme === 'none' ? 'onboarding' : '')}
    >
      <div className="container flex flex-col gap-7 sm:gap-10 lg:gap-20">
        <div className={clsx('flex sm:items-end sm:flex-row flex-col relative gap-7 sm:gap-10 md:gap-20', theme === 'none' && 'lg:gap-44')}>
          <div
            className={clsx(
              titleVisible && 'animate-fade-right animate-ease-in-out',
              titleVisible ? 'opacity-100' : 'opacity-0',
              'bg-site-accent  w-[205px] h-[50px] sm:h-[69px] absolute top-0 bottom-0 my-auto -left-[240px]'
            )}
          />
          <h2
            ref={titleRef}
            className={clsx(
              titleVisible && 'animate-fade-up animate-ease-in-out animate-delay-75',
              titleVisible ? 'opacity-100' : 'opacity-0',
              'title--semi-large sm:title--unit border-text text-transparent font-bold',
              theme === 'dark' && 'text-white lg:title--semi-unit',
              theme === 'none' && 'lg:title--semi-unit',
              theme === 'light' && 'lg:title--chonker'
            )}
          >
            {fields.title}
          </h2>
          <Links
            className={clsx(
              titleVisible && 'animate-fade-up animate-ease-in-out animate-delay-100',
              titleVisible ? 'opacity-100' : 'opacity-0',
              'max-sm:hidden',
              theme === 'dark' && 'max-md:hidden',
              theme === 'none' && 'max-md:hidden',
              theme !== 'light' && 'relative lg:-top-6'
            )}
            items={fields.links}
          />
        </div>
        <div ref={containerRef} className="grid grid-cols-1 md:grid-cols-2 items-start gap-5 sm:gap-10 lg:gap-20 xl:gap-28">
          {columns.map((column, i) => (
            <div
              key={i}
              className={clsx(
                containerVisible && 'animate-fade-up animate-ease-in-out',
                containerVisible ? 'opacity-100' : 'opacity-0',
                'col-span-1 ',
                i === 0 && 'animate-delay-200',
                i === 1 && 'animate-delay-300'
              )}
            >
              <Content type={theme === 'light' ? 'thicc' : 'slighty-thicc'} theme={theme}>
                {column}
              </Content>
            </div>
          ))}
        </div>
        <Links
          className={clsx(
            containerVisible && 'animate-fade-up animate-ease-in-out animate-delay-100',
            containerVisible ? 'opacity-100' : 'opacity-0',
            'sm:hidden justify-end'
          )}
          items={fields.links}
        />
      </div>
    </section>
  )
}

export function useComponentTheme(index: number, styletype: string) {
  const data = useLoaderData<LoaderData>()

  const isEmployersPage = data.page.databaseId === 161
  let theme: ContentProps['theme'] = 'light'
  let type: 'alt' | 'normal' = 'normal'

  if (!isEmployersPage) return { theme, type }

  if (index === 1 && styletype === 'content_usps') type = 'alt'

  if (index === 4 && styletype === 'content_columns_big') theme = 'dark'

  if (index === 5 && styletype === 'content_usps') theme = 'dark'

  if (index === 6 && styletype === 'content_columns_big') theme = 'none'

  if (index === 7 && styletype === 'content_usps') theme = 'none'

  if (index === 8 && styletype === 'content_default') theme = 'none'

  if (index === 0 && styletype === 'content_videos') theme = 'none'

  if (index === 0 && styletype === 'slider_logos') theme = 'none'

  return { theme, type }
}
