import Breadcrumbs from '~/components/elements/Breadcrumbs'
import type { Page_Flexcontent_Flex_Content, Page_Flexcontent_Flex_Content_Items } from '~/graphql/types'
import Expert from '~/components/elements/Expert'
import Content from '~/components/elements/Content'
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import SocialSharing from '~/components/elements/SocialSharing'
import useIsVisible from '~/hooks/useIsVisible'

export default function ContentNews({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const [ref, isVisible] = useIsVisible()

  return (
    <section data-component="ContentNews" className="section relative" id={fields.sectionId} ref={ref}>
      <div className="max-lg:container max-lg:mb-6 pt-12">
        <div className="lg:absolute left-0 lg:top-20 bg-site-base px-4 py-5 flex lg:flex-col justify-evenly lg:justify-center items-center gap-5">
          <SocialSharing />
        </div>
      </div>
      <div className="container xl:pl-32">
        <Breadcrumbs />
        <div className="grid lg:grid-cols-12 gap-10 lg:gap-20 spacing--normal--top">
          <div className="lg:col-span-8 xl:col-span-7 pt-10 order-2 [&_>_div]:mb-12 [&_>_div:last-child]:mb-0">
            <div id={slugify(fields?.title)}>
              <h2 className="title--normal text-site-accent">{fields?.title}</h2>
              <Content className="children-p:font-bold spacing--small--top">{fields?.description}</Content>
              <LossePlaatjie src={fields.image} className="w-full h-auto object-contain spacing--small--top" />
            </div>
            {fields?.items?.map((item, index) => {
              switch (item.blocktype) {
                case 'default':
                  return <BlockDefault key={index} item={item} />

                case 'grey':
                  return <BlockGrey key={index} item={item} />

                case 'quote':
                  return <BlockQuote key={index} item={item} />

                default:
                  return null
              }
            })}
          </div>
          <div className="max-xl:hidden order-3" />
          <aside className={clsx('lg:col-span-4 lg:order-3 order-1', isVisible ? 'animate-fade-up opacity-100' : 'opacity-0')}>
            <div className="lg:sticky top-6 overflow-y-auto max-h-[90vh]">
              <Expert title={fields?.relationtitle} fields={fields?.relation} className="max-lg:hidden" />
              <div className="bg-site-grey-light p-6">
                <h3 className="title--small">In dit artikel:</h3>
                <ul className="spacing--small--top flex flex-col gap-3">
                  <SidebarItem title={fields?.title} />
                  {fields?.items
                    ?.filter((item) => !!item.title)
                    ?.map((item, index) => (
                      <SidebarItem key={index} title={item?.title} />
                    ))}
                </ul>
              </div>
            </div>
          </aside>
        </div>
      </div>
    </section>
  )
}

function SidebarItem({ title }: { title: string }) {
  return (
    <li className="flex gap-3">
      <div className="pt-[2px]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          className="min-w-[14px] min-h-[14px] max-w-[14px] max-h-[14px] mt-1"
        >
          <g clipPath="url(#clip0_1132_9689)">
            <path
              d="M3.74993 14.0626C3.74995 13.9394 3.77425 13.8175 3.82144 13.7037C3.86862 13.5899 3.93777 13.4865 4.02493 13.3995L8.81929 8.60512C8.96443 8.46002 9.07956 8.28776 9.15811 8.09816C9.23666 7.90856 9.27709 7.70535 9.27709 7.50012C9.27709 7.2949 9.23666 7.09168 9.15811 6.90208C9.07956 6.71249 8.96443 6.54022 8.81929 6.39512L4.03055 1.60637C3.85978 1.42956 3.76528 1.19274 3.76742 0.946932C3.76956 0.701122 3.86815 0.465985 4.04197 0.292165C4.21579 0.118345 4.45093 0.0197493 4.69674 0.0176133C4.94255 0.0154773 5.17936 0.109972 5.35617 0.280745L10.1449 5.0695C10.7888 5.71455 11.1504 6.58872 11.1504 7.50012C11.1504 8.41152 10.7888 9.28569 10.1449 9.93075L5.34992 14.7257C5.21878 14.8567 5.05175 14.9459 4.86994 14.982C4.68813 15.0181 4.49971 14.9995 4.32848 14.9285C4.15725 14.8575 4.0109 14.7374 3.90793 14.5833C3.80495 14.4292 3.74997 14.248 3.74993 14.0626Z"
              fill="#1D1D1B"
            />
          </g>
        </svg>
      </div>

      <LosseLink to={`#${slugify(title)}`}>
        <span className="text-sm mt-1 lg:text-base font-bold">{title}</span>
      </LosseLink>
    </li>
  )
}

function BlockDefault({ item }: { item: Page_Flexcontent_Flex_Content_Items }) {
  return (
    <div id={slugify(item?.title)}>
      {item?.title && <h2 className="title--normal">{item?.title}</h2>}
      {item?.description && (
        <Content className={clsx(item?.title && 'spacing--small--top list--stripe--accent')}>{item?.description}</Content>
      )}
      {item.link && (
        <LosseLink target={item.link.target} to={item.link.url} className="btn--accent spacing--small--top">
          {item.link.title}
        </LosseLink>
      )}
      {item?.image && <LossePlaatjie className="w-full spacing--small--top" src={item?.image} />}
    </div>
  )
}

function BlockGrey({ item }: { item: Page_Flexcontent_Flex_Content_Items }) {
  return (
    <div className="py-8 px-6 lg:px-10 -mx-6 lg:-mx-12 xl:py-14 xl:px-16 xl:-mx-16 bg-site-grey-light" id={slugify(item?.title)}>
      {item?.title && <h2 className="title--normal">{item?.title}</h2>}
      {item?.description && (
        <Content className={clsx(item?.title && 'spacing--small--top list--stripe--accent')}>{item?.description}</Content>
      )}
      {item.link && (
        <LosseLink target={item.link.target} to={item.link.url} className="btn--accent spacing--small--top">
          {item.link.title}
        </LosseLink>
      )}
      {item?.image && <LossePlaatjie className="w-full spacing--small--top" src={item?.image} />}
    </div>
  )
}

function BlockQuote({ item }: { item: Page_Flexcontent_Flex_Content_Items }) {
  return (
    <div className="py-6 px-8 sm:py-10 sm:px-12 sm:-mx-1 bg-site-base" id={slugify(item?.title)}>
      {item?.title && <h2 className="title--normal text-white">{item?.title}</h2>}
      {item?.description && (
        <Content className={clsx(item?.title && 'spacing--small--top', 'children-p:text-2xl children-p:font-bold')} theme="dark">
          {item?.description}
        </Content>
      )}
      {item.link && (
        <LosseLink target={item.link.target} to={item.link.url} className="btn--accent spacing--small--top">
          {item.link.title}
        </LosseLink>
      )}
      {item?.image && <LossePlaatjie className="w-full spacing--small--top" src={item?.image} />}
    </div>
  )
}

function slugify(input: string): string {
  return input
    ?.toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/^-+|-+$/g, '')
}
