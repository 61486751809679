import { useLosseConfig } from '@ubo/losse-sjedel'
import type { FormDuufProps } from '@ubo/form-duuf'
import { FormDuuf, FormDuufGroup, useFormDuuf } from '@ubo/form-duuf'
import Loading from './Loading'
import Content from './Content'
import clsx from 'clsx'

type FormThemes = 'dark' | 'light' | 'dark--alternative' | 'grey'
type Props = Omit<FormDuufProps, 'renderStatus' | 'renderLoading'> & {
  useChildren?: boolean
  renderStatus?: FormDuufProps['renderStatus']
  renderLoading?: FormDuufProps['renderLoading']
  theme?: FormThemes
  submit?: 'normal' | 'alternative'
}

export default function Form({ useChildren = false, generate = false, ...rest }: Props) {
  const config = useLosseConfig()
  const formId = rest.data.formId
  const apiUrl = `${config.wordpressUrl}/wp-json/gf/v2/forms/${formId}`

  return (
    <FormDuuf
      apiUrl={apiUrl}
      renderLoading={() => (
        <div className="absolute left-0 top-0 w-full h-full bg-site-base flex--center gap-3 z-20">
          <Loading />
          <p className="text-white">Aan het versturen ...</p>
        </div>
      )}
      renderStatus={({ content }) => (
        <>
          <div className="flex--center">
            <div className="bg-site-accent flex--center w-20 h-20 rounded-full box--shadow">
              <svg
                width="800px"
                height="800px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="w-20 h-20 text-white relative left-1"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.0303 8.78039L8.99993 16.8107L5.4696 13.2804L6.53026 12.2197L8.99993 14.6894L15.9696 7.71973L17.0303 8.78039Z"
                  fill="currentColor"
                />
              </svg>
            </div>
          </div>
          <Content className="content mt-4 text-center" theme={rest.theme === 'light' ? 'light' : 'dark'}>
            {content}
          </Content>
        </>
      )}
      className={clsx(
        ['dark', 'dark--alternative'].includes(rest.theme) && 'form--dark',
        rest.theme === 'light' && 'form--light',
        rest.theme === 'grey' && 'form--grey',
        'relative py-1'
      )}
      generate={generate}
      {...rest}
    >
      <>
        {useChildren && rest.children}
        {!useChildren && (
          <>
            <FormDuufGroup />
            <div className={clsx(rest.submit === 'alternative' && 'mt-10 flex--center')}>
              <Submit theme={rest.theme} />
            </div>
          </>
        )}
      </>
    </FormDuuf>
  )
}

export function Submit({ theme }: { theme: FormThemes }) {
  const context = useFormDuuf()
  const isValid: boolean =
    context.captchaFilled &&
    context.formik.isSubmitting === false &&
    Object.keys(context.formik.touched).length > 0 &&
    Object.keys(context.formik.errors).length === 0

  return (
    <div className="form-duuf-group form-duuf-group-submit">
      <span className={`form-duuf-submit${!isValid ? ' form-duuf-submit-disabled' : ''}`}>
        <button
          className={clsx(theme === 'dark--alternative' ? 'btn--accent !px-10' : 'btn--conversion')}
          type="submit"
          disabled={!isValid}
        >
          {theme !== 'dark--alternative' && (
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="01 align center">
                <path
                  id="Vector"
                  d="M1.94467 6.66874C1.60983 6.76442 1.30577 6.94586 1.06258 7.19513C0.819396 7.44439 0.645508 7.75284 0.558126 8.08994C0.470744 8.42704 0.472897 8.78112 0.564372 9.11713C0.655846 9.45315 0.833472 9.75945 1.07967 10.0057L4.49167 13.4137V19.9997H11.0847L14.5197 23.4297C14.704 23.6157 14.9233 23.7634 15.165 23.8643C15.4066 23.9652 15.6658 24.0173 15.9277 24.0177C16.0998 24.0174 16.2712 23.9952 16.4377 23.9517C16.7746 23.8669 17.0833 23.6947 17.3326 23.4526C17.5819 23.2105 17.763 22.9071 17.8577 22.5727L24.4917 0.020752L1.94467 6.66874ZM2.50067 8.59174L19.5287 3.57175L6.49367 16.5857V12.5857L2.50067 8.59174ZM15.9407 22.0157L11.9137 17.9997H7.91367L20.9467 4.97775L15.9407 22.0157Z"
                  fill="white"
                />
              </g>
            </svg>
          )}

          {context.form.button.text}
        </button>
      </span>
    </div>
  )
}
