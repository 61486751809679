/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { LosseLink, useLoaderData, useLocation, useLosseLayout } from '@ubo/losse-sjedel'
import { AnimatePresence } from 'framer-motion'
import HeaderSubmenuBig from './HeaderSubmenuBig'
import { useHeaderTheme, type HeaderLoaderData } from './Header'
import clsx from 'clsx'
import useGlobalCounters from '~/hooks/useGlobalCounters'
import HeaderSubmenu from './HeaderSubmenu'
import useIsMobile from '~/hooks/useIsMobile'

export default function HeaderMenu({ setMobileMenuOpen }: { setMobileMenuOpen: (open: boolean) => void }) {
  const { header } = useLoaderData<HeaderLoaderData>()
  const globalCounters = useGlobalCounters()
  const isMobile = useIsMobile()
  const [currentIndex, setCurrentIndex] = useState(-1)
  const headerTheme = useHeaderTheme()
  const location = useLocation()
  const { isScrollable, setScrollable } = useLosseLayout()

  useEffect(() => {
    if (!isScrollable && currentIndex === -1) setScrollable(true)
  }, [currentIndex])

  useEffect(() => {
    if (currentIndex !== -1) {
      setCurrentIndex(-1)
    }
  }, [location.pathname, location.hash, location.search])

  return (
    <>
      {header?.menuHeader?.map((edge, index) => {
        const submenuOpen = currentIndex === index
        const lastItem = index === header.menuHeader.length - 1
        const isContact = edge?.link?.url === '/contact/'

        return (
          <li
            onClick={(event) => {
              if (edge?.typeSubmenu !== 'yes_big' || submenuOpen) return

              event.stopPropagation()

              setCurrentIndex(index)

              setTimeout(() => {
                setScrollable(false)
              }, 1)
            }}
            className={clsx(isContact && 'max-lg:hidden', 'relative')}
            onMouseEnter={() => {
              if (isMobile || edge?.typeSubmenu !== 'yes_small' || submenuOpen) return

              setCurrentIndex(index)
            }}
            onMouseLeave={() => {
              if (isMobile || edge?.typeSubmenu !== 'yes_small' || !submenuOpen) return

              setCurrentIndex(-1)
            }}
            key={index}
          >
            <div
              className={clsx(
                lastItem && headerTheme === 'dark' && 'lg:btn--outline--white !p-0',
                lastItem && headerTheme === 'light' && 'lg:btn--outline--black !p-0',
                'flex flex-wrap items-center'
              )}
            >
              <LosseLink
                className={clsx(
                  headerTheme === 'light' && 'lg:text-black',
                  headerTheme === 'dark' && 'lg:text-white',
                  lastItem && (headerTheme === 'light' || headerTheme === 'dark') && 'lg:btn--spacing',
                  'group flex--center max-lg:animate-fade-up max-lg:text-white'
                )}
                to={edge?.typeSubmenu === 'yes_big' ? '#' : edge?.link?.url || '/'}
                style={{
                  animationDelay: `${index * 150}ms`
                }}
              >
                {({ isActive }) => (
                  <>
                    <span className="max-xs:text-xl max-lg:text-2xl lg:text-sm xl:text-base font-normal lg:font-bold">
                      {edge?.link?.title}
                    </span>
                    {edge?.link?.url === '/vacatures/' && (
                      <span>
                        <span className="ml-10 lg:ml-2 text-lg lg:text-xs bg-site-accent rounded-full px-3 xl:px-2 py-[2px] text-white shadow">
                          {globalCounters?.vacancies}
                        </span>
                      </span>
                    )}
                    {!lastItem && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="81"
                        height="79"
                        viewBox="0 0 81 79"
                        fill="none"
                        className={clsx(
                          isActive && edge?.typeSubmenu === 'no'
                            ? 'animate-fade-left text-site-accent'
                            : 'hidden group-hover:block group-hover:animate-fade-left',
                          'w-5 h-5 absolute text-site-conversion-hover -left-4 group-hover:animate-ease-in-out group-hover:animate-duration-300 -top-2'
                        )}
                      >
                        <path d="M49.125 0H31.875V25.2361H49.125V0Z" fill="currentColor" />
                        <path d="M49.125 53.7637H31.875V78.9998H49.125V53.7637Z" fill="currentColor" />
                        <path d="M81 31.0879H55.125V47.912H81V31.0879Z" fill="currentColor" />
                        <path d="M25.875 31.0879H0V47.912H25.875V31.0879Z" fill="currentColor" />
                      </svg>
                    )}
                  </>
                )}
              </LosseLink>
              {edge?.typeSubmenu !== 'no' && (
                <div
                  onClick={() => {
                    setCurrentIndex(submenuOpen ? -1 : index)
                  }}
                  className={clsx(
                    headerTheme === 'light' && 'lg:text-site-base',
                    headerTheme === 'dark' && 'lg:text-white',
                    'max-lg:-rotate-90 max-lg:text-white',
                    !lastItem && 'ml-10 lg:ml-2',
                    lastItem && 'ml-10 lg:-ml-4 lg:pr-4'
                  )}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="18"
                    viewBox="0 0 17 18"
                    fill="none"
                    className="w-7 h-7 lg:w-5 lg:h-5"
                  >
                    <path
                      d="M13.2528 6.31542C13.187 6.24903 13.1086 6.19633 13.0223 6.16037C12.936 6.12441 12.8434 6.1059 12.7499 6.1059C12.6564 6.1059 12.5638 6.12441 12.4775 6.16037C12.3912 6.19633 12.3128 6.24903 12.247 6.31542L9.00283 9.55958C8.93698 9.62597 8.85864 9.67867 8.77232 9.71463C8.686 9.75059 8.59342 9.7691 8.49991 9.7691C8.4064 9.7691 8.31382 9.75059 8.2275 9.71463C8.14119 9.67867 8.06284 9.62597 7.99699 9.55958L4.75283 6.31542C4.68698 6.24903 4.60864 6.19633 4.52232 6.16037C4.436 6.12441 4.34342 6.1059 4.24991 6.1059C4.1564 6.1059 4.06382 6.12441 3.9775 6.16037C3.89118 6.19633 3.81284 6.24903 3.74699 6.31542C3.61507 6.44813 3.54102 6.62766 3.54102 6.81479C3.54102 7.00192 3.61507 7.18145 3.74699 7.31417L6.99824 10.5654C7.39668 10.9634 7.93678 11.1869 8.49991 11.1869C9.06304 11.1869 9.60314 10.9634 10.0016 10.5654L13.2528 7.31417C13.3848 7.18145 13.4588 7.00192 13.4588 6.81479C13.4588 6.62766 13.3848 6.44813 13.2528 6.31542Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              )}
            </div>
            <AnimatePresence>
              {edge?.typeSubmenu === 'yes_big' && submenuOpen && (
                <HeaderSubmenuBig
                  key={index}
                  menuItem={edge}
                  fields={edge.submenu}
                  setCurrentIndex={setCurrentIndex}
                  setMobileMenuOpen={setMobileMenuOpen}
                />
              )}
            </AnimatePresence>
            <AnimatePresence>
              {edge?.typeSubmenu === 'yes_small' && submenuOpen && (
                <HeaderSubmenu key={index} fields={edge.submenuSmall} setCurrentIndex={setCurrentIndex} />
              )}
            </AnimatePresence>
          </li>
        )
      })}
    </>
  )
}
