import clsx from 'clsx'
import { useSwiper } from 'swiper/react'

export function SwiperNavigationArrow({
  id,
  className,
  direction
}: {
  id: string
  className: string
  direction: 'prev' | 'next'
  maxLength?: number
}) {
  const swiper = useSwiper()

  return (
    <div
      id={id}
      className={clsx(
        className && className,
        direction === 'prev' && swiper.isBeginning ? 'opacity-25 !cursor-not-allowed' : 'group',
        direction === 'next' && swiper.isEnd ? 'opacity-25 !cursor-not-allowed' : 'group',
        'cursor-pointer z-20 flex justify-center items-center w-20 h-20'
      )}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="28" viewBox="0 0 14 28" fill="none">
        <path
          d="M13.9997 1.74975C13.9997 1.97968 13.9543 2.20735 13.8663 2.41975C13.7782 2.63214 13.6491 2.8251 13.4864 2.98758L4.53693 11.9371C4.26601 12.2079 4.0511 12.5295 3.90447 12.8834C3.75785 13.2373 3.68238 13.6167 3.68238 13.9997C3.68238 14.3828 3.75785 14.7622 3.90447 15.1161C4.0511 15.47 4.26601 15.7916 4.53693 16.0624L13.4759 25.0014C13.7947 25.3315 13.9711 25.7735 13.9671 26.2324C13.9631 26.6912 13.7791 27.1301 13.4546 27.4546C13.1301 27.7791 12.6912 27.9631 12.2324 27.9671C11.7735 27.9711 11.3315 27.7947 11.0014 27.4759L2.06243 18.5369C0.860562 17.3328 0.185547 15.701 0.185547 13.9997C0.185547 12.2985 0.860562 10.6667 2.06243 9.46258L11.0131 0.511916C11.2579 0.267414 11.5697 0.100967 11.9091 0.033607C12.2484 -0.0337526 12.6001 0.00100026 12.9198 0.133471C13.2394 0.265942 13.5126 0.490188 13.7048 0.77787C13.897 1.06555 13.9997 1.40376 13.9997 1.74975Z"
          className="fill-white group-hover:fill-site-accent transition-all duration-200"
        />
      </svg>
    </div>
  )
}
