import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import useIsVisible from '~/hooks/useIsVisible'

export default function ContentExpert({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const [titleRef, titleVisible] = useIsVisible()
  const [contentRef, contentVisible] = useIsVisible()

  return (
    <section data-component="ContentExpert" className="section section--with-bg bg-site-grey-light" id={fields.sectionId}>
      <div ref={titleRef} className="container">
        <h2
          className={clsx(
            titleVisible && 'animate-fade-up animate-ease-in-out animate-duration-500',
            titleVisible ? 'opacity-100' : 'opacity-0',
            'text-transparent sm:block hidden relative -mb-1 md:-mb-3 lg:-mb-5 pl-8 md:pl-20 lg:pl-32 border-text title--semi-unit'
          )}
        >{`${fields.relation.recap.firstname} vertelt`}</h2>
        <div ref={contentRef} className="flex flex-col sm:flex-row gap-5 sm:gap-8 lg:gap-36">
          <div className="flex max-sm:items-end gap-5">
            <LossePlaatjie
              maxwidth={300}
              className={clsx(
                contentVisible && 'animate-fade-up animate-delay-150 animate-ease-in-out animate-duration-500',
                contentVisible ? 'opacity-100' : 'opacity-0',
                'min-w-[100px] w-[100px] sm:min-w-[150px] sm:w-[150px] md:min-w-[200px] md:w-[200px] lg:min-w-[250px] lg:w-[250px]'
              )}
              src={fields.relation.featuredImage.node}
            />
            <h2
              className={clsx(
                titleVisible && 'animate-fade-up animate-ease-in-out animate-duration-500',
                titleVisible ? 'opacity-100' : 'opacity-0',
                'text-transparent sm:hidden pb-3 relative border-text title--semi-unit'
              )}
            >{`${fields.relation.recap.firstname} vertelt`}</h2>
          </div>

          <div
            className={clsx(
              contentVisible && 'animate-fade-up animate-delay-200 animate-ease-in-out animate-duration-500',
              contentVisible ? 'opacity-100' : 'opacity-0',
              'flex flex-col max-w-screen-sm justify-center sm:pt-6 gap-4 lg:gap-6'
            )}
          >
            <h3 className="text-3xl font-bold text-site-base">{fields.title}</h3>
            <Content>{fields.description}</Content>
          </div>
        </div>
      </div>
    </section>
  )
}
