import { LossePlaatjie } from '@ubo/losse-sjedel'
import Links from '~/components/elements/Links'
import clsx from 'clsx'
import { useInView } from 'framer-motion'
import { useRef } from 'react'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'

export default function BannerColumns({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const contentRef = useRef(null)

  const contentVisible = useInView(contentRef, { once: true })

  return (
    <section
      data-component="BannerColumns"
      className="bg-site-base relative md:min-h-[800px] overflow-hidden md:h-screen-dynamic header--margin--top--negative"
    >
      <div className="max-w-[2000px] mx-auto h-full relative">
        <LossePlaatjie loading="eager" className="!h-full absolute animate-fade object-cover" src={fields.image} maxwidth={2000} />
        <div className="absolute h-full w-full bg-site-base mix-blend-multiply opacity-80 top-0 left-0" />
        <div className="h-full container relative z-20">
          <div ref={contentRef} className="h-full relative z-20 flex flex-col sm:justify-end max-md:pt-[140px] pb-10 md:pb-[100px]">
            <h1
              className={clsx(
                contentVisible && 'animate-fade-up animate-ease-in-out animate-duration-700',
                contentVisible ? 'opacity-100' : 'opacity-0',
                'title--semi-large text-center sm:title--large xl:title--semi-unit mb-3 text-white'
              )}
            >
              {fields.title}
            </h1>
            <h2
              className={clsx(
                contentVisible && 'animate-fade-up animate-ease-in-out animate-duration-1000',
                contentVisible ? 'opacity-100' : 'opacity-0',
                'title--small text-center xl:title--semi-large !font-light mb-6 sm:mb-10 lg:mb-20 text-site-accent'
              )}
            >
              {fields.subtitle}
            </h2>
            <div
              style={{ gridTemplateColumns: `repeat(${fields.items.length}, minmax(0, 1fr))` }}
              className={clsx(
                contentVisible && 'animate-fade-up animate-ease-in-out animate-delay-300 animate-duration-500',
                contentVisible ? 'opacity-100' : 'opacity-0',
                'grid max-sm:px-4 max-sm:!grid-cols-1 max-w-screen-lg mx-auto gap-3 sm:gap-8 lg:gap-14'
              )}
            >
              {fields.items.map((item, i) => (
                <Content theme="dark" key={i}>
                  {item.description}
                </Content>
              ))}
            </div>
            <div className="flex justify-center">
              <Links
                theme="dark"
                className={clsx(
                  contentVisible && 'animate-fade-up animate-ease-in-out animate-delay-300 animate-duration-1000',
                  contentVisible ? 'opacity-100' : 'opacity-0',
                  'pt-10 md:pt-[130px] max-sm:justify-center flex-wrap gap-y-4'
                )}
                items={fields.links}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
