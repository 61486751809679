import type { ComponentPropsWithoutRef } from 'react'
import { LosseContent, losseContentToReact } from '@ubo/losse-sjedel'
import Button from '~/components/elements/Button'
import clsx from 'clsx'

export type ContentProps = ComponentPropsWithoutRef<'div'> & {
  theme?: 'light' | 'dark' | 'accent' | 'none'
  type?: 'thiccer' | 'thicc' | 'slighty-thicc' | 'normal' | 'blog'
}

export default function Content({ theme = 'light', type, className, ...rest }: ContentProps) {
  return (
    <LosseContent
      {...rest}
      className={clsx(
        theme === 'accent' && 'content--accent',
        theme === 'light' && 'content--light',
        theme === 'dark' && 'content--dark',
        type === 'thiccer' && 'content--thiccer',
        type === 'thicc' && 'content--thicc',
        type === 'slighty-thicc' && 'content--slighty-thicc',
        type === 'blog' && 'content--blog',
        className
      )}
      replace={(domNode: any) => {
        const { name, attribs, children } = domNode

        // check if its pasted from word
        if (name === 'p' && attribs.style && attribs.style.includes('font-variant-ligatures')) {
          delete attribs.style
        }

        // check if there's any empty <ul> tags
        if (name === 'ul' && children.length === 0) {
          return <br style={{ display: 'none' }} />
        }

        // check if its an empty p tag
        if (name === 'p' && children.length === 0) {
          return <br style={{ display: 'none' }} />
        }

        if (name === 'a') {
          const attributes = { ...attribs }

          attributes.className = attributes.class
          delete attributes.class

          if (attribs.class === 'button') {
            return (
              <Button to={attributes.href} {...attributes}>
                {losseContentToReact(children)}
              </Button>
            )
          }
        }

        return null
      }}
    >
      {rest.children}
    </LosseContent>
  )
}
