import clsx from 'clsx'
import { Fragment } from 'react'
import Content from '~/components/elements/Content'
import Links from '~/components/elements/Links'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import useIsVisible from '~/hooks/useIsVisible'

export default function ContentBlockAlternative({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const [ref, isVisible] = useIsVisible()

  return (
    <section data-component="ContentBlockAlternative" className="section" ref={ref} id={fields.sectionId}>
      <div className="container">
        <div
          className={clsx(
            isVisible && 'animate-fade-up animate-ease-in-out',
            isVisible ? 'opacity-100' : 'opacity-0',
            'bg-neutral-100 p-6 sm:p-16 xl:px-28 xl:py-20 lg:columns-2'
          )}
        >
          <div className="break-after-column">
            <div className="relative flex flex-col gap-6 sm:gap-10 lg:gap-20 py-5">
              <h2
                className={clsx(
                  isVisible && 'animate-fade-up animate-delay-100 animate-ease-in-out',
                  isVisible ? 'opacity-100' : 'opacity-0',
                  'title--medium relative'
                )}
                ref={ref}
              >
                <span
                  className={clsx(
                    isVisible && 'animate-fade-right animate-once animate-delay-300 block',
                    isVisible ? 'opacity-100' : 'opacity-0',
                    'absolute w-[100px] h-[40px] lg:w-[125px] lg:h-[60px] left-0 top-0 lg:-top-[25px] bg-site-accent -z-[1]'
                  )}
                />
                {fields.title?.split(' | ')?.map((row, key) => {
                  return (
                    <Fragment key={key}>
                      {key > 0 && <br />}
                      <span className="leading-[1.2] pl-6">{row}</span>
                    </Fragment>
                  )
                })}
              </h2>
              <Links items={fields?.links} microcopyPlacement="bottom" />
            </div>
          </div>
          <div
            className={clsx(
              isVisible && 'animate-fade-left animate-once animate-delay-150 block',
              isVisible ? 'opacity-100' : 'opacity-0',
              'w-full h-full'
            )}
          >
            <Content>{fields?.description}</Content>
          </div>
        </div>
      </div>
    </section>
  )
}
