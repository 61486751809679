import type { Page_Flexcontent_Flex_Posts, Post as PostProps, RootQueryToPostConnection } from '~/graphql/types'
import Post from './post-types/Post'
import clsx from 'clsx'
import useIsVisible from '~/hooks/useIsVisible'

type PostsNewsMore = Page_Flexcontent_Flex_Posts & {
  subdata: {
    PostsMore: RootQueryToPostConnection
  }
}

export default function PostsNewsMore({ fields: _fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const [ref, isVisible] = useIsVisible()
  const fields = _fields as PostsNewsMore
  const posts = fields.subdata?.PostsMore?.edges as unknown as { node: PostProps }[]

  return (
    <section data-component="PostsNewsMore" className="section" ref={ref}>
      <div className="container">
        <h2 className="spacing--normal--bottom title--medium">{fields?.title}</h2>
        <div className="grid lg:grid-cols-2 gap-4 md:gap-6 xl:gap-8 spacing--small--top">
          {posts?.map((edge, index) => (
            <div
              key={index}
              className={clsx(isVisible ? 'animate-fade-up opacity-100' : 'opacity-0')}
              style={{ animationDelay: `${index * 100 + 250}ms` }}
            >
              <Post data={edge.node} showReadtime={false} />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
