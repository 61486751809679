import clsx from 'clsx'
import Content from '~/components/elements/Content'
import GreenGreenGrass from '~/components/elements/GreenGreenGrass'
import PageDownArrow from '~/components/elements/PageDownArrow'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'

export default function BannerUnit({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section
      data-component="BannerUnit"
      className="bg-site-base header--margin--top--negative overflow-hidden section section--with-bg relative"
    >
      <GreenGreenGrass className="w-[1000px] md:w-full max-w-[2000px] object-cover animate-fade animate-ease-in-out animate-duration-[2000ms] animate-delay-500 absolute top-[120px] left-0 right-0 mx-auto" />
      <div
        className={clsx(
          fields?.bannerHalfHeight
            ? 'min-h-[calc(40dvh_-_40px)] lg:min-h-[calc(40dvh_-_90px)]'
            : 'min-h-[calc(100dvh_-_40px)] lg:min-h-[calc(100dvh_-_90px)]',
          'container header--padding--top relative '
        )}
      >
        <PageDownArrow className="absolute w-5 md:w-7 z-10 left-0 right-0 mx-auto bottom-1 md:bottom-5 animate-bounce animate-ease-in-out" />
        <div className="flex justify-end">
          <div className="flex relative z-10 w-fit flex-col">
            <h1 className="title--medium md:title--large animate-fade-up px-4 pt-4 md:pt-6 md:px-6 bg-site-base animate-ease-in-out w-fit leading-[1] font-bold">
              {fields.title.split('|').map((t, i) => (
                <span className={clsx(i === 0 ? 'text-white' : 'text-transparent border-text--white')} key={i}>
                  {t}
                  <br />
                </span>
              ))}
            </h1>
            <div className="animate-fade-up max-w-[500px] pb-4 px-4 md:pb-6 md:px-6 bg-site-base pt-6 animate-ease-in-out animate-delay-100">
              <Content theme="dark">{fields.description}</Content>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
