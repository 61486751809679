import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import useIsVisible from '~/hooks/useIsVisible'

const gridOptions: {
  color: string
}[] = [
  {
    color: 'bg-site-accent'
  },
  { color: 'bg-site-accent' },
  { color: 'bg-site-base' },
  { color: 'bg-site-grey-alternative' },
  { color: 'bg-site-grey-dark' },
  { color: 'bg-site-accent' }
]

export default function ContentStats({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const [contentRef, contentVisible] = useIsVisible()
  const [titleRef, titleVisible] = useIsVisible()

  return (
    <section data-component="ContentStats" className="section section--with-bg bg-site-grey-light" id={fields.sectionId}>
      <div className="container">
        <div className="max-w-screen-lg mx-auto">
          <div
            ref={contentRef}
            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-rows-[repeat(10,minmax(0,70px))] gap-3 sm:gap-4 lg:gap-12"
          >
            <div
              ref={titleRef}
              className={clsx(
                titleVisible && 'animate-fade-up animate-ease-in-out animate-duration-500',
                titleVisible ? 'opacity-100' : 'opacity-0',
                'max-sm:col-span-1 max-md:col-span-2 md:row-span-3'
              )}
            >
              <h2 className="text-transparent border-text title--semi-large lg:title--large pb-3 sm:pb-4 lg:pb-8">{fields.title}</h2>
              <Content type={fields.addbiggerdescription ? 'slighty-thicc' : 'normal'} className="max-lg:children-p:!text-base">
                {fields.description}
              </Content>
            </div>
            {fields.items.map((item, i) => (
              <div
                key={i}
                style={{ animationDelay: `${i * 100 + 500}ms` }}
                className={clsx(
                  contentVisible && 'animate-fade-up animate-ease-in-out animate-duration-500',
                  contentVisible ? 'opacity-100' : 'opacity-0',
                  gridOptions[i + 1].color,
                  i === 0 && 'md:col-start-2 md:row-start-2',
                  i === 1 && 'max-sm:row-start-[unset] max-sm:col-start-[unset] max-md:row-start-3 max-md:col-start-2',
                  'row-span-2 md:row-span-3 h-full w-full object-cover overflow-hidden',
                  'grid grid-cols-2'
                )}
              >
                <div className="flex items-end">
                  <LossePlaatjie loading="eager" maxwidth={500} src={item.image} />
                </div>
                <div
                  className={clsx(
                    i === 0 && 'pr-5 sm:pr-7 lg:pr-10 py-5 sm:py-7 lg:py-10',
                    i === 1 && 'max-sm:-order-1 max-sm:pl-5 sm:pr-7 lg:pr-10 py-7 lg:py-10',
                    i === 2 && 'sm:-order-1 max-sm:pr-5 sm:pl-7 lg:pl-10 py-7 lg:py-10 sm:grid grid-cols-1',
                    i === 3 && '-order-1 pl-5 sm:pl-7 lg:pl-10 py-5 sm:py-7 lg:py-10',
                    i === 4 && 'sm:-order-1 max-sm:pr-5 sm:pl-7 lg:pl-10 py-7 lg:py-10 sm:grid grid-cols-1'
                  )}
                >
                  <h3
                    className={clsx(
                      i === 0 && 'text-right',
                      i === 1 && 'sm:text-right text-white',
                      i === 2 && 'text-white max-sm:pt-0 max-lg:pt-2',
                      i === 3 && 'text-white',
                      i === 4 && '',
                      'text-3xl lg:text-6xl font-bold pb-1 lg:pb-2'
                    )}
                  >
                    {item.title}
                  </h3>
                  <p
                    className={clsx(
                      i === 0 && 'text-right',
                      i === 1 && 'text-white sm:text-right',
                      i === 2 && 'text-white -order-1 ',
                      i === 3 && 'text-white',
                      i === 4 && '-order-1',
                      'text-xl sm:text-base md:text-lg lg:text-2xl leading-[1.2]'
                    )}
                  >
                    {item.subtitle}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
