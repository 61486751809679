import { useLosseData } from '@ubo/losse-sjedel'
import { useState } from 'react'
import { Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import type { InstagramFeedItem, Page_Flexcontent_Flex_Slider } from '~/graphql/types'

export default function SliderInstagram({ fields }: { fields: Page_Flexcontent_Flex_Slider }) {
  const [data] = useLosseData<{ instagramFeed: InstagramFeedItem[] }>('InstagramFeed')
  const [controlledSwiper, setControlledSwiper] = useState(null)

  return (
    <section data-component="SliderInstagram" className="section overflow-hidden" id={fields.sectionId}>
      <div className="container mx-auto relative">
        <h2 className="title--medium mb-4">{fields.title}</h2>
        <h3 className="title--small text-site-accent mb-10">{fields.subtitle}</h3>

        <div className="flex w-[100px] relative z-10 gap-6 mr-10 mt-4 items-start mb-6">
          <button
            onClick={() => {
              if (!controlledSwiper) return
              controlledSwiper.slidePrev()
            }}
            type="button"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
              <g clipPath="url(#clip0_813_6613)">
                <path
                  fill="#1D1D1B"
                  d="M18 1.5a1.501 1.501 0 01-.44 1.06l-7.671 7.672a2.5 2.5 0 000 3.536l7.662 7.662a1.5 1.5 0 01-2.121 2.12L7.768 15.89a5.505 5.505 0 010-7.778L15.44.439A1.5 1.5 0 0118 1.499z"
                ></path>
              </g>
            </svg>
          </button>

          <button
            onClick={() => {
              if (!controlledSwiper) return
              controlledSwiper.slideNext()
            }}
            type="button"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
              <g clipPath="url(#clip0_813_6611)">
                <path
                  fill="#1D1D1B"
                  d="M6 22.5a1.5 1.5 0 01.44-1.06l7.671-7.672a2.5 2.5 0 000-3.536L6.449 2.57A1.5 1.5 0 018.57.45l7.662 7.661a5.505 5.505 0 010 7.778L8.56 23.561A1.5 1.5 0 016 22.501z"
                ></path>
              </g>
            </svg>
          </button>
        </div>

        <Swiper
          className="w-full"
          onSwiper={setControlledSwiper}
          autoplay
          modules={[Autoplay]}
          wrapperClass="items-center"
          slidesPerView={1}
          spaceBetween={15}
          breakpoints={{
            640: {
              slidesPerView: 2
            },
            768: {
              slidesPerView: 3
            },
            1024: {
              slidesPerView: 4
            },
            1280: {
              slidesPerView: 5
            }
          }}
        >
          {data &&
            data?.instagramFeed?.map((item, index) => (
              <SwiperSlide className="flex items-center" key={index}>
                <a href={item.permalink} target="_blank" className="relative flex flex-col items-center justify-center " rel="noreferrer">
                  <img
                    src={item.thumbnailUrl || item.mediaUrl}
                    className="sm:w-full sm:h-[245px] w-full h-full object-cover aspect-square"
                    alt={item.caption}
                  />

                  <div className="absolute left-3 bottom-3 bg-white w-10 h-10 rounded-full flex items-center justify-center">
                    {item.mediaType !== 'VIDEO' && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        className="feather feather-image"
                        viewBox="0 0 24 24"
                      >
                        <rect width="18" height="18" x="3" y="3" rx="2" ry="2"></rect>
                        <circle cx="8.5" cy="8.5" r="1.5"></circle>
                        <path d="M21 15L16 10 5 21"></path>
                      </svg>
                    )}

                    {item.mediaType === 'VIDEO' && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        className="feather feather-video"
                        viewBox="0 0 24 24"
                      >
                        <path d="M23 7L16 12 23 17 23 7z"></path>
                        <rect width="15" height="14" x="1" y="5" rx="2" ry="2"></rect>
                      </svg>
                    )}
                  </div>
                </a>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </section>
  )
}
