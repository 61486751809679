import { LosseLink } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { type Post } from '~/graphql/types'

export default function Case({ fields, type = 'big' }: { fields: Post; type?: 'big' | 'small' }) {
  return (
    <LosseLink
      to={fields.uri}
      className={clsx(
        type === 'big'
          ? 'bg-site-base p-6 sm:p-8 lg:p-14 lg:col-span-3 lg:h-[520px]'
          : 'bg-site-grey-dark py-6 sm:py-8 lg:py-14 px-6 sm:px-8 lg:col-span-2 lg:h-[520px]',
        'flex flex-col group justify-between gap-8'
      )}
    >
      <div className="flex items-center gap-4 sm:gap-6">
        <div className="bg-site-accent px-6 py-2 rounded-full text-white">{fields?.categories?.edges?.[0]?.node?.name}</div>
        <div className="flex max-sm:text-sm items-center gap-2 sm:gap-3 text-white">
          <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="fi-rr-clock" clipPath="url(#clip0_1015_5398)">
              <path
                id="Vector"
                d="M12.7383 0C10.3649 0 8.04483 0.703788 6.07144 2.02236C4.09805 3.34094 2.55998 5.21509 1.65173 7.4078C0.743481 9.60051 0.505841 12.0133 0.968863 14.3411C1.43189 16.6689 2.57478 18.8071 4.25301 20.4853C5.93124 22.1635 8.06943 23.3064 10.3972 23.7694C12.725 24.2324 15.1378 23.9948 17.3305 23.0866C19.5232 22.1783 21.3973 20.6402 22.7159 18.6668C24.0345 16.6935 24.7383 14.3734 24.7383 12C24.7348 8.81846 23.4695 5.76821 21.2198 3.51852C18.9701 1.26883 15.9198 0.00344108 12.7383 0V0ZM12.7383 22C10.7605 22 8.82708 21.4135 7.18258 20.3147C5.53809 19.2159 4.25637 17.6541 3.49949 15.8268C2.74262 13.9996 2.54458 11.9889 2.93043 10.0491C3.31629 8.10929 4.26869 6.32746 5.66722 4.92893C7.06574 3.53041 8.84757 2.578 10.7874 2.19215C12.7272 1.8063 14.7379 2.00433 16.5651 2.7612C18.3924 3.51808 19.9542 4.79981 21.053 6.4443C22.1518 8.08879 22.7383 10.0222 22.7383 12C22.7354 14.6513 21.6809 17.1931 19.8061 19.0679C17.9314 20.9426 15.3896 21.9971 12.7383 22Z"
                fill="white"
              />
              <path
                id="Vector_2"
                d="M12.7387 6C12.4735 6 12.2191 6.10536 12.0316 6.29289C11.8441 6.48043 11.7387 6.73478 11.7387 7V11.325L8.36772 13.437C8.14228 13.5778 7.98203 13.8024 7.9222 14.0614C7.86238 14.3204 7.90789 14.5926 8.04872 14.818C8.18955 15.0434 8.41416 15.2037 8.67315 15.2635C8.93214 15.3233 9.20428 15.2778 9.42972 15.137L13.2697 12.737C13.4148 12.6461 13.5341 12.5195 13.6162 12.3692C13.6984 12.219 13.7405 12.0502 13.7387 11.879V7C13.7387 6.73478 13.6334 6.48043 13.4458 6.29289C13.2583 6.10536 13.0039 6 12.7387 6Z"
                fill="white"
              />
            </g>
          </svg>
          {`${fields.recap?.readtime} minuten`}
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div
          className={clsx(
            type === 'big'
              ? 'bg-site-base text-2xl sm:text-3xl lg:text-[44px] lg:!leading-[1.2]'
              : 'bg-site-grey-dark lg:!leading-[1.2] text-2xl sm:text-3xl lg:text-[35px]',
            'text-white font-bold lg:group-hover:pb-3 transition-all duration-200'
          )}
        >
          {fields.recap?.quote || fields.title}
        </div>
        {fields.recap?.company && <div className="text-site-accent text-xl lg:text-3xl">{fields.recap?.company}</div>}
      </div>
    </LosseLink>
  )
}
