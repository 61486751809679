import { useState } from 'react'
import { FreeMode } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import Content from '~/components/elements/Content'
import CrossHair from '~/components/elements/CrossHair'
import EndlessContainer from '~/components/elements/EndlessContainer'
import type { Page_Flexcontent_Flex_Slider } from '~/graphql/types'

export default function SliderProcedure({ fields }: { fields: Page_Flexcontent_Flex_Slider }) {
  const [controlledSwiper, setControlledSwiper] = useState(null)

  const svgStates = [
    <svg key={0} xmlns="http://www.w3.org/2000/svg" width="38" height="38" fill="none" viewBox="0 0 38 38">
      <path
        stroke="#88B922"
        d="M15.454 11.639V.5h7.093v11.139h-7.093zM15.454 37.5V26.361h7.093v11.14h-7.093zM26.361 22.546v-7.092H37.5v7.092H26.361zM.5 22.546v-7.092h11.139v7.092H.5z"
      ></path>
    </svg>,

    <svg key={1} xmlns="http://www.w3.org/2000/svg" width="38" height="38" fill="none" viewBox="0 0 38 38">
      <path fill="#78BF00" stroke="#88B922" d="M15.454 11.639V.5h7.093v11.139h-7.093z"></path>
      <path
        stroke="#88B922"
        d="M15.454 37.5V26.361h7.093v11.14h-7.093zM26.361 22.546v-7.092H37.5v7.092H26.361zM.5 22.546v-7.092h11.139v7.092H.5z"
      ></path>
    </svg>,

    <svg key={2} xmlns="http://www.w3.org/2000/svg" width="38" height="38" fill="none" viewBox="0 0 38 38">
      <path fill="#78BF00" stroke="#78BF00" d="M15.454 11.639V.5h7.093v11.139h-7.093z"></path>
      <path stroke="#78BF00" d="M15.454 37.5V26.361h7.093v11.14h-7.093z"></path>
      <path fill="#78BF00" stroke="#78BF00" d="M26.361 22.546v-7.092H37.5v7.092H26.361z"></path>
      <path stroke="#78BF00" d="M.5 22.546v-7.092h11.139v7.092H.5z"></path>
    </svg>,

    <svg key={3} width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" className="min-w-[38px]">
      <path d="M15.4541 11.6389V0.5H22.5467V11.6389H15.4541Z" fill="#78BF00" stroke="#78BF00" />
      <path d="M15.4541 37.5002V26.3613H22.5467V37.5002H15.4541Z" fill="#78BF00" stroke="#78BF00" />
      <path d="M26.3611 22.5462V15.4536H37.5V22.5462H26.3611Z" fill="#78BF00" stroke="#78BF00" />
      <path d="M0.5 22.5462V15.4536H11.6389V22.5462H0.5Z" stroke="#78BF00" />
    </svg>,

    <svg key={4} xmlns="http://www.w3.org/2000/svg" width="38" height="38" fill="none" viewBox="0 0 38 38">
      <path
        fill="#78BF00"
        stroke="#78BF00"
        d="M15.454 11.639V.5h7.093v11.139h-7.093zM15.454 37.5V26.361h7.093v11.14h-7.093zM26.361 22.546v-7.092H37.5v7.092H26.361zM.5 22.546v-7.092h11.139v7.092H.5z"
      ></path>
    </svg>
  ]

  return (
    <section
      data-component="SliderProcedure"
      className="section section--with-bg bg-site-grey-light overflow-hidden"
      id={fields?.sectionId}
    >
      <div className="container">
        <div className="sm:px-10 py-3 relative">
          <CrossHair className="w-[220px] h-[220px] absolute -left-14 -top-16 sm:block hidden" />
          <div className="relative">
            {fields?.title && <h2 className="title--medium mb-2">{fields?.title}</h2>}
            {fields?.subtitle && <h3 className="sm:title--light title--tiny sm:text-site-base text-site-accent">{fields?.subtitle}</h3>}
          </div>

          <EndlessContainer>
            <div className="flex mt-4 sm:flex-row flex-col -ml-4">
              <div className="flex w-[100px] relative z-10 gap-6 mr-10 mt-4 items-start sm:mb-0 mb-4">
                <button
                  onClick={() => {
                    if (!controlledSwiper) return
                    controlledSwiper.slidePrev()
                  }}
                  type="button"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <g clipPath="url(#clip0_813_6613)">
                      <path
                        fill="#1D1D1B"
                        d="M18 1.5a1.501 1.501 0 01-.44 1.06l-7.671 7.672a2.5 2.5 0 000 3.536l7.662 7.662a1.5 1.5 0 01-2.121 2.12L7.768 15.89a5.505 5.505 0 010-7.778L15.44.439A1.5 1.5 0 0118 1.499z"
                      ></path>
                    </g>
                  </svg>
                </button>

                <button
                  onClick={() => {
                    if (!controlledSwiper) return
                    controlledSwiper.slideNext()
                  }}
                  type="button"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <g clipPath="url(#clip0_813_6611)">
                      <path
                        fill="#1D1D1B"
                        d="M6 22.5a1.5 1.5 0 01.44-1.06l7.671-7.672a2.5 2.5 0 000-3.536L6.449 2.57A1.5 1.5 0 018.57.45l7.662 7.661a5.505 5.505 0 010 7.778L8.56 23.561A1.5 1.5 0 016 22.501z"
                      ></path>
                    </g>
                  </svg>
                </button>
              </div>

              <Swiper
                className="w-full"
                onSwiper={setControlledSwiper}
                freeMode
                spaceBetween={25}
                modules={[FreeMode]}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    freeMode: false
                  },
                  640: {
                    slidesPerView: 2.6,
                    freeMode: true
                  }
                }}
              >
                {fields?.items?.map((item, index) => (
                  <SwiperSlide className="p-2 !h-auto pr-6" key={index}>
                    <div className="p-6 bg-white block--shadow h-full flex flex-col gap-4">
                      <div className="flex items-center gap-x-4">
                        {svgStates[index]}
                        <h4 className="title--light">{item?.title}</h4>
                      </div>

                      <Content>{item?.description}</Content>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </EndlessContainer>
        </div>
      </div>
    </section>
  )
}
