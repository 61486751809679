import { Switch } from '@headlessui/react'
import { useLocation } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useEffect, useState } from 'react'

export default function ToggleVacancyAlert({ className }: { className?: string }) {
  const [enabled, setEnabled] = useState(false)
  const location = useLocation()

  useEffect(() => {
    const _isOpen = location.hash === `#vacature-alert`

    if (_isOpen) setEnabled(true)
    if (!_isOpen) setEnabled(false)
  }, [location.hash])

  return (
    <a href="#vacature-alert" className={clsx('flex items-center group animate-fade', className)}>
      <Switch
        checked={enabled}
        className={clsx(
          enabled ? 'bg-site-accent' : 'bg-site-grey',
          'relative inline-flex h-5 w-9 items-center rounded-full pointer-events-none'
        )}
      >
        <span
          className={clsx(
            enabled ? 'translate-x-[18px]' : 'translate-x-1',
            'inline-block h-[14px] w-[14px] transform rounded-full bg-white transition'
          )}
        />
      </Switch>
      <span className="ml-3 text-sm font-bold text-white group-hover:underline">Vacature alert ontvangen</span>
    </a>
  )
}
