import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useInView } from 'framer-motion'
import { Fragment, useRef, useState } from 'react'
import Content from '~/components/elements/Content'
import Form from '~/components/elements/Form'
import Links from '~/components/elements/Links'
import Loading from '~/components/elements/Loading'
import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'

export default function FormWhitepaper({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  const [showForm, setShowForm] = useState(false)

  const titleRef = useRef(null)
  const imageRef = useRef(null)
  const containerRef = useRef(null)

  const titleVisible = useInView(titleRef, { once: true })
  const imageVisible = useInView(imageRef, { once: true })
  const containerVisible = useInView(containerRef, { once: true })

  return (
    <section data-component="FormWhitepaper" className="py-6 xs:pb-12 xs:pt-20 sm:section" id={fields.sectionId}>
      <div className="container">
        <div
          ref={containerRef}
          className={clsx(
            containerVisible && 'animate-fade-up animate-ease-in-out animate-duration-500',
            containerVisible ? 'opacity-100' : 'opacity-0',
            'bg-site-grey py-5 sm:py-8 md:py-12 lg:py-16 relative px-5 xs:px-7 sm:px-10 md:px-16 lg:px-20 grid grid-cols-1 max-lg:gap-10 lg:grid-cols-2'
          )}
        >
          <h2
            ref={titleRef}
            className={clsx(
              titleVisible && 'animate-fade-up animate-ease-in-out animate-delay-100 animate-duration-500',
              titleVisible ? 'opacity-100' : 'opacity-0',
              'lg:absolute left-2 top-8 z-10 text-transparent border-text title--semi-large sm:title--large xl:title--semi-unit'
            )}
          >
            {fields.title}
            <div
              className={clsx(
                titleVisible && 'animate-fade-right animate-delay-200 animate-ease-in-out animate-duration-500',
                titleVisible ? 'opacity-100' : 'opacity-0',
                'bg-site-accent absolute left-10 sm:left-16 md:left-12 top-2 sm:top-4 md:top-4 lg:top-10 w-[120px] sm:w-[150px] lg:w-[220px] h-[60px] sm:h-[80px] lg:h-[125px] -z-10'
              )}
            />
          </h2>
          <div
            ref={imageRef}
            className="flex max-lg:absolute max-sm:-right-4 max-md:-right-6 max-lg:right-6 max-lg:-top-14 justify-center lg:pt-5"
          >
            <LossePlaatjie
              className={clsx(
                imageVisible && 'animate-fade-right animate-delay-300 animate-ease-in-out animate-duration-500',
                imageVisible ? 'opacity-100' : 'opacity-0',
                'w-[140px] xs:block hidden xs:w-[170px] sm:w-[200px] md:w-[220px] lg:w-[260px] relative z-20 h-auto'
              )}
              src={fields.image}
              maxwidth={600}
            />
          </div>
          <div className="relative">
            {showForm ? (
              <div
                key="FormWhitepaperForm"
                className="animate-fade-up animate-ease-in-out animate-duration-500 flex flex-col h-full justify-center"
              >
                <svg
                  onClick={() => setShowForm(false)}
                  className="absolute top-0 right-0 cursor-pointer"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                >
                  <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M20 20L4 4m16 0L4 20" />
                </svg>
                <Form
                  theme="light"
                  onSubmitDone={() => {
                    window.open(fields?.downloadfile?.mediaItemUrl, '_blank')
                  }}
                  data={fields.form}
                  renderLoading={() => <Loading />}
                  renderStatus={({ content }) => <Content>{content}</Content>}
                />
              </div>
            ) : (
              <div key="FormWhitepaperContent" className="animate-fade-up animate-ease-in-out animate-duration-500">
                <h3 className="title--normal sm:title--medium !leading-[1.2] pb-4">
                  {fields.subtitle.split('|').map((t, i) => (
                    <Fragment key={i}>
                      <span>{t}</span>
                      <br />
                    </Fragment>
                  ))}
                </h3>
                <Content>{fields.description}</Content>
                {fields.internalform ? (
                  <div className="pt-5 sm:pt-8">
                    <button onClick={() => setShowForm(true)} className="btn--conversion">
                      Download 'm hier!
                    </button>
                  </div>
                ) : (
                  <Links className="pt-5 sm:pt-8" theme="light" items={fields.links} />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
