/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import { useState } from 'react'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import { motion } from 'framer-motion'
import { useFaq } from '@ubo/losse-sjedel'
import useIsVisible from '~/hooks/useIsVisible'

export default function ContentFaq({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const [ref, isVisible] = useIsVisible()

  useFaq({
    items:
      fields?.items?.map((item) => ({
        question: item.title,
        answer: item.description.replace(/<[^>]*>/g, '')
      })) ?? []
  })

  return (
    <section data-component="ContentFaq" className="section lg:px-20 sm:px-10" id={fields.sectionId} ref={ref}>
      <div className="container sm:bg-site-grey-light lg:py-24 py-8">
        <div className="max-w-screen-md mx-auto">
          <div className="sm:mb-14 mb-6">
            <h2
              className={clsx(
                'title--medium text-center mb-4',
                isVisible && 'animate-fade-up animate-ease-in-out',
                isVisible ? 'opacity-100' : 'opacity-0'
              )}
            >
              {fields.title}
            </h2>
            {fields?.description && (
              <Content
                className={clsx(
                  'text-center',
                  isVisible && 'animate-fade-up animate-ease-in-out animate-delay-200',
                  isVisible ? 'opacity-100' : 'opacity-0'
                )}
              >
                {fields?.description}
              </Content>
            )}
            {!fields?.description && (
              <div className="text-center content--light">
                Staat je vraag er niet tussen?{' '}
                <button
                  type="button"
                  onClick={() => {
                    // @ts-ignore
                    Tawk_API.toggle()
                  }}
                  className="text-site-conversion font-bold"
                >
                  Stel je vraag
                </button>{' '}
                dan aan een van onze experts.
              </div>
            )}
          </div>

          {fields.items.map((item, index) => (
            <Accordion key={index} index={index} isVisible={isVisible} title={item.title} description={item.description} />
          ))}
        </div>
      </div>
    </section>
  )
}

function Accordion({ title, index, isVisible, description }: { title: string; index: number; isVisible: boolean; description: string }) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div
      className={clsx(isVisible && 'animate-fade-up animate-ease-in-out', isVisible ? 'opacity-100' : 'opacity-0')}
      style={{ animationDelay: `${index * 100 + 300}ms` }}
    >
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="mb-4 flex items-center justify-between border-b border-black py-2 px-2 w-full gap-x-2"
      >
        <span className="font-bold lg:text-xl text-base text-left">{title}</span>
        <svg
          className={clsx('transform transition-transform duration-300 flex-none', isOpen && 'rotate-90')}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            fill="#1D1D1B"
            d="M8.21 5.29a1 1 0 000 1.42l4.58 4.58a1 1 0 010 1.42l-4.58 4.58a.999.999 0 000 1.42 1 1 0 001.41 0l4.59-4.59a3 3 0 000-4.24L9.62 5.29a1 1 0 00-1.41 0z"
          ></path>
        </svg>
      </button>

      <motion.div
        className="overflow-hidden px-2"
        initial={{ height: 0 }}
        animate={{ height: isOpen ? 'fit-content' : 0 }}
        transition={{ duration: 0.2 }}
      >
        <Content className="pb-4">{description}</Content>
      </motion.div>
    </div>
  )
}
