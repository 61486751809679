import clsx from 'clsx'
import { Fragment } from 'react'

type Props = {
  currentStep: number
  steps: string[]
}

export default function StepProgress({ currentStep, steps }: Props) {
  return (
    <div className="flex justify-between relative mb-12">
      {steps.map((step, index) => {
        const percentage = 100 / (steps.length - 1)
        const isDone = index <= currentStep

        return (
          <Fragment key={index}>
            {index !== 0 && (
              <div
                className={clsx('absolute h-1 top-1/2 -translate-y-1/2 rounded-full shadow', isDone && 'bg-black', !isDone && 'bg-white')}
                style={{
                  width: `${Math.round(percentage)}%`,
                  left: `${Math.round(percentage * (index - 1))}%`
                }}
              />
            )}
            <div className={clsx('w-3 h-3 rounded-full relative shadow z-10', !isDone && 'bg-white', isDone && 'bg-black')}>
              <div
                className={clsx(
                  'absolute top-[calc(100%+theme(spacing.2))] text-black text-[10px] font-site-base left-1/2 -translate-x-1/2',
                  !isDone && 'opacity-40'
                )}
              >
                {step}
              </div>
            </div>
          </Fragment>
        )
      })}
    </div>
  )
}
