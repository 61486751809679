import clsx from 'clsx'
import { useState, useRef, useEffect } from 'react'

export default function EndlessContainer({ hasContainer = true, ...props }) {
  const [containerWidth, setContainerWidth] = useState<number | null>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const handleResize = () => {
    if (!containerRef.current) {
      return
    }

    const width = containerRef.current.getBoundingClientRect().width
    const spaceLeft = (window.innerWidth - width) / 2

    setContainerWidth(Math.round(width + spaceLeft))
  }

  useEffect(() => {
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', () => handleResize)
    }
  }, [containerRef])

  return (
    <>
      <div className={clsx(hasContainer && 'container', 'max-w-screen-xl')} ref={containerRef} />
      <div
        className={clsx('ml-auto', props.className ? `${props.className}` : 'pl-4')}
        style={{
          width: containerWidth || '100%'
        }}
      >
        {props.children}
      </div>
    </>
  )
}
