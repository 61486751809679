import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useInView } from 'framer-motion'
import { useRef } from 'react'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import Content from '~/components/elements/Content'
import Form from '~/components/elements/Form'
import type { Expert, Page_Flexcontent_Flex_Form } from '~/graphql/types'

export default function FormContent({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  const relation = fields.relation as Expert

  const contentRef = useRef(null)
  const relationRef = useRef(null)
  const formRef = useRef(null)

  const contentVisible = useInView(contentRef, { once: true })
  const relationVisible = useInView(relationRef, { once: true })
  const formVisible = useInView(formRef, { once: true })

  return (
    <section data-component="FormContent" className="pt-6 lg:pt-8 section--pb" id={fields.sectionId}>
      <div className="container">
        <Breadcrumbs />
        <div className="grid lg:pt-8 grid-cols-3 lg:grid-cols-7 gap-6 lg:gap-12 xl:gap-20">
          <div
            ref={contentRef}
            className={clsx(
              contentVisible && 'animate-fade-up animate-ease-in-out animate-duration-500',
              contentVisible ? 'opacity-100' : 'opacity-0',
              'col-span-3 lg:col-span-4 pt-6'
            )}
          >
            <Content>{fields.description}</Content>
            <div
              ref={relationRef}
              className={clsx(
                relationVisible && 'animate-fade-up animate-delay-500 animate-ease-in-out animate-duration-500',
                relationVisible ? 'opacity-100' : 'opacity-0',
                'flex flex-col sm:flex-row gap-3 sm:gap-6 px-4 sm:px-6 py-5 sm:py-10'
              )}
            >
              <LossePlaatjie
                maxwidth={200}
                className="max-sm:w-[150px] sm:block hidden min-w-[100px] sm:min-w-[200px]"
                src={relation?.featuredImage?.node}
              />
              <div className="flex flex-col gap-3 justify-between lg:py-4">
                <Content className="children-strong:text-site-base children-strong:text-lg">{fields.extradescription}</Content>
                <div className="max-sm:flex max-sm:items-center max-sm:gap-4 max-sm:flex-row">
                  <LossePlaatjie
                    maxwidth={100}
                    className="w-[70px] sm:hidden block rounded-full h-[70px] object-cover"
                    src={relation?.featuredImage?.node}
                  />
                  <div>
                    <h3 className="text-2xl font-bold">{relation?.title}</h3>
                    <h4 className="text-site-accent">{relation?.recap.function}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-3">
            <div
              ref={formRef}
              className={clsx(
                formVisible && 'animate-fade-up animate-delay-300 animate-ease-in-out animate-duration-500',
                formVisible ? 'opacity-100' : 'opacity-0',
                'bg-site-base p-6 sm:p-10 lg:p-6 xl:p-12'
              )}
            >
              <h2 className="text-site-accent pb-6 title--normal">{fields.formtitle}</h2>
              <Content className="list--checkmark" theme="dark">
                {fields.formdescription}
              </Content>
              <div className="pt-10">
                <Form data={fields.form} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
