import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { Fragment } from 'react'
import Content from '~/components/elements/Content'
import CrossHair from '~/components/elements/CrossHair'
import Links from '~/components/elements/Links'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import useIsVisible from '~/hooks/useIsVisible'

export default function BannerFunction({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const [subcontentRef, subcontentVisible] = useIsVisible()

  return (
    <section data-component="BannerFunction" className="relative overflow-hidden">
      <div className="container relative pt-[50px] sm:pt-[100px] lg:pt-[150px]">
        <CrossHair className="animate-fade animate-ease-in-out w-[67px] sm:w-[127px] z-10 absolute left-0 sm:-left-20 top-0 sm:top-0 lg:top-[50px]" />
        <div className="opacity-60">
          <img
            src="/static/dots.png"
            className="absolute animate-fade-left animate-ease-in-out max-sm:opacity-20 -right-[120%] sm:-right-[60%] lg:-right-[50%] top-0 lg:top-[150px] xl:top-[170px] h-auto min-w-[900px] lg:min-w-[1000px] xl:min-w-[1800px]"
            alt="dots"
          />
        </div>
        <div className="relative z-20">
          <h1 className="animate-fade-up animate-ease-in-out animate-duration-500 title--large mb-3">{fields.title}</h1>
          <h2 className="animate-fade-up animate-ease-in-out animate-duration-500 animate-delay-75 title--semi-large mb-6">
            {fields.subtitle}
          </h2>
          <Content
            className="animate-fade-up animate-ease-in-out animate-duration-500 animate-delay-100 children-p:text-xl md:children-p:text-2xl lg:children-p:text-3xl max-w-screen-md mb-6 sm:mb-10 lg:mb-14"
            theme="accent"
          >
            {fields.description}
          </Content>
          <Links
            className="animate-fade-up animate-ease-in-out animate-duration-500 animate-delay-150 max-sm:flex-wrap max-sm:children-div:w-full max-sm:children-a:children-div:w-full"
            items={fields.links}
          />
        </div>
      </div>
      <div className="sm:container mt-8 lg:mt-14">
        <div className="max-w-screen-xl mx-auto">
          <div className="relative animate-fade-up animate-ease-in-out animate-duration-1000 animate-delay-300 lg:-ml-[120px] xl:-ml-[200px] lg:mr-[100px] xl:mr-[200px] -mb-1 lg:-mb-24">
            <LossePlaatjie src={fields.image} maxWidth={1200} className="aspect-[16/8] object-cover" />
          </div>
          <div
            ref={subcontentRef}
            className={clsx(
              subcontentVisible && 'animate-fade-up animate-ease-in-out animate-duration-500',
              subcontentVisible ? 'opacity-100' : 'opacity-0',
              'bg-site-base grid grid-cols-1 lg:grid-cols-12 relative max-lg:gap-7 z-20 py-6 xs:py-8 sm:py-16 max-sm:container sm:px-20'
            )}
          >
            <div className="col-span-1 lg:col-span-5">
              <h3 className="title--medium relative !leading-[1.2] text-white">
                <div className="bg-site-accent absolute h-8 sm:h-10 w-12 sm:w-20 lg:h-[62px] lg:w-[125px] -left-3 sm:-left-10 top-0 lg:-top-1 -z-10" />
                {fields.sub.title.split('|').map((t, i) => (
                  <Fragment key={i}>
                    <span className={clsx(i !== 0 && 'pl-6 md:pl-10')}>{t}</span>
                    {i < fields.sub.title.split('|').length - 1 && <br />}
                  </Fragment>
                ))}
              </h3>
            </div>
            <div className="col-span-1 lg:col-span-7">
              <Content theme="dark">{fields.sub.description}</Content>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
