import CrossHair from '../CrossHair'

export default function NoResults() {
  return (
    <div className="flex--center">
      <div className="flex flex-col items-center gap-10 lg:gap-14 relative">
        <CrossHair className="w-[340px] animate-fade-up animate-ease-in-out animate-delay-500 absolute left-0 right-0 top-[60px] mx-auto" />
        <h1 className="title--semi-large animate-fade-up animate-delay-75 animate-ease-in-out text-site-base relative leading-[1] text-center font-bold mt-56">
          Sorry, <br /> er is geen match.
        </h1>
        <h2 className="text-2xl lg:text-3xl relative max-w-screen-sm text-center animate-fade-up animate-ease-in-out animate-delay-150 font-bold text-site-base">
          Probeer het met andere filters.
        </h2>
      </div>
    </div>
  )
}
