import type { Expert as ExpertType, Page_Flexcontent_Flex_Posts } from '~/graphql/types'
import Expert from './post-types/Expert'
import { useEffect, useRef, useState } from 'react'
import CrossHair from '~/components/elements/CrossHair'
import Content from '~/components/elements/Content'
import { CountUp } from 'countup.js'
import { useInView } from 'framer-motion'
import useIsVisible from '~/hooks/useIsVisible'
import clsx from 'clsx'
import useIsMobile from '~/hooks/useIsMobile'

export const NumberCounter = (targetNumber: number, increments: number, i: number) => {
  const [count, setCount] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      if (count < targetNumber) {
        setCount(count + increments)
      } else {
        clearInterval(interval)
      }
    }, i)

    return () => clearInterval(interval)
  }, [count, targetNumber, i, increments])

  return <div>{count}</div>
}

export default function ExpertsShuffleStats({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const isMobile = useIsMobile('(max-width: 639px)')
  const [shuffle, setShuffle] = useState(0)

  const _experts = fields?.posts?.edges?.map((edge) => edge?.node as ExpertType)
  const experts = _experts
    .sort(() => Math.random() - 0.5)
    .filter((value, index, self) => self.findIndex((e) => e.databaseId === value.databaseId) === index)
    .slice(0, isMobile ? 4 : 9)

  const [titleRef, titleIsVisible] = useIsVisible()
  const [expertsRef, expertsIsVisible] = useIsVisible()

  return (
    <section data-component="ExpertsShuffleStats" className="relative overflow-hidden">
      <div className="grid max-w-[1700px] mx-auto grid-cols-1 lg:grid-cols-2 relative">
        <div
          className={clsx(
            expertsIsVisible && 'animate-fade-left animate-ease-in-out animate-duration-1000',
            expertsIsVisible ? 'opacity-100' : 'opacity-0',
            'lg:block hidden absolute -right-2/3 sm:-right-1/3 top-0 animate-fade-left animate-ease-in-out animate-duration-1000 animate-delay-500'
          )}
        >
          <img className="h-[270px] xl:h-[320px] object-cover" loading="eager" src="/static/dots.png" alt="dots" />
        </div>
        <div className="lg:pr-[80px] xl:pr-[180px]">
          <div
            ref={expertsRef}
            className={clsx(
              expertsIsVisible && 'animate-fade-right animate-delay-200 animate-ease-in-out animate-duration-500',
              expertsIsVisible ? 'opacity-100' : 'opacity-0',
              'grid grid-cols-2 sm:grid-cols-3 relative'
            )}
          >
            <div
              onClick={() => setShuffle(shuffle + 1)}
              className={clsx(
                expertsIsVisible && 'animate-fade-up animate-delay-200 animate-ease-in-out animate-duration-1000',
                expertsIsVisible ? 'opacity-100' : 'opacity-0',
                'cursor-pointer z-[100] hover:bg-site-conversion group transition-all duration-200 absolute bottom-5 sm:bottom-10 left-0 right-0 mx-auto hover:text-white font-semibold w-fit bg-white rounded-full py-2 px-5 flex items-center gap-2'
              )}
            >
              <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="_01_align_center">
                  <path
                    id="Vector"
                    d="M20.1224 12.2932L18.7085 13.7072L21.0005 16.0002H20.6185C17.2345 16.0002 15.0825 14.4682 13.1725 12.4922L12.8725 12.8402C12.5445 13.2203 12.2065 13.6112 11.8545 14.0042C14.0545 16.5517 17.2566 18.0125 20.6225 18.0042H21.0005L18.7075 20.2972L20.1215 21.7112L23.4145 18.4182C24.1952 17.6372 24.1952 16.3712 23.4145 15.5902L20.1224 12.2932Z"
                    className="fill-site-accent group-hover:fill-white transition-all duration-200"
                  />
                  <path
                    id="Vector_2"
                    d="M20.6189 6.00005H20.9999L18.7079 8.29303L20.1219 9.70702L23.4149 6.414C24.1957 5.63302 24.1957 4.36702 23.4149 3.58598L20.1219 0.292969L18.7079 1.70695L20.9999 4.00008H20.618C15.661 4.00008 12.9509 6.67008 10.492 9.47808C8.06296 6.73505 5.14293 4.00008 -0.0400391 4.00008V6.00009C4.42396 6.00009 6.83296 8.33611 9.16898 11.0001C6.83296 13.6641 4.42396 16.0001 -0.0400391 16.0001V18.0001C5.76796 18.0001 8.73594 14.5641 11.36 11.5341C13.814 8.68805 16.1369 6.00005 20.6189 6.00005Z"
                    className="fill-site-accent group-hover:fill-white transition-all duration-200"
                  />
                </g>
              </svg>
              Shuffle
            </div>
            <CrossHair
              className={clsx(
                expertsIsVisible && 'animate-fade-up animate-delay-500 animate-ease-in-out animate-duration-500',
                expertsIsVisible ? 'opacity-100' : 'opacity-0',
                'w-[100px] sm:w-[150px] xl:w-[180px] absolute max-sm:-bottom-[50px] max-lg:-bottom-[75px] lg:top-[100px] xl:top-[170px] right-[5px] sm:right-[50px] z-50 lg:-right-[75px] xl:-right-[90px]'
              )}
            />
            {experts.map((expert) => {
              return <Expert type="small" key={Math.random() + expert.databaseId} expert={expert} />
            })}
          </div>
        </div>
        <div className="lg:absolute relative container mx-auto pointer-events-none top-0 left-0 right-0 h-full max-sm:pb-8 max-lg:pb-10">
          <div className="block lg:hidden -z-10 absolute -right-1/3 sm:-right-2/3 top-0 animate-fade-left animate-ease-in-out animate-duration-1000 animate-delay-500">
            <img className="h-[145px] sm:h-[270px] xl:h-[320px] object-cover" loading="eager" src="/static/dots.png" alt="dots" />
          </div>
          <div className="grid h-full grid-cols-1 lg:grid-cols-2">
            <div className="col-start-2 pointer-events-auto h-full flex items-center lg:pr-[100px] pt-24 lg:pt-[200px]">
              <div>
                <h2
                  ref={titleRef}
                  className={clsx(
                    titleIsVisible && 'animate-fade-up animate-ease-in-out animate-duration-700',
                    titleIsVisible ? 'opacity-100' : 'opacity-0',
                    'title--medium pb-6 sm:pb-10'
                  )}
                >
                  {fields.title}
                </h2>
                <Content
                  className={clsx(
                    titleIsVisible && 'animate-fade-up animate-delay-200 animate-ease-in-out animate-duration-700',
                    titleIsVisible ? 'opacity-100' : 'opacity-0'
                  )}
                >
                  {fields.description}
                </Content>
                <div style={{ gridTemplateColumns: `repeat(${fields.items.length}, minmax(0, 1fr))` }} className="grid pt-10 xl:pt-14">
                  {fields.items.map((item, index) => (
                    <div key={index}>
                      <div>{item.title}</div>
                      <div className="title--semi-large pt-4">
                        <Number number={parseFloat(item.number.replace(',', '.'))} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export function Number({ number }: { number: number }) {
  const countupRef = useRef(null)
  const isCountVisible = useInView(countupRef, { once: true })
  let countUpAnim: any

  useEffect(() => {
    initCountUp()
  }, [initCountUp])

  useEffect(() => {
    if (isCountVisible) {
      countUpAnim.start()
    }
    // @ts-ignore
  }, [countUpAnim, isCountVisible])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function initCountUp() {
    countUpAnim = new CountUp(countupRef.current, number, {
      decimalPlaces: number >= 10 ? 0 : 1
    })
    if (!countUpAnim.error) {
      countUpAnim.start()
    } else {
      console.error(countUpAnim.error)
    }
  }

  return <div ref={countupRef}>0</div>
}
