import clsx from 'clsx'

export default function GreenGreenGrass({ className }: { className?: string }) {
  return (
    <svg className={clsx(className && className)} viewBox="0 0 1564 825" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.14819 63.1819L30.0719 2.1311" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M136.362 62.6164L156.155 2.69678" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M264.708 63.1821L282.794 1.56567" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M392.479 63.182L410.008 1" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M520.824 62.6164L537.222 2.69678" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M648.038 62.6165L666.133 1.56567" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M774.687 62.0509L792.773 1.56567" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M903.023 62.6163L919.987 2.1311" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1030.8 62.6165L1047.77 1.56567" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1156.89 62.0508L1176.11 1" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1285.8 62.6163L1303.32 2.1311" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1412.44 62.6165L1430.53 1.56567" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1540.78 63.1821L1557.18 1.56567" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M806.908 273.449L757.723 329.41" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1187.09 273.449L1137.9 329.41" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1312.19 273.449L1263 329.41" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M680.834 273.449L633.338 328.278" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1057.34 270.655L1017.19 331.072" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M556.449 276.843L507.821 329.41" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M934.219 274.804L890.409 331.449" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M430.366 279.105L381.173 329.41" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M305.415 278.54L257.353 328.844" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M178.767 279.105L131.271 329.41" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M53.2501 279.671L6.31958 329.41" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M5.19653 241.236L53.8158 192.62" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M130.147 241.236L179.332 192.62" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M256.222 242.924L305.415 191.488" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M382.304 241.801L414.298 208.667L429.8 192.619" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M908.899 246.366L932.944 207.082L944.597 188.054" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M507.255 241.802L555.883 190.357" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1032.63 245.526L1071.9 186.633" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M547.397 101.052L513.478 155.881" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1431.92 100.62L1398 155.449" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1548.92 100.62L1515 155.449" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M420.183 100.486L387.396 157.012" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1304.3 100.486L1271.51 157.012" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M635.93 243.574L679.449 190.847" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1156.63 243.574L1200.14 190.847" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M762.553 244.815L803.86 187.343" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1283.25 244.815L1324.56 187.343" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1410 248.092L1451.31 190.62" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1514 248.092L1555.31 190.62" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M799.038 101.219L765.111 156.049" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M671.765 100.62L639.088 157.214" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M294.109 99.9204L261.313 157.578" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1178.22 99.9204L1145.43 157.578" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M170.855 98.7893L132.402 158.143" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1050.05 96.1305L1021.43 160.802" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M6.88525 157.012L44.2067 96.5269" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M891 157.012L928.321 96.5269" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M3.49927 411.936L66.2534 371.239" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M129.016 413.067L191.205 370.673" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M252.836 414.764L316.156 367.845" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M378.91 414.199L439.409 367.279" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M879.991 419.504L930.508 361.974" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M501.606 413.068L563.229 368.411" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1001.95 417.989L1055.07 363.489" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M625.991 414.764L687.614 368.411" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1128.42 421.38L1177.37 361.795" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M753.564 419.878L810.18 363.297" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1252.74 422.66L1303.18 360.515" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M814.499 458.29L748.114 498.422" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1325.03 452.396L1267.4 504.316" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M690.443 456.593L624.294 498.422" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1197.99 449.126L1144.16 505.943" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M567.755 459.421L500.475 497.291" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1075.38 451.705L1019.9 505.404" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M442.804 457.159L376.089 498.988" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M953.27 451.35L895.444 504.797" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M317.287 457.725L251.704 498.422" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M194.599 459.421L129.016 496.16" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M70.2137 460.553L4.06519 494.463" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M3.49927 579.252L68.5164 553.817" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M129.016 579.818L191.77 553.251" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M253.401 579.252L316.721 552.12" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M377.221 579.252L439.975 553.251" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M502.172 579.818L563.795 553.251" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M625.991 579.817L688.746 552.686" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M750.934 579.252L814.499 552.686" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1558.88 624.475L1493.29 663.476" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1435.06 628.991L1368.91 662.91" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1311.24 628.434L1244.52 663.475" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1186.85 630.122L1121.27 662.344" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1062.47 632.95L998.016 663.476" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M938.648 632.95L873.631 662.91" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M814.499 635.212L749.811 662.91" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M689.877 634.647L625.991 662.344" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M566.058 636.909L502.172 662.91" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M443.369 641.434L377.787 662.344" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M317.853 642L253.967 662.344" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M194.033 643.688L130.147 662.344" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M72.4766 645.385L2.36792 662.91" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1.23633 744.871L73.0422 731.305" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M125.622 744.871L196.296 728.477" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M248.31 745.436L319.55 727.346" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M373.827 744.306L445.633 726.215" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M498.212 745.437L569.452 724.526" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1.23633 816.685L73.0422 803.752" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M125.622 816.685L196.296 801.051" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M248.31 817.225L319.55 799.979" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M373.827 816.144L445.633 798.898" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M498.212 817.225L569.452 797.277" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M622.031 744.871L692.14 720.001" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M745.285 745.436L814.499 720.001" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M870.236 744.871L940.911 718.305" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M992.359 744.871L1064.73 718.305" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1114.48 744.871L1187.98 715.477" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1238.87 744.871L1311.24 713.78" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1364.38 745.436L1436.19 713.78" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1489.33 745.437L1559.44 710.96" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1559.44 788.396L1487.64 823.438" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1434.49 790.659L1363.82 822.873" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1311.24 790.093L1241.13 823.438" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1188.55 791.79L1117.31 822.873" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1063.6 795.175L992.359 823.438" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M940.345 794.61L870.236 823.438" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M620.334 822.873L692.14 797.438" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M746.417 822.307L814.499 797.438" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1557.12 278.438L1514.15 330.448" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1434.43 276.185L1386.38 330.448" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1375.72 420.897L1428.69 363.78" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1504.03 424.366L1550.29 359.746" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1551.45 449.892L1499.3 508.824" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1428.75 453.082L1374.57 507.118" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M874.703 580.29L937.457 553.716" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M997.957 581.413L1061.28 553.716" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1121.78 580.847L1186.79 547.502" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1246.16 580.29L1310.05 542.412" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1371.11 581.413L1435 542.978" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M1495.5 580.848L1558.25 541.281" stroke="#88B922" strokeWidth="3" strokeMiterlimit="10" />
    </svg>
  )
}
