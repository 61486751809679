import clsx from 'clsx'
import { useInView } from 'framer-motion'
import { Fragment, useRef } from 'react'
import Content from '~/components/elements/Content'
import CrossHair from '~/components/elements/CrossHair'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import { useComponentTheme } from './ContentColumnsBig'
import { LosseLink } from '@ubo/losse-sjedel'

export default function ContentUsps({ fields, index }: { fields: Page_Flexcontent_Flex_Content; index: number }) {
  const { theme, type } = useComponentTheme(index, fields?.styletype)

  const contentRef = useRef(null)
  const contentVisible = useInView(contentRef, { once: true })

  const hasTwoOrMore = fields.items.length > 2

  return (
    <section
      ref={contentRef}
      data-component="ContentUsps"
      className={clsx(
        theme === 'dark' && 'bg-site-base pb-12 md:pb-20 lg:pb-36 pt-20 lg:pt-32',
        theme === 'none' && 'bg-site-grey-light section--large--mb pb-12 md:pb-20 lg:pb-36 pt-20 lg:pt-32',
        theme === 'light' && type === 'normal' && 'section--large',
        theme === 'light' && type === 'alt' && 'section'
      )}
      id={fields.sectionId || 'usps'}
    >
      <div className="container">
        <div
          className={clsx(
            hasTwoOrMore ? 'justify-center lg:justify-between' : 'justify-center',
            'flex lg:flex-row flex-col items-center lg:items-start',
            hasTwoOrMore && 'lg:gap-2 xs:gap-10 gap-7',
            !hasTwoOrMore && 'lg:gap-20 xs:gap-10 gap-7'
          )}
        >
          {fields.items.map((item, i) => (
            <div
              className={clsx(
                contentVisible && 'animate-fade-up animate-ease-in-out animate-duration-500',
                contentVisible ? 'opacity-100' : 'opacity-0',
                item.description ? 'items-start' : 'items-center',
                'flex gap-4 xl:gap-7'
              )}
              key={i}
            >
              <div>
                <CrossHair className="w-[61px]" />
              </div>
              <div className={clsx(hasTwoOrMore && 'max-w-[305px]')}>
                <h3
                  className={clsx(
                    'title--tiny xs:title--light xl:title--small text-center',
                    theme === 'dark' && 'text-white',
                    type === 'alt' && 'text-center'
                  )}
                >
                  {item.title.split('|').map((t, i) => (
                    <Fragment key={i}>
                      {t}
                      <br />
                    </Fragment>
                  ))}
                </h3>
                {item.description && (
                  <Content className="children-em:text-sm" theme={theme}>
                    {item.description}
                  </Content>
                )}
              </div>
            </div>
          ))}
        </div>
        {theme === 'dark' && (
          <div className="spacing--big--top flex justify-center">
            <LosseLink to="#onboarding" className="btn--conversion">
              Lees meer over Onboarding
            </LosseLink>
          </div>
        )}
      </div>
    </section>
  )
}
