import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import SliderTitle from './SliderTitle'
import SliderProcedure from './SliderProcedure'
import SliderDefault from './SliderDefault'
import SliderLogos from './SliderLogos'
import SliderInstagram from './SliderInstagram'

export default function SliderShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    slider_default: SliderDefault,
    slider_title: SliderTitle,
    slider_procedure: SliderProcedure,
    slider_logos: SliderLogos,
    slider_instagram: SliderInstagram
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
