import { useSearchParams } from '@ubo/losse-sjedel'
import clsx from 'clsx'

export default function Select({ name, options, className }: { name: string; options: string[]; className?: string }) {
  const [searchParams, setSearchParams] = useSearchParams()

  return (
    <div className={clsx('btn--outline--black relative group', className)}>
      <span className="max-sm:hidden">Sorteren op:</span>
      <span className="text-site-accent group-hover:text-white">{searchParams.get(name) || 'Laatst toegevoegd'}</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className="w-6 h-6 text-site-base group-hover:text-white"
      >
        <path
          d="M18.7099 8.21C18.6169 8.11627 18.5063 8.04187 18.3844 7.99111C18.2626 7.94034 18.1319 7.9142 17.9999 7.9142C17.8679 7.9142 17.7371 7.94034 17.6153 7.99111C17.4934 8.04187 17.3828 8.11627 17.2899 8.21L12.7099 12.79C12.6169 12.8837 12.5063 12.9581 12.3844 13.0089C12.2626 13.0596 12.1319 13.0858 11.9999 13.0858C11.8679 13.0858 11.7371 13.0596 11.6153 13.0089C11.4934 12.9581 11.3828 12.8837 11.2899 12.79L6.70986 8.21C6.6169 8.11627 6.5063 8.04187 6.38444 7.99111C6.26258 7.94034 6.13187 7.9142 5.99986 7.9142C5.86785 7.9142 5.73714 7.94034 5.61528 7.99111C5.49343 8.04187 5.38282 8.11627 5.28986 8.21C5.10361 8.39736 4.99907 8.65081 4.99907 8.915C4.99907 9.17918 5.10361 9.43263 5.28986 9.61999L9.87986 14.21C10.4424 14.7718 11.2049 15.0873 11.9999 15.0873C12.7949 15.0873 13.5574 14.7718 14.1199 14.21L18.7099 9.61999C18.8961 9.43263 19.0007 9.17918 19.0007 8.915C19.0007 8.65081 18.8961 8.39736 18.7099 8.21Z"
          fill="currentColor"
        />
      </svg>
      <select
        defaultValue={searchParams.get(name) || ''}
        onChange={(event) => {
          searchParams.set(name, event.target.value)
          setSearchParams(searchParams)
        }}
        className="absolute left-0 top-0 w-full h-full opacity-0 cursor-pointer"
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  )
}
