import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import PostsVacancies from './PostsVacancies'
import PostsReviews from './PostsReviews'
import PostsCandidates from './PostsCandidates'
import ExpertsOverview from './ExpertsOverview'
import PostsVacanciesRelated from './PostsVacanciesRelated'
import PostsVacanciesHighlighted from './PostsVacanciesHighlighted'
import ExpertsShuffleStats from './ExpertsShuffleStats'
import PostsNews from './PostsNews'
import PostsNewsLatest from './PostsNewsLatest'
import ExpertsShuffleOverview from './ExpertsShuffleOverview'
import ExpertsHighlighted from './ExpertsHighlighted'
import CasesOverview from './CasesOverview'
import CasesHighlighted from './CasesHighlighted'
import InternalVacanciesHighlighted from './InternalVacanciesHighlighted'
import PostsNewsMore from './PostsNewsMore'
import PostsNewsHighlighted from './PostsNewsHighlighted'

export default function PostsShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    news_overview: PostsNews,
    news_latest: PostsNewsLatest,
    news_more: PostsNewsMore,
    news_highlighted: PostsNewsHighlighted,
    vacancies_overview: PostsVacancies,
    review_overview: PostsReviews,
    professionals_overview: PostsCandidates,
    experts_overview: ExpertsOverview,
    vacancies_related: PostsVacanciesRelated,
    vacancies_highlighted: PostsVacanciesHighlighted,
    experts_shuffle_stats: ExpertsShuffleStats,
    experts_shuffle_overview: ExpertsShuffleOverview,
    experts_highlighted: ExpertsHighlighted,
    cases_overview: CasesOverview,
    cases_highlighted: CasesHighlighted,
    internalvacancies_highlighted: InternalVacanciesHighlighted
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
