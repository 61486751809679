/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useRef } from 'react'
import { useOnClickOutside } from 'usehooks-ts'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  show: boolean
  setShow: (show: boolean) => void
  setPaddings?: boolean
}

export default function Modal({ show, setShow, setPaddings = true, children, ...rest }: Props) {
  const ref = useRef()

  useOnClickOutside(ref, () => setShow(false))

  function handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Escape') setShow(false)
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown)

    return () => {
      document.removeEventListener('keydown', handleKeydown)
    }
  }, [])

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed left-0 top-0 w-full h-full z-50 bg-site-base/80"
        >
          <div className="container flex--center h-full">
            <div
              className="bg-site-grey-light w-[550px] animate-fade-up max-h-[calc(100vh-theme(spacing.10))] sm:max-h-[calc(100vh-theme(spacing.20))] overflow-auto"
              ref={ref}
            >
              <button
                type="button"
                onClick={() => {
                  setShow(!show)
                }}
                className="absolute z-20 right-2 top-2 sm:right-4 sm:top-4 btn--pseudo btn--pseudo--conversion text-site-base rounded-full p-2"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 lg:w-6 lg:h-6"
                >
                  <g id="Group">
                    <path
                      id="Vector"
                      d="M14.1209 12.0002L23.5605 2.56161C24.1465 1.97565 24.1465 1.02563 23.5605 0.439714C22.9745 -0.146246 22.0245 -0.146246 21.4386 0.439714L12 9.8793L2.56137 0.439714C1.97541 -0.146246 1.02538 -0.146246 0.43947 0.439714C-0.146443 1.02567 -0.14649 1.9757 0.43947 2.56161L9.87905 12.0002L0.43947 21.4389C-0.14649 22.0248 -0.14649 22.9748 0.43947 23.5608C1.02543 24.1467 1.97545 24.1467 2.56137 23.5608L12 14.1211L21.4386 23.5608C22.0245 24.1467 22.9745 24.1467 23.5605 23.5608C24.1464 22.9748 24.1464 22.0248 23.5605 21.4389L14.1209 12.0002Z"
                      fill="currentColor"
                    />
                  </g>
                </svg>
              </button>
              <div className="absolute h-14 sm:h-16 w-[40%] bg-site-accent left-0 top-6" />
              <div className={clsx(setPaddings && 'pb-12 pt-10 sm:pt-14 z-10 px-4 md:px-14', 'relative')}>
                <div {...rest}>{children}</div>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
