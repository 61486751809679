import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import type { Page_Flexcontent_Flex_Slider } from '~/graphql/types'

export default function SliderDefault({ fields }: { fields: Page_Flexcontent_Flex_Slider }) {
  const [controlledSwiper, setControlledSwiper] = useState(null)
  const [index, setIndex] = useState(0)

  const swiperButtonClasses = `absolute sm:bottom-0 sm:top-0 top-2 m-auto shadow transition-opacity z-10 sm:w-10 sm:h-10 w-8 h-8 bg-white text-site-accent rounded-full flex items-center justify-center`

  return (
    <section data-component="SliderDefault" className="section overflow-hidden" id={fields.sectionId}>
      <div className="container mx-auto max-w-screen-md relative">
        <button
          className={clsx('xl:-left-10 lg:-left-8 left-6 ', swiperButtonClasses, controlledSwiper?.isBeginning && 'opacity-0')}
          onClick={() => {
            if (!controlledSwiper) return
            controlledSwiper.slidePrev()
            setIndex(index - 1)
          }}
          type="button"
        >
          <svg className="sm:w-5 w-4 sm:h-5 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <g clipPath="url(#clip0_813_6613)">
              <path
                fill="currentColor"
                d="M18 1.5a1.501 1.501 0 01-.44 1.06l-7.671 7.672a2.5 2.5 0 000 3.536l7.662 7.662a1.5 1.5 0 01-2.121 2.12L7.768 15.89a5.505 5.505 0 010-7.778L15.44.439A1.5 1.5 0 0118 1.499z"
              ></path>
            </g>
          </svg>
        </button>

        <button
          className={clsx('xl:-right-10 lg:-right-8 right-6', swiperButtonClasses, controlledSwiper?.isEnd && 'opacity-0')}
          onClick={() => {
            if (!controlledSwiper) return
            controlledSwiper.slideNext()
            setIndex(index + 1)
          }}
          type="button"
        >
          <svg className="sm:w-5 w-4 sm:h-5 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <g clipPath="url(#clip0_813_6611)">
              <path
                fill="currentColor"
                d="M6 22.5a1.5 1.5 0 01.44-1.06l7.671-7.672a2.5 2.5 0 000-3.536L6.449 2.57A1.5 1.5 0 018.57.45l7.662 7.661a5.505 5.505 0 010 7.778L8.56 23.561A1.5 1.5 0 016 22.501z"
              ></path>
            </g>
          </svg>
        </button>

        <Swiper
          className="w-full"
          onSwiper={(swiper) => {
            setControlledSwiper(swiper)
            swiper.on('slideChange', function () {
              setIndex(swiper.realIndex)
            })
          }}
          freeMode
          slidesPerView={1}
        >
          {fields.items.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="relative">
                <LossePlaatjie src={item.image} className="w-full h-auto object-cover" />
                {item.description && (
                  <p className="absolute left-0 bottom-0 w-full from-site-base/90 to-transparent text-white bg-gradient-to-t p-6 pt-20 sm:text-base text-sm">
                    {item.description}
                  </p>
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  )
}
